import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { ListItemText, listItemsStyle } from '../../../constants/styles';
import { sizes } from '../../../constants/sizes';

const platformListItemsStyle = listItemsStyle[Platform.OS];
const isWeb = (Platform.OS === 'web');
const tabsCaptionContainerStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: isWeb ? 0 : 30,
    height: sizes.TASK_ROW_CAPTION_HEIGHT,
    backgroundColor: COLORS.WHITE.LIGHT,
    marginLeft: 30
};

export default class UserRowCaptions extends Component {
    render() {
        return (
            <View style={tabsCaptionContainerStyle}>
                <ListItemText style={platformListItemsStyle.admin} >
                    Login
                </ListItemText>
                <ListItemText style={platformListItemsStyle.admin} >
                    firstName
                </ListItemText>
                <ListItemText style={platformListItemsStyle.admin} >
                    lastName
                </ListItemText>
                <ListItemText style={platformListItemsStyle.admin} >
                    role
                </ListItemText>
                <ListItemText style={platformListItemsStyle.adin} >
                    password
                </ListItemText>
            </View>
        )
    }
}