export default function deleteImages(images, { isState } = {}) {
    const tmpImages = images.filter(i => typeof i.id === 'string');
    const realImages = images.filter(i => typeof i.id === 'number');

    this.setState({ images: this.state.images.filter(i => !tmpImages.map(i => i.id).indexOf(i.id || i.preId) < 0) });
    this.props.update([...this.state.images.filter(i => tmpImages.map(i => i.id).indexOf(i.id || i.preId) < 0)], this.props.updateSettings);

    const onAfterSaga = (errors) => {
        console.log('errors => ', errors);
    };

    this.props.deleteImages(realImages, onAfterSaga);
}
