import http from './http';

const ENDPOINT = {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
};

export default class AuthService {
    static async login(credentials) {
        return await http.post(ENDPOINT.LOGIN, credentials);
    }

    static logout(token) {
        return http.delete(ENDPOINT.LOGOUT, token);
    }
}
