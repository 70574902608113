import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RadioGroup } from './components/common/web/RadioGroup';
import { CustomSelect } from './components/common/web/Select';
import Layout from './Layout';
import WebRouterOutlet from './router/WebRouterOutlet';
import { persistor, store } from './store';

const webElements = {
    radio: RadioGroup,
    select: CustomSelect,
};

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Layout router={<WebRouterOutlet />} customElements={webElements} />
                </PersistGate>
            </Provider>
        );
    }
}