import React, {Component} from 'react';
import { ButtonContainer, ButtonText } from '../../constants/styles';

const PLUS_BUTTON_TEXT = '+';
const plusTextStyle = {
    fontSize: 42,
    lineHeight: 40
};

export class PlusButton extends Component {
    render() {
        return (
            <ButtonContainer width={40} onPress={this.props._onPress}>
                <ButtonText style={plusTextStyle}>
                    {PLUS_BUTTON_TEXT}
                </ButtonText>
                {this.props.children}
            </ButtonContainer>
        );
    }
}