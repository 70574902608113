import React, { Component } from 'react';
import { Image, ScrollView, TouchableOpacity, View, Platform } from 'react-native';
import { COLORS } from '../../constants/colors';
import { ButtonLink, Content, HeaderContainer, HeaderText, taskContainerWithShadow, TaskPreviewContainer, Text } from '../../constants/styles';
import { formatDate } from '../../helpers/formatDate';
import i18n from '../../i18n';
import config from '../../config';
import { sizes } from "../../constants/sizes";

const platformOS = Platform.OS.toUpperCase();

export class TaskPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            viewImage: false,
        };

        this.toggleView = () => {
            this.setState(prevState => ({ visible: !prevState.visible }))
        };
        this.setViewImage = (pictureId) => {
            this.setState({ viewImage: pictureId })
        };
    }

    render() {
        const { data } = this.props;
        if (!data) return null;

        return (
            <TaskPreviewContainer style={{ ...taskContainerWithShadow, width: '100%', }}>
                <HeaderContainer>
                    <HeaderText size={2} style={{ width: '38%', height: this.state.visible ? 'auto' : 30, overflow: 'hidden' }}>
                        {
                            this.state.visible
                                ? data.name
                                : (data.name || '').length > 24
                                    ? (data.name || '').slice(0, 20) + '...'
                                    : data.name
                        }
                    </HeaderText>
                    <Text style={{ width: '20%' }}>{data.coordinates}</Text>
                    <Text style={{ width: '38%' }}>{data.country + (data.region ? ', ' + data.region : '') + (data.district ? ', ' + data.district + ' ' + i18n.t('task.district') : '')}</Text>
                </HeaderContainer>
                <Content visible={this.state.visible}>
                    <Text style={{ marginBottom: 10 }}>{data.task && data.task.description ? data.task.description : ''}</Text>
                    <Text color={COLORS.PURPLE.DARK} size={3} style={{ marginBottom: 10 }}>{`Created ${formatDate(data.createdAt)} by ${data.author.firstName} ${data.author.lastName}`}</Text>
                    {data.pictures && data.pictures.length > 0 && (
                        <View>
                            <Text size={2} style={{ marginBottom: 10 }}>{i18n.t('text.added_pictures')}</Text>
                            <ScrollView style={{ width: '100%' }} horizontal={true}>
                                {data.pictures.map(picture => (
                                    <TouchableOpacity
                                        key={picture.id}
                                        onPress={() => this.setViewImage(picture.id)}>
                                        <Image
                                            source={{ uri: `${config.hostname}picture/${picture.id}?size=middle` }}
                                            key={picture.id}
                                            style={{ width: 200, height: 200, resizeMode: 'contain', marginRight: 10 }} />
                                    </TouchableOpacity>
                                ))}
                            </ScrollView>
                        </View>
                    )}
                </Content>
                <ButtonLink onPress={this.toggleView}>{this.state.visible ? 'Close task' : 'See task'}</ButtonLink>
                {!!this.state.viewImage &&
                    <ScrollView
                        maximumZoomScale={3}
                        minimumZoomScale={1}
                        style={{
                            position: 'absolute',
                            top: 0,
                            marginLeft: sizes[platformOS].MARGIN_LEFT,
                            width: sizes[platformOS].TASK_PREVIEW_WIDTH,
                            height: sizes[platformOS].TASK_PREVIEW_IMAGE_VIEW_HEIGHT,
                            backgroundColor: COLORS.GRAY.LIGHT}}
                        horizontal={true}>
                        <TouchableOpacity
                            onPress={() => this.setState({ viewImage: false })}
                            style={{
                                width: sizes[platformOS].TASK_PREVIEW_WIDTH,
                                height: sizes[platformOS].TASK_PREVIEW_IMAGE_VIEW_HEIGHT}} >
                            {data.pictures.map(image => image.id === this.state.viewImage && (
                                <Image
                                    key={image.id}
                                    style={{
                                        resizeMode: 'contain',
                                        width: sizes[platformOS].TASK_PREVIEW_WIDTH,
                                        height: sizes[platformOS].TASK_PREVIEW_IMAGE_VIEW_HEIGHT}}
                                    source={{ uri: `${config.hostname}picture/${image.id}` }}
                                />
                            ))}
                        </TouchableOpacity>
                    </ScrollView>
                }
            </TaskPreviewContainer>
        );
    }
}
