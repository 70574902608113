import { DESTROY_DATA, SET_AUTH_INFORMATION, UPDATE_AUTH } from '../actions/auth';
import AuthInformation from '../types/AuthInformation';

const DEFAULT_STATE = new AuthInformation({});

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case SET_AUTH_INFORMATION:
            return new AuthInformation(action.payload);

        case UPDATE_AUTH:
            return new AuthInformation({ ...state, ...action.payload });

        case DESTROY_DATA:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
