import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../constants/colors';
import { CustomTooltip } from './Tooltip'

export class RadioGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        const value = this.getValue(this.props.formData, this.props.initialValue);
        this.setState({value});
    }
    onChange(event) {
        const value = Number(event.target.id);
        this.setState({value});
        if(this.props.onSelect) {
            this.props.onSelect(value);
        }
    }
    getValue(data, value) {
        return Number(Object.keys(data).filter(key => data[key].value === value)[0]);
    }

    render() {
        const RadioCaption = ()=>{
            if(!this.props.tooltipParagraphs) {
                return (<p style={{width: this.props.captionWidth, marginTop: 10, marginBottom: 0}}>{this.props.caption}</p>)
            } else {
                return (
                    <CustomTooltip
                        height={this.props.tooltipHeight}
                        width={this.props.tooltipWidth}
                        paragraphs={this.props.tooltipParagraphs}
                    >
                        <p style={{width: this.props.captionWidth, marginTop: 10}}>{this.props.caption}</p>
                    </CustomTooltip>);
            }
        };
        return (
            <div style={{ display: 'flex', flexDirection: 'row', ...(this.props.style || {}) }}>
                <RadioCaption />
                <form  style={{ display: 'flex', flexDirection: this.props.reverse ? 'row-reverse' : 'row', marginTop: 10, marginRight: 20}}>
                    {this.props.formData.map((data)=>(
                        <div key={data.value} style={{marginRight: 10}} >
                            <input
                                disabled={this.props.disabled}
                                type={'radio'}
                                key={data.value}
                                id={data.value}
                                value={data.label}
                                checked={this.state.value === data.value}
                                style={{color: COLORS.PURPLE.DARK}}
                                onChange={this.onChange}
                            />
                            <label htmlFor={data.value} style={{ width: (this.props.labelWidth || 70) }}
                            >{data.label}</label>
                        </div>)
                    )}
                </form>
                {this.props.children}
            </div>)
        }
}

RadioGroup.propTypes = {
    formData: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
};
