import React, { Component } from 'react';
import { View, Dimensions, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import { editTask } from '../../actions/tasks';
import { multiPlatformAlert } from "../../helpers/alert";
import { getFailedLoadedImages, getFailedRemovedImages } from '../../helpers/updateImages';
import { TaskDetails, ButtonSubmit, PlusButton, Input, LocationView, CoordinatesView } from '../common';
import pressButtonOnEnter from '../../helpers/pressButtonOnEnter';
import {
    SeparatedView,
    SubHeaderText,
    Text,
    taskDetailsStyle,
    BaseContainer,
    taskContainerWithShadow, InputView
} from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';
import crossImage from '../../assets/icons/cross.png';
import pencil from '../../assets/images/pencil.png';
import config from '../../config';
import i18n from '../../i18n';

const TASK_DESCRIPTION_WIDTH = Dimensions.get('window').width - sizes.WEB.LEFT_MENU_WIDTH - sizes.TASK_DETAILS_WIDTH - sizes.TASK_MARGIN;

const taskEditScreenStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20,
    paddingTop: 30,
    paddingRight: 30,
    paddingLeft: 30,
    width: '100%',
    height: Dimensions.get('window').height - (sizes.GLOBAL_HEADER_HEIGHT + sizes.BOTTOM_MENU_HEIGHT + sizes.BOTTOM_MARGIN),
};

const buttonWrapperStyle = {
    width: '100%',
    marginBottom: 50,
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
};

const addImagesContainerStyle = {
    paddingLeft: 30,
    paddingRight: 30,
    width: Dimensions.get('window').width
};

const alertOKButton = {
    id: 'ok',
    text: i18n.t('ok'),
};

const alertCancelButton = {
    id: 'cancel',
    text: i18n.t('cancel'),
};

class TaskEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            region: '',
            district: '',
            data: {
                name: '',
                coordinates: '',
                pictures: [],
                task: {
                    description: '',
                    historicalInformation: '',
                },
            },
            objectNameError: '',
            showHistoricalInformation: false,
            removedImages: [],
            isEditObject: false,
            viewImage: false,
            isPicturesUpdated: false,
        };
        this.id = 0;
        this.saveTask = this.saveTask.bind(this);
        this.cancelEditTask = this.cancelEditTask.bind(this);
        this.setDataState = this.setDataState.bind(this);
        this.onAfterTaskUpdate = this.onAfterTaskUpdate.bind(this);
    }

    cancelEditTask() {
        if(this.checkDataUpdated(this.state.data, this.initData)) {
            multiPlatformAlert(
                i18n.t('warning'),
                i18n.t('task.confirm_cancel'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                        onPress: () => {
                            this.props.history.goBack()
                        }
                    },
                    alertCancelButton,
                ]
            );
        } else {
            this.props.history.goBack();
        }
    }

    checkDataUpdated(newData, oldData) {
        let isDataUpdated = newData.task.description !== oldData.task.description ||
                            newData.task.historicalInformation !== oldData.task.historicalInformation ||
                            this.state.isPicturesUpdated ||
                            this.state.removedImages.length;
        Object.keys(oldData).forEach(key=> {
            if(key !== 'task' && key !== 'pictures') {
                isDataUpdated = isDataUpdated || (newData[key] !== oldData[key]);
            }
        });
        return isDataUpdated;
    }

    getLocation(data) {
        return {
            country: data.country,
            region: data.region,
            district: data.district,
        }
    }

    saveTask() {
        const newData = {...this.state.data, ...this.getLocation(this.state)};
        const isDataUpdated = this.checkDataUpdated(newData, {...this.initData, ...this.initLocation});
        if (newData.task.description) {
            if(isDataUpdated) {
                newData.removedImages = this.state.removedImages;
                this.props.editTask(this.id, newData, this.onAfterTaskUpdate);
            } else {
                this.props.history.goBack();
            }

        } else {
            multiPlatformAlert(
                i18n.t('error'),
                i18n.t('text.error_edit_task'),
                [ alertOKButton ]
            );
        }
    }

    onAfterTaskUpdate(response) {
        if (!response || 'error' in response) {
            multiPlatformAlert(
                i18n.t('error'),
                i18n.t('task.object_not_updated') +'\n' + i18n.t('task.internet_connection_issue'),
                [ alertOKButton ]
            );
        } else if ('imagesLoaded' in response) {
            const imagesLoaded = response.imagesLoaded.filter(image => image && ('id' in image));
            if(imagesLoaded.length > 0) {
                const imagesNotLoaded = getFailedLoadedImages(imagesLoaded);
                const errorMessage = imagesNotLoaded.length > 0 ? i18n.t('task.error_image_load') + '\n' : '';
                if (errorMessage.length > 0) {
                    multiPlatformAlert(
                        i18n.t('error'),
                        errorMessage,
                        [ alertOKButton ]
                    );
                } else {
                    multiPlatformAlert(
                        i18n.t('success'),
                        i18n.t('task.success_images_loaded'),
                        [ alertOKButton ]
                    );
                }
            }
            this.props.history.push(this.props.paths.task, { data: response.data, canEdit: true, isDataUpdated: true });
        } else if('imagesRemoved' in response) {
            if(response.imagesRemoved.length > 0) {
                const imagesNotRemoved = getFailedRemovedImages(response.imagesRemoved);
                const errorMessage = imagesNotRemoved.length > 0 ? i18n.t('task.error_image_remove') : '';
                if (errorMessage.length > 0) {
                    multiPlatformAlert(
                        i18n.t('error'),
                        errorMessage,
                        [alertOKButton]
                    );
                } else {
                    multiPlatformAlert(
                        i18n.t('success'),
                        i18n.t('task.success_images_removed'),
                        [alertOKButton]
                    );
                }
            }
        } else {
            multiPlatformAlert(
                i18n.t('success'),
                i18n.t('task.object_data_updated'),
                [ alertOKButton ]
            );
        }
    }

    componentDidMount() {
        const { data } = this.props.location.state || {};
        if (!data || !data.id) {
            this.props.history.push(this.props.paths.home);
            return;
        }
        const task = data.task || {};
        this.id = data.id;
        this.initData = {
            name: data.name,
            coordinates: data.coordinates,
            pictures: data.pictures,
            task: {
                description: task.description || '',
                historicalInformation: task.historicalInformation || '',
            }
        };
        this.initLocation = {
            country: data.country,
            region: data.region,
            district: data.district,
        };
        this.setState({data: {...this.initData}, ...this.initLocation});
    }

    onChangeFileInputCallback = (images) => {
        this.setDataState( 'pictures', [...this.state.data.pictures, ...images]);
        this.setState( {isPicturesUpdated: true});
    };

    addPlusContainer(onPress, text, style = {}, hide = false, opt = {}) {
        const FileInput = this.props.elements.fileInput;

        return (
            <View style={{ ...style, display: hide ? 'none' : 'flex',flexDirection: 'row' }}>
                <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', height: 40 }}>
                    <Text style={{ width: 200 }}>{text}</Text>
                </View>
                <PlusButton _onPress={onPress}>
                    {opt.field === 'pictures' && (
                        <FileInput
                            onChangeCallback={this.onChangeFileInputCallback}
                            width={40}
                            height={40}
                            type="file"
                            data={this.state.data.pictures}
                        />
                    )}
                </PlusButton>
            </View>
        );
    }

    setDataState(field, newValue) {
        const data = {...this.state.data};
        data[field] = newValue;
        this.setState({ data });
    }

    render() {
        const { data } = this.props.location.state || {};
        if(data) {
            const description = data.task && data.task.description ? data.task.description : '';
            const historicalInformation = data.task && data.task.historicalInformation ? data.task.historicalInformation : '';
            return (
                <View style={taskEditScreenStyle}>
                    <ScrollView style={{height: '100%'}} contentContainerStyle={{flexGrow: 1}}>
                        <View style={{...taskDetailsStyle, display: !this.state.viewImage ? 'flex' : 'none'}}>
                            {!this.state.isEditObject &&
                                <View style={{position: 'relative'}}>
                                    <TaskDetails data={data} />
                                    <TouchableOpacity style={{ position: 'absolute', right: 10, top: 50, width: 20, height: 20}} onPress={() => {this.setState({isEditObject: true})}}>
                                        <Image style={{ width: 20, height: 20, resizeMode: 'contain' }} source={pencil} />
                                    </TouchableOpacity>
                                </View>
                            }
                            {this.state.isEditObject &&
                            <View style={{ width: '50%'}}>
                                <SubHeaderText style={{marginBottom: 15}}>{i18n.t('task.object')}</SubHeaderText>
                                <BaseContainer style={{
                                    ...taskContainerWithShadow,
                                    height: sizes.TASK_DETAILS_HEIGHT,
                                    marginLeft: 0,
                                    marginRight: 30,
                                    marginBottom: 10,
                                }}>
                                    <View style={{
                                        marginTop: 20,
                                        marginBottom: 15,
                                        height: '100%',
                                        justifyContent: 'space-between'
                                    }}>
                                        <InputView style={{marginBottom: 10, marginRight: 25, marginLeft: 20}}>
                                            <Input
                                                _viewStyle={{marginBottom: 0, width: '100%'}}
                                                _onBlur={() => this.setState({objectNameError: this.state.data.name ? '' : i18n.t('text.error_object_name')})}
                                                onKeyPress={pressButtonOnEnter(this.saveTask)}
                                                placeholder={i18n.t('task.object_name')}
                                                onChangeText={(val) => this.setDataState( 'name', val)}
                                                value={this.state.data.name}
                                                error={this.state.objectNameError}
                                            />
                                        </InputView>
                                        <SeparatedView style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                            paddingTop: 15,
                                            paddingBottom: 5,
                                            paddingRight: 25,
                                            paddingLeft: 20
                                        }}>
                                            <LocationView
                                                viewStyle={{marginBottom: 0, width: '100%'}}
                                                initCountry={data.country}
                                                onChangeCountry={(val) => this.setState( {country: val.label || ''})}
                                                countryWidth={'32%'}
                                                initRegion={data.region}
                                                onChangeRegion={(val) => this.setState( {region: val.label || ''})}
                                                regionWidth={'32%'}
                                                initDistrict={data.district}
                                                onChangeDistrict={(val) => this.setState( {district: val.label || ''})}
                                                districtWidth={'32%'}
                                                select={this.props.elements.select}
                                            />
                                        </SeparatedView>
                                        <CoordinatesView
                                            onKeyPress={pressButtonOnEnter(this.saveTask)}
                                            getLocationAsync={this.props.getLocationAsync}
                                            viewStyle={{
                                                marginTop: 0,
                                                marginRight: 25,
                                                marginLeft: 20,
                                                marginBottom: 20,
                                                width: '100%',
                                                display: 'block'
                                            }}
                                            buttonWidth={157}
                                            inputStyle={{width: '100%'}}
                                            coordinates={this.state.data.coordinates}
                                            onChangeText={(coordinates) => this.setDataState('coordinates', coordinates)}
                                        />
                                    </View>
                                </BaseContainer>
                            </View>
                            }
                            <View style={{ width: this.state.isEditObject ? '50%' : TASK_DESCRIPTION_WIDTH}}>
                                <SubHeaderText style={{marginBottom: 15}}>{i18n.t('text.task')}</SubHeaderText>
                                <Input
                                    _viewStyle={{ width: '100%', marginBottom: 0 }}
                                    onKeyPress={pressButtonOnEnter(this.saveTask)}
                                    defaultValue={description}
                                    height={sizes.TASK_DETAILS_HEIGHT}
                                    multiline={true}
                                    numberOfLines={4}
                                    placeholder={i18n.t('task.add_task')}
                                    onChangeText={(val) => this.setDataState('task', {...this.state.data.task, description: val})}
                                />
                            </View>
                        </View>
                        {!historicalInformation &&
                            this.addPlusContainer(
                        () => this.setState({ showHistoricalInformation: true }),
                                i18n.t('task.add_historical_information'),
                                { marginTop: 30, marginLeft: 30, marginRight: 30 },
                                this.state.showHistoricalInformation
                        )}
                        {(!!historicalInformation || this.state.showHistoricalInformation) &&
                            <View style={{ marginTop: 30}}>
                                <SubHeaderText style={{marginBottom: 15}}>{i18n.t('task.historical_information')}</SubHeaderText>
                                <Input
                                    _viewStyle={{ width: '100%', marginBottom: 0 }}
                                    onKeyPress={pressButtonOnEnter(this.saveTask)}
                                    defaultValue={historicalInformation}
                                    height={173}
                                    multiline={true}
                                    numberOfLines={4}
                                    placeholder={i18n.t('task.add_historical_information')}
                                    onChangeText={(val) => this.setDataState('task', {...this.state.data.task, historicalInformation: val})}
                                />
                            </View>
                        }
                        <SeparatedView style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30, width: '100%'}}>
                            {this.addPlusContainer(
                                () => {},
                                i18n.t('task.add_pictures'),
                                {}, false, {field: 'pictures'}
                            )}

                            <ScrollView style={addImagesContainerStyle} horizontal={true}>
                                {this.state.data.pictures.map((image) => (
                                    <TouchableOpacity
                                        key={image.id}
                                        onPress={() => this.setState({ viewImage: image.id })}
                                        style={{ marginRight: 10, position: 'relative' }}>
                                        <Image
                                            key={image.id}
                                            style={{ width: 50, height: 50 }}
                                            source={{ uri: image.base64 || `${config.hostname}picture/${image.id}?size=small` }}
                                        />
                                        <TouchableOpacity
                                            style={{ position: 'absolute', top: 5, right: 5 }}
                                            onPress={() => {
                                                this.setState( { removedImages: [...this.state.removedImages, ...this.initData.pictures.filter(i => i.id === image.id)] });
                                                this.setDataState('pictures', this.state.data.pictures.filter(i => i.id !== image.id))}}
                                                 >
                                            <Image
                                                style={{ width: 10, height: 10 }}
                                                source={crossImage}
                                            />
                                        </TouchableOpacity>
                                    </TouchableOpacity>
                                ))}
                            </ScrollView>
                        </SeparatedView>
                        <View style={buttonWrapperStyle}>
                            <ButtonSubmit
                                style={{marginRight: 30, width: 100, backgroundColor: COLORS.GRAY.LIGHT2}}
                                buttonColor={COLORS.GRAY.DARK}
                                _onPress={this.cancelEditTask}
                                name={i18n.t('buttons.cancel')}
                            />
                            <ButtonSubmit
                                style={{marginRight: 30, width: 100}}
                                _onPress={this.saveTask}
                                name={i18n.t('buttons.save')}
                            />
                        </View>
                        {!!this.state.viewImage &&
                            <TouchableOpacity
                                onPress={() => this.setState({ viewImage: false })}
                                style={{position: 'absolute', top: 0, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 600, backgroundColor: COLORS.GRAY.LIGHT}}
                            >
                                {this.state.data.pictures.map(image => image.id === this.state.viewImage && (
                                    <Image
                                        key={image.id}
                                        style={{ marginRight: 20, width: 600, height: 600, resizeMode: 'contain'}}
                                        source={{ uri: image.base64 || `${config.hostname}picture/${image.id}` }}
                                    />
                                ))}
                            </TouchableOpacity>
                        }
                    </ScrollView>
                </View>);

        } else {
            return <View/>;
        }
    }
}

const mapDispatchToProps = dispatch => ({
    editTask: (id, data, onAfterSaga) => dispatch(editTask(id, data, onAfterSaga)),
});

export const TaskEditScreen = connect(null, mapDispatchToProps)(TaskEdit);
