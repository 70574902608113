import React, { Component } from 'react';
import { View, FlatList } from 'react-native';
import styled from 'styled-components/native';
import UserRow from '../common/list/UserRow';
import UserRowCaptions from '../common/list/UserRowCaptions';
import UserService from '../../services/UserService';
import AddUserView from '../common/AddUserView';

const BaseContainer = styled.View`
  margin-left: 20;
  margin-right: 20;
`;

export default class AdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
        this.onPressDeleteUser = this.onPressDeleteUser.bind(this);
        this.createUser = this.createUser.bind(this);
    }
    async componentDidMount() {
        const { users } = await UserService.getUsers();
        this.setState({ users });
    }
    async createUser(newUser) {
        try {
            const {users} = this.state;
            const response = await UserService.createUser(newUser);
            newUser.id = response.user.id;
            const newArr = [...users, newUser];
            this.setState({ users: newArr })
        } catch (err) {
            console.log(err);
        }
    }
    async onUpdateUser(update) {
        try {
            const { id, ...params } = update;
            await UserService.updateUser(id, params);
        } catch (err) {
            console.log(err);
        }
    }
    async onPressDeleteUser(id) {
        try {
            await UserService.removeUser(id);
            this.setState(({ users }) => {
                const idx = users.findIndex(u => u.id === id);
                const arr1 = users.slice(0, idx);
                const arr2 = users.slice(idx + 1);
                const newArr = [
                    ...arr1,
                    ...arr2
                ]
                return { 
                    users: newArr 
                };
            });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const { users } = this.state;
        return (
            <View>
                <BaseContainer style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, height: 620, borderBottomColor: '#ccc', borderBottomWidth: '1px' }}>
                    <View style={{ width: '100%' }}>
                        <UserRowCaptions />
                        <FlatList
                            data={users}
                            extraData={users}
                            renderItem={({ item }) => <UserRow 
                                user={item} 
                                onPressDeleteUser={() => this.onPressDeleteUser(item.id) }
                                updateUser={this.onUpdateUser}>
                                </UserRow>}
                            keyExtractor={(item, index) => item.id.toString()}
                        />
                    </View>
                </BaseContainer>
                <AddUserView onAddUser={this.createUser}></AddUserView>
            </View>
        )
    }
}