import { Dimensions } from "react-native";

const WIDTH = Dimensions.get('window').width;
const HEIGHT = Dimensions.get('window').height;
const WEB_LEFT_MENU_WIDTH = 203;
const WEB_MARGIN_LEFT = -45;
const GLOBAL_HEADER_HEIGHT = 95;
const SCREEN_HEADER_HEIGHT = 70;

export const sizes = {
    GLOBAL_HEADER_HEIGHT,
    QUESTIONNAIRE_HEADER_HEIGHT: 195,
    BOTTOM_MENU_HEIGHT: 70,
    TAB_HEADER_HEIGHT: 38,
    TAB_VISITED_HEADER_HEIGHT: 27,
    SEARCH_HEIGHT: 55,
    BOTTOM_MARGIN: 5,
    TASK_ROW_CAPTION_HEIGHT: 40,
    SCREEN_HEADER_HEIGHT,
    TASK_DETAILS_WIDTH: 320,
    TASK_DETAILS_HEIGHT: 252,
    TASK_MARGIN: 100,
    WEB: {
        LEFT_MENU_WIDTH: WEB_LEFT_MENU_WIDTH,
        MARGIN_LEFT: WEB_MARGIN_LEFT,
        TASK_PREVIEW_WIDTH: WIDTH - WEB_LEFT_MENU_WIDTH - WEB_MARGIN_LEFT,
        TASK_PREVIEW_IMAGE_VIEW_HEIGHT: 850,
        GOOGLE_MAPS_WIDTH: WIDTH - WEB_LEFT_MENU_WIDTH,
        GOOGLE_MAPS_HEIGHT: HEIGHT - GLOBAL_HEADER_HEIGHT - SCREEN_HEADER_HEIGHT,
    },
    IOS: {
        MARGIN_LEFT: 0,
        TASK_PREVIEW_WIDTH: 725,
        TASK_PREVIEW_IMAGE_VIEW_HEIGHT: 800,
        QUESTIONNAIRE_WIDTH: 630,
    }
};