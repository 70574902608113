export const COLORS = {
  WHITE: {
    WHITE: '#FFFFFF',
    LIGHT: '#F8F8F8'
  },
  BLACK: {
    LIGHT: '#444',
    BLACK: '#000000',
  },
  GRAY: {
    DARK: '#656565',
    LIGHT: '#E9E9E9',
    LIGHT2: "#CFCECE",
    LIGHT3: "#F8F8F8",
  },
  GREEN: {
    LIGHT: '#30A835',
    LIGHT2: '#6CD1A7',
  },
  PURPLE: {
    DARK: '#831735',
    DARK1: '#521224',
    LIGHT: '#B06B7E',
  },
  RED: {
    LIGHT: '#E73030',
    RED: '#F00',
  },
  ORANGE: {
    LIGHT: '#D9821C',
  },
  PINK: {
    LIGHT: '#EEDFE3',
  },
  BLUE: {
    LIGHT: '#0E92CB',
  }
};
