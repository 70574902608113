import React from 'react';
import { Platform, View } from 'react-native';
import { DraftLabelText, DraftText } from '../../../constants/styles';
import { sizes } from '../../../constants/sizes';
import i18n from '../../../i18n';

const isIOS = (Platform.OS === 'ios');
const getWidth = ($width)=> {
    return $width ? (isIOS ? $width : `${$width / sizes.IOS.QUESTIONNAIRE_WIDTH * 100}%`) : 'auto';
};

export const DraftItem = (props)=>
    (<View style={{ flexDirection: props.flexDirection || 'row', marginTop: 20, marginRight: 16, paddingLeft: props.leftShift || 0, width: getWidth(props.width) }}>
        <DraftLabelText>
            {props.label + (props.noSemicolon ? ' ' : ': ')}
        </DraftLabelText>
        <DraftText>
            {props.data || '-'}
        </DraftText>
    </View>);

export const DraftItemsContainer = (props)=>
    (<View style={{ flexDirection: 'row' }}>
        {props.items.map((item, index)=>
            <DraftItem
                width={props.width}
                leftShift={props.leftShift}
                key={index}
                label={i18n.t(props.translation + '.' + item)}
                data={props.object[item]}
            />
        )}
    </View>);