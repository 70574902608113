import { CLEAR_QUESTIONNAIRE_LIST, CREATE_QUESTIONNAIRE, SET_QUESTIONNAIRES, UPDATE_QUESTIONNAIRE } from '../actions/questionnaire';
import Questionnaire from '../types/Questionnaire';

const DEFAULT_STATE = [];

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case CREATE_QUESTIONNAIRE:
            return [...state, new Questionnaire(payload).toJS()];

        case UPDATE_QUESTIONNAIRE:
            return state.map(item => (item.cemeteryId === payload.cemeteryId) ? { ...payload, isUpdated: true } : item);

        case SET_QUESTIONNAIRES:
            return payload ? payload.map(i => new Questionnaire(i).toJS()) : state;

        case CLEAR_QUESTIONNAIRE_LIST:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
