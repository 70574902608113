import { call, put, select, takeEvery } from 'redux-saga/effects';
import { setQuestionnaires, SUBMIT_QUESTIONNAIRE } from '../actions/questionnaire';
import { setTasks } from '../actions/tasks';
import { getAuth } from '../reducers';
import SyncService from '../services/SyncService';

function* submitQuestionnaireHandler({ payload, onAfterSaga }) {
    if (!payload) return null;

    const { token } = yield select(getAuth)

    const response = yield call(
        SyncService.submitQuestionnaire,
        payload,
        token
    );

    if (response.error) {
        console.log('[SERVER ERROR]: submitQuestionnaireHandler ->', response.error);
        onAfterSaga(false, response.error);
        return null;
    }

    const data = yield call(
        SyncService.getAll,
        token
    );

    yield put(setTasks(data.tasks));
    yield put(setQuestionnaires(data.questionnaires));

    onAfterSaga(true);
}

function* tasksWatcher() {
    yield takeEvery(SUBMIT_QUESTIONNAIRE, submitQuestionnaireHandler);
}

export default tasksWatcher;
