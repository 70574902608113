import { all, fork } from 'redux-saga/effects';
import auth from './auth';
import location from './location';
import mediacontent from './mediacontent';
import questionnaires from './questionnaires';
import sync from './sync';
import tasks from './tasks';

const sagas = [
    mediacontent,
    auth,
    tasks,
    sync,
    questionnaires,
    location,
];

export default function* root() {
    yield all(sagas.map(saga => fork(saga)));
}
