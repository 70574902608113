export const ROLES = {
  NAMES: {
    HISTORIAN: 'HISTORIAN',
    MOBILE_GROUP: 'MOBILE_GROUP',
    EDITOR: 'EDITOR',
    HISTORIAN_READONLY: 'HISTORIAN_READONLY'
  },
  RESTRICTIONS: {
    CAN_EDIT: 'isCanEdit',
    CAN_CREATE: 'isCanCreate',
    NO_ACCESS: 'noAccess',
    NO_RESTRICTIONS: 'noRestrictions',
  }
};