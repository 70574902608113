import React, {Component} from 'react';
import {Container} from '../../constants/styles';
import {ButtonSubmit} from './ButtonSubmit';

export class Form extends Component {
    render() {
        return (
            <Container>
                {this.props.children}
                <ButtonSubmit _onPress={this.props.onButtonPress}
                              name={this.props.buttonName}/>
            </Container>
        );
    }
}
