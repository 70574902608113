import {Platform, Alert} from 'react-native';

export const multiPlatformAlert = (title, content, buttons) => {
    if (Platform.OS === 'ios') {
        Alert.alert(
            title,
            content,
            buttons,
            {cancelable: false}
        );
    } else {
        const alertContent = `${title}: ${content}`;
        const cancelButton = buttons.find((button) => button.id === 'cancel');
        if (cancelButton) {
            const okButton = buttons.find((button) => button.id === 'ok');
            //eslint-disable-next-line
            if (confirm(alertContent)) {
                okButton.onPress();
            }
        } else {
            //eslint-disable-next-line
            alert(alertContent);
        }
    }

};