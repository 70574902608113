import React, { Component } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { Input } from '../';
import { YES_OR_NO } from '../../../constants/radiobuttons';
import { BaseContainer, ButtonContainer, ButtonText, ErrorText, modalSelectorSelectWithErrorStyle, modalSelectorTextStyle, modalSelectorTextWithErrorStyle, selectorStyle, SeparatedView, SmallText, Text, iosTooltipContainerBorder } from '../../../constants/styles';
import { attachComponentField } from '../../../helpers/attachComponentField';
import { updateData } from '../../../helpers/updateQuestionnaireHelper';
import i18n from '../../../i18n';
import AddImages from '../AddImages';
import AddInterview from '../AddInterview';

const isWeb = (Platform.OS === 'web');
const SELECTS = {
    urgency_of_erecting_fence: i18n.t('SELECTS.urgency_of_erecting_fence').map(attachComponentField),
    ownership: i18n.t('SELECTS.ownership').map(attachComponentField),
};
const CommentWithText = (props)=> {
    return (
        <View>
            <Text style={{ marginTop: isWeb ? 15 : 5, marginLeft: isWeb ? 0 : 5 }}>{props.text}</Text>
            <Input
                _viewStyle={{ marginTop: isWeb ? 10 : 5, marginBottom: 15 }}
                height={100}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                multiline={true}
                numberOfLines={4}
                onChangeText={props.onChangeText}
            />
        </View>);
};

export default class TabDetails extends Component {
    constructor(props) {
        super(props);
        const details = (props.questionnaire && props.questionnaire.details) || {};
        const gravestones = details.gravestones || {};
        const isGravestones = gravestones.isGravestones || 0;
        const isVandalism = (details.vandalism || {}).isVandalism || 0;
        const isDroneSurvey = (details.droneSurvey || {}).isDroneSurvey || 0;
        const numberOfGravestones = (gravestones.numberOfGravestones || '').toString();
        const urgencyOfErectingId = (details.erectingFence || {}).urgencyOfErectingId || '';
        const erectingFence = (urgencyOfErectingId && i18n.t('SELECTS.urgency_of_erecting_fence')[urgencyOfErectingId - 1]) || '';
        const ownershipId = (details.ownership || {}).ownershipId || 0;
        const ownership = (ownershipId && i18n.t('SELECTS.ownership')[details.ownership.ownershipId - 1]) || '';
        this.state = {
            isGravestones,
            isVandalism,
            isDroneSurvey,
            numberOfGravestones,
            numberOfGravestonesError: '',
            ownership,
            ownershipId,
            erectingFence,
            urgencyOfErectingId,
            erectingFenceError: ''
        };
        this.checkErectingFence = this.checkErectingFence.bind(this);
        this.updateErectingFence = this.updateErectingFence.bind(this);
        this.updateNumberOfGravestones = this.updateNumberOfGravestones.bind(this);
        this.openGallery = this.openGallery.bind(this);
    }

    componentDidMount() {
        this.update = (val, opt, isInput=false) => {
            updateData(val, 'details', opt, this.props.update, isInput);
        };
    }
    componentWillUnmount() {
        updateData('', 'details', {}, this.props.update, true);
    }

    updateNumberOfGravestones(opt, validate) {
        const val = this.state.numberOfGravestones;
        // const isValid = validate(val);
        const isValid = true;
        if (isValid) {
            const numberOfGravestones = val.toString();
            this.setState({ numberOfGravestones, numberOfGravestonesError: '' });
            this.update(numberOfGravestones, opt);
        } else {
            this.setState({ numberOfGravestones: '', numberOfGravestonesError: i18n.t('text.error_number_of_gravestones') });
        }
    }

    openGallery(data, updateData) {
        this.props.openGallery(data, { tabId: 3, updateData: { ...updateData, sectionName: 'details' } });
    }

    checkErectingFence(val = '') {
        const erectingFence = val || this.state.erectingFence;
        if (erectingFence === i18n.t('text.urgency_of_erecting_fence')) {
            this.setState({ erectingFenceError: i18n.t('text.error_urgency_of_erecting_fence') });
        } else {
            this.setState({ erectingFenceError: '' })
        }
    }

    updateErectingFence(erectingFence, opt) {
        if (this.state.erectingFenceError) {
            this.checkErectingFence(erectingFence.label);
        }
        this.setState({ erectingFence: erectingFence.label });
        this.update(erectingFence.id, opt);
    };

    render() {
        const { imageManipulator, imagePicker, permissions } = this.props.elements;
        const ModalSelector = this.props.elements.select;
        const RadioGroup = this.props.elements.radio;
        const CustomTooltip = this.props.elements.tooltip;

        const questionnaire = this.props.questionnaire;
        const details = questionnaire && questionnaire.details;
        if (details) {
            const {gravestones={}, vandalism={}, droneSurvey={}} = details || {};
            const isVandalism = vandalism.isVandalism || 0;
            const isGravestones = gravestones.isGravestones || 0;
            const isDroneSurvey = droneSurvey.isDroneSurvey || 0;
            return (
                <View style={{ height: isWeb ? 'auto' : this.props.height }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, height: 'auto' }}>
                        <BaseContainer>
                            <View style={{ marginTop: 15, marginBottom: 15 }}>
                                <RadioGroup
                                    caption={i18n.t('text.gravestones')}
                                    initialValue={isGravestones ? 1 : 0}
                                    captionWidth={isWeb ? 350 : '46%'}
                                    formData={YES_OR_NO}
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                    reverse={true}
                                    onSelect={(val) => {
                                        this.setState({ isGravestones: val });
                                        this.update(val, { rootField: 'gravestones', valueField: 'isGravestones', isBool: true })
                                    }}>
                                    <CustomTooltip
                                        height={80}
                                        childWidth={'100%'}
                                        paragraphs={[i18n.t('tooltips.number_of_gravestones')]}>
                                        <View style={{ ...iosTooltipContainerBorder, marginBottom: 10, marginTop: isWeb ? 0 : -5 }}>
                                            <Input
                                                pointerEvents={!this.state.isGravestones ? 'none' : 'auto'}
                                                disabled={!this.state.isGravestones}
                                                width={isWeb ? 250 : 190}
                                                defaultValue={this.state.numberOfGravestones}
                                                _viewStyle={{ marginBottom: 0 }}
                                                placeholder={i18n.t('text.number_of_gravestones')}
                                                error={this.state.numberOfGravestonesError}
                                                onChangeText={(value) => {
                                                    this.setState({ numberOfGravestones: value });
                                                }}
                                                _onBlur={() => {
                                                    this.updateNumberOfGravestones({ rootField: 'gravestones', valueField: 'numberOfGravestones' }, (value) => value && Number.isInteger(Number(value)))

                                                }}
                                            />
                                        </View>
                                    </CustomTooltip>
                                </RadioGroup>
                                <View style={{ marginBottom: 20 }}>
                                    <Input
                                        height={100}
                                        defaultValue={gravestones.comment}
                                        placeholder={i18n.t('text.comments_and_additional_info')}
                                        multiline={true}
                                        numberOfLines={4}
                                        onChangeText={(val) => {
                                            this.update(val, { rootField: 'gravestones', valueField: 'comment' }, true)
                                        }}
                                    />
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Input
                                        defaultValue={gravestones.dateOfEarliest}
                                        placeholder={i18n.t('text.date_of_gravestone_earlier')}
                                        onChangeText={(val) => {
                                            this.update(val, { rootField: 'gravestones', valueField: 'dateOfEarliest' })
                                        }}
                                    />
                                </View>
                                <View style={{ marginBottom: 20 }}>
                                    <Input
                                        defaultValue={gravestones.dateOfLast}
                                        placeholder={i18n.t('text.date_of_gravestone_last')}
                                        onChangeText={(val) => {
                                            this.update(val, { rootField: 'gravestones', valueField: 'dateOfLast' })
                                        }}
                                    />
                                </View>
                                <AddImages
                                    isWeb={isWeb}
                                    fileInput={this.props.elements.fileInput}
                                    elements={{ imageManipulator, imagePicker, permissions }}
                                    questionnaireId={questionnaire.cemeteryId}
                                    updateSettings={{ rootField: 'gravestones', valueField: 'pictures', sectionName: 'details' }}
                                    openGallery={this.openGallery}
                                    images={gravestones.pictures}
                                    update={this.props.update}
                                />
                                <View style={{ marginBottom: 20 }}>
                                    {!!this.state.urgencyOfErectingId && <Text style={{ marginBottom: isWeb ? 10 : 5, marginLeft: isWeb ? 0 : 5 }}>{i18n.t('text.urgency_of_erecting_fence')}</Text>}
                                    <ModalSelector
                                        cancelText={i18n.t('cancel')}
                                        animationType={'none'}
                                        data={SELECTS.urgency_of_erecting_fence}
                                        initValueTextStyle={modalSelectorTextStyle}
                                        selectTextStyle={this.state.erectingFenceError ? modalSelectorTextWithErrorStyle : modalSelectorTextStyle}
                                        selectStyle={this.state.erectingFenceError ? modalSelectorSelectWithErrorStyle : selectorStyle}
                                        initValue={this.state.erectingFence || i18n.t('text.urgency_of_erecting_fence')}
                                        onChange={(val) => {
                                            this.setState({ erectingFence: val.label, urgencyOfErectingId: val.id });
                                            this.updateErectingFence(val, { rootField: 'erectingFence', valueField: 'urgencyOfErectingId' })
                                        }}
                                        onModalClose={() => {
                                            this.checkErectingFence()
                                        }}
                                    />
                                    {!!this.state.erectingFenceError && <ErrorText style={{ marginBottom: 20 }}>{this.state.erectingFenceError}</ErrorText>}
                                    {!!this.state.urgencyOfErectingId && (
                                        <Input
                                            _viewStyle={{ marginTop: 10, marginBottom: 15 }}
                                            height={100}
                                            defaultValue={details.erectingFence.comment}
                                            placeholder={i18n.t('text.comments_and_additional_info')}
                                            multiline={true}
                                            numberOfLines={4}
                                            onChangeText={(val) => {
                                                this.update(val, { rootField: 'erectingFence', valueField: 'comment' }, true)
                                            }}
                                        />
                                    )}
                                </View>
                                <RadioGroup
                                    caption={i18n.t('text.any_vandalism')}
                                    captionWidth={290}
                                    initialValue={isVandalism ? 1 : 0}
                                    formData={YES_OR_NO}
                                    reverse={true}
                                    onSelect={(val) => {
                                        this.setState({ isVandalism: val });
                                        this.update(val, { rootField: 'vandalism', valueField: 'isVandalism', isBool: true })
                                    }}
                                />
                                {!!this.state.isVandalism && (
                                    <Input
                                        _viewStyle={{ marginTop: 15, marginBottom: 15 }}
                                        height={100}
                                        defaultValue={details.vandalism.comment}
                                        placeholder={i18n.t('text.comments_and_additional_info')}
                                        multiline={true}
                                        numberOfLines={4}
                                        onChangeText={(val) => {
                                            this.update(val, { rootField: 'vandalism', valueField: 'comment', isBool: false }, true)
                                        }}
                                    />
                                )}
                                <AddImages
                                    isWeb={isWeb}
                                    fileInput={this.props.elements.fileInput}
                                    elements={{ imageManipulator, imagePicker, permissions }}
                                    questionnaireId={questionnaire.cemeteryId}
                                    updateSettings={{ rootField: 'vandalism', valueField: 'pictures', sectionName: 'details' }}
                                    openGallery={this.openGallery}
                                    images={details.vandalism.pictures}
                                    update={this.props.update}
                                />
                                <View style={{ marginBottom: 20 }}>
                                    {!!this.state.ownershipId && <Text style={{ marginBottom: isWeb ? 10 : 5, marginLeft: isWeb ? 0 : 5 }}>{i18n.t('text.ownership')}</Text>}
                                    <ModalSelector
                                        cancelText={i18n.t('cancel')}
                                        animationType={'none'}
                                        data={SELECTS.ownership}
                                        initValueTextStyle={modalSelectorTextStyle}
                                        selectTextStyle={modalSelectorTextStyle}
                                        selectStyle={selectorStyle}
                                        initValue={this.state.ownership || i18n.t('text.ownership')}
                                        onChange={(val) => {
                                            this.setState({ ownership: val.label, ownershipId: val.id });
                                            this.update(val.id, { rootField: 'ownership', valueField: 'ownershipId' })
                                        }}
                                    />
                                    {!!this.state.ownershipId && (
                                        <Input
                                            _viewStyle={{ marginTop: 10, marginBottom: 15 }}
                                            height={100}
                                            defaultValue={details.ownership.comment}
                                            placeholder={i18n.t('text.comments_and_additional_info')}
                                            multiline={true}
                                            numberOfLines={4}
                                            onChangeText={(val) => {
                                                this.update(val, { rootField: 'ownership', valueField: 'comment' }, true)
                                            }}
                                        />
                                    )}
                                </View>
                                <AddImages
                                    isWeb={isWeb}
                                    fileInput={this.props.elements.fileInput}
                                    elements={{ imageManipulator, imagePicker, permissions }}
                                    questionnaireId={questionnaire.cemeteryId}
                                    updateSettings={{ rootField: 'ownership', valueField: 'pictures', sectionName: 'details' }}
                                    openGallery={this.openGallery}
                                    images={details.ownership.pictures}
                                    update={this.props.update}
                                >
                                    <SmallText>
                                        {i18n.t('text.photos_must_be_horizontal')}
                                    </SmallText>
                                </AddImages>
                                <CommentWithText
                                    defaultValue={details.comment}
                                    text={i18n.t('text.any_structures')}
                                    onChangeText={(val) => this.update(val, { rootField: 'comment' }, true)}
                                />
                                <CommentWithText
                                    defaultValue={details.anyMassGravesComment}
                                    text={i18n.t('text.any_mass_graves')}
                                    onChangeText={(val) => this.update(val, { rootField: 'anyMassGravesComment' }, true)}
                                />
                                <CommentWithText
                                    defaultValue={details.anySpecificThreatsComment}
                                    text={i18n.t('text.any_specific_threats')}
                                    onChangeText={(val) => this.update(val, { rootField: 'anySpecificThreatsComment' }, true)}
                                />
                                <View style={{ marginTop: 15}} >
                                    <Text style={{ marginLeft: isWeb ? 0 : 5 }}>{i18n.t('text.description_historical_things')}</Text>
                                    <Input
                                        _viewStyle={{ marginTop: isWeb ? 10 : 5, marginBottom: 15 }}
                                        height={100}
                                        defaultValue={details.description}
                                        placeholder={i18n.t('text.comments_and_additional_info')}
                                        multiline={true}
                                        numberOfLines={4}
                                        onChangeText={(val) => this.update(val, { rootField: 'description' }, true)}
                                    />
                                </View>
                                <AddImages
                                    isWeb={isWeb}
                                    fileInput={this.props.elements.fileInput}
                                    elements={{ imageManipulator, imagePicker, permissions }}
                                    questionnaireId={questionnaire.cemeteryId}
                                    updateSettings={{ rootField: 'pictures', sectionName: 'details' }}
                                    openGallery={this.openGallery}
                                    images={details.pictures}
                                    update={this.props.update}
                                />
                                <AddInterview
                                    fileInput={this.props.elements.fileInput}
                                    questionnaireId={questionnaire.cemeteryId}
                                    data={details.interview}
                                    elements={this.props.elements}
                                    update={this.props.update}
                                    updateSettings={{ sectionName: 'details', rootField: 'interview' }}
                                />
                                <View>
                                    <Input
                                        _viewStyle={{ marginTop: 15, marginBottom: 15 }}
                                        height={100}
                                        defaultValue={details.contact}
                                        placeholder={i18n.t('text.contact_person')}
                                        multiline={true}
                                        numberOfLines={4}
                                        onChangeText={(val) => {
                                            this.update(val, { rootField: 'contact' }, true)
                                        }}
                                    />
                                </View>
                                <RadioGroup
                                    caption={i18n.t('text.drone_survey')}
                                    captionWidth={200}
                                    initialValue={isDroneSurvey ? 1 : 0}
                                    formData={YES_OR_NO}
                                    reverse={true}
                                    onSelect={(val) => {
                                        this.setState({ isDroneSurvey: val });
                                        this.update(val, { rootField: 'droneSurvey', valueField: 'isDroneSurvey', isBool: true })
                                    }}
                                />
                                {!this.state.isDroneSurvey && (
                                    <CommentWithText
                                        defaultValue={droneSurvey.comment}
                                        placeholder={i18n.t('text.drone_survey_description')}
                                        text={i18n.t('text.drone_survey_description')}
                                        onChangeText={(val) => this.update(val, { rootField: 'droneSurvey', valueField: 'comment' }, true)}
                                    />
                                )}
                                {!!this.props.saveQuestionnaire &&
                                    <SeparatedView style={{ marginTop: 20 }}>
                                        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <ButtonContainer width={140} onPress={this.props.saveQuestionnaire}>
                                                <ButtonText>{i18n.t('buttons.save')}</ButtonText>
                                            </ButtonContainer>
                                        </View>
                                    </SeparatedView>
                                }
                            </View>
                        </BaseContainer>
                    </ScrollView>
                </View>
            );
        } else {
            return <View />
        }
    }
}
