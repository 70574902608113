import React, { Component } from 'react';
import { Alert, Image, Platform, TouchableOpacity } from 'react-native';
import { getFullName } from '../../../helpers/getProperty';
import { formatDate } from '../../../helpers/formatDate';
import { checkCanEdit } from '../../../helpers/checkPermissions';
import checkIsSubmitted from '../../../helpers/checkIsSubmitted';
import { setStatus, setStatusReady } from '../../../helpers/getStatusElement';
import { ItemContainer, ItemDivider, ItemVerticalContainer, ListItemText, anchorStyle, listItemsStyle } from '../../../constants/styles';
import viewImg from '../../../assets/images/ic_view.png';
import i18n from '../../../i18n';

const platform = Platform.OS;
const isIOS = (platform === 'ios');
const platformListItemsStyle = listItemsStyle[platform];

export class TaskRow extends Component {
    render() {
        const Link = this.props.link;
        const {data, paths, readonly, role} = this.props;
        const isCanEdit = checkCanEdit(role, readonly);
        const isSubmitted = data.task && data.task.status && checkIsSubmitted(data.task);
        const path = (isCanEdit || data.task) && {pathname: data.task ? paths.task : paths.edit, state: {data: data, readonly: readonly, canEdit: isCanEdit}};
        const onPress = (event) => {
            if(isIOS && !data.task && isCanEdit) {
                Alert.alert(
                    i18n.t('warning'),
                    i18n.t('text.no_edit'),
                    [
                        {
                            id: 'ok',
                            text: i18n.t('ok'),
                        },
                    ]
                );
                event.preventDefault();
            }
        };
        const onPressAttribute = isIOS ? {onPress: onPress} : {onClick: onPress};

        return (
            <ItemVerticalContainer>
                <TouchableOpacity>
                    <Link to={path || ''} style={!isIOS ? anchorStyle : {}} {...onPressAttribute} >
                        <ItemContainer>
                            <ListItemText style={platformListItemsStyle.name}>
                                {data.name}
                            </ListItemText>
                            <ListItemText style={platformListItemsStyle.country}>
                                {data.country}
                            </ListItemText>
                            <ListItemText style={platformListItemsStyle.region}>
                                {data.region}
                            </ListItemText>
                            <ListItemText style={platformListItemsStyle.district}>
                                {data.district}
                            </ListItemText>
                            {!isIOS && <ListItemText style={platformListItemsStyle.author}>
                                {getFullName(data.author)}
                            </ListItemText>}
                            {!isIOS && <ListItemText style={platformListItemsStyle.surveyor}>
                                {(data.questionnaire && data.questionnaire.author && getFullName(data.questionnaire.author)) || '-'}
                            </ListItemText>}
                            {!isIOS && <ListItemText style={platformListItemsStyle.createdAt}>
                                {(isSubmitted && data.questionnaire && data.questionnaire.mobileGroupSubmitDate && formatDate(data.questionnaire.mobileGroupSubmitDate)) || '-'}
                            </ListItemText>}
                            {isIOS && setStatus(data)}
                            {!isIOS && <ListItemText style={platformListItemsStyle.status}>
                                {setStatus(data)}
                            </ListItemText>}
                            {!isIOS && <ListItemText style={platformListItemsStyle.historicalInformation}>
                                {setStatusReady(data.questionnaire, 'historian')}
                            </ListItemText>}
                            {!isIOS && <ListItemText style={platformListItemsStyle.editorInformation}>
                                {setStatusReady(data.questionnaire, 'editor')}
                            </ListItemText>}
                            {isIOS && <Image source={viewImg} style={{ width: 25, height: 15 }} />}
                        </ItemContainer>
                    </Link>
                </TouchableOpacity>
                <ItemDivider />
            </ItemVerticalContainer>
        );
    }
}