const getTwoDigitsNumber = (number)=> {
    return number > 9 ? number : '0' + number
};
export const formatDate = (date) =>{
    if (!date || date.trim() === '') {
        console.log('Date error');
        return '';
    } else {
        const dateObject = new Date(date);
        const separator = '.';
        return getTwoDigitsNumber(dateObject.getDate()) + separator + getTwoDigitsNumber(dateObject.getMonth()+1) + separator + dateObject.getFullYear();
    }
};
