import React, { Component } from 'react';
import { Dimensions, FlatList, Platform, View } from 'react-native';
import { sizes } from '../../../constants/sizes';
import i18n from '../../../i18n';
import { TaskRow } from './TaskRow';
import { TaskRowCaptions } from './TaskRowCaptions';

const isIOS = (Platform.OS === 'ios');
const MENU_SHIFT = sizes.BOTTOM_MENU_HEIGHT + sizes.BOTTOM_MARGIN;
const TASKS_LIST_NON_CONTENT_HEIGHT = sizes.GLOBAL_HEADER_HEIGHT + sizes.SEARCH_HEIGHT + sizes.TASK_ROW_CAPTION_HEIGHT + (isIOS ? MENU_SHIFT : 0);
const TASKS_LIST_CONTENT_HEIGHT = Dimensions.get('window').height - TASKS_LIST_NON_CONTENT_HEIGHT;

export class TasksList extends Component {
    _renderItem(item) {
        return (
            <TaskRow
                data={item}
                link={this.props.link}
                paths={this.props.paths}
                readonly={this.props.readonly}
                role={this.props.role}
            />
        )
    }

    render() {
        let captions = {};
        if (isIOS) {
            captions = {
                name: i18n.t('task.name'),
                country: i18n.t('task.country'),
                region: i18n.t('task.region'),
                district: i18n.t('task.district'),
                status: i18n.t('task.status'),
            };
        } else {
            captions = {
                name: i18n.t('task.name'),
                country: i18n.t('task.country'),
                region: i18n.t('task.region'),
                district: i18n.t('task.district'),
                createdBy: i18n.t('task.object_created_by'),
                surveyor: i18n.t('task.surveyor'),
                createdAt: i18n.t('task.submitted_date'),
                status: i18n.t('task.status'),
                historicalInformation: i18n.t('task.historical_researcher'),
                editorInformation: i18n.t('task.editor'),
            };
        }

        const { data } = this.props;
        const viewStyle = {
            height: TASKS_LIST_CONTENT_HEIGHT,
            marginRight: isIOS ? 0 : 10
        };

        return (
            <View style={viewStyle}>
                <TaskRowCaptions data={captions} sort={this.props.sort}/>
                {data.length ? (
                    <FlatList
                        data={data}
                        renderItem={({ item }) => this._renderItem(item)}
                        keyExtractor={(item, index) => index.toString()}
                    />
                ) : null}
            </View>
        )
    }
}