import React, { Component } from 'react';
import { Dimensions, Image, ScrollView, TouchableOpacity, View, Platform } from 'react-native';
import { connect } from 'react-redux';
import { changeImagesUploadStatus, deleteImagesAction, deleteImageWhenPossible, uploadImage } from '../../actions/questionnaire';
import config from '../../config';
import { addImages } from '../../helpers/addImages';
import deleteImages from '../../helpers/deleteImages';
import { ButtonContainer, buttonsContainerStyle, ButtonText, HeaderText } from '../../constants/styles';
import { sizes } from '../../constants/sizes';
import { COLORS } from '../../constants/colors';
import preloadImage from '../../assets/icons/preload.gif';
import yesIcon from '../../assets/icons/yes-icon.png';
import noPicture from '../../assets/icons/no-picture.png';
import download from '../../assets/images/download.png';
import i18n from '../../i18n';

const isIOS = Platform.OS === 'ios';
const styleClicked = {
    borderWidth: 10,
    borderColor: COLORS.PURPLE.DARK,
};

const { height, width } = Dimensions.get('window');
const $width = isIOS ? width - 46 : '100%';
const $height = height - (isIOS ? (sizes.GLOBAL_HEADER_HEIGHT + sizes.QUESTIONNAIRE_HEADER_HEIGHT + sizes.BOTTOM_MENU_HEIGHT) : 100);

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
            isSelectable: false,
            areImagesSelectedForDelete: false,
            selectedImage: null,
            isImagesLoading: [],
            isOriginalLoading: false,
        };

        this._onSelectImage = this._onSelectImage.bind(this);
        this._addImages = addImages.bind(this);
        this._deleteImages = deleteImages.bind(this);
        this.addOrDelete = this.addOrDelete.bind(this);
        this.selectToggle = this.selectToggle.bind(this);
        this.deleteImages = this.deleteImages.bind(this);
    }

    componentDidMount() {
        if (this.state.images.length !== this.props.images.length) {
            this.setState({
                images: this.props.images.map(i => ({ ...i, clicked: false }))
            });
        }

        this.props.location.state = {
            ...this.props.location.state,
            cancelLastAction: () => this.props.closeGallery(this.state.images, this.props.updateSettings)
        }
    }

    _onSelectImage(image) {
        let areSelectedImages = false;
        if (this.state.isSelectable) {
            const images = this.state.images.map(i => {
                let item = i;
                if ((image.id && image.id === i.id) || (image.preId && image.preId === i.preId)) {
                    item['clicked'] = !i.clicked;
                }
                areSelectedImages = areSelectedImages || item.clicked;
                return item;
            });
            this.setState({ images, areImagesSelectedForDelete: areSelectedImages });
        } else {
            this.setState({ selectedImage: image });
        }
    }

    deleteImages() {
        let images = this.state.images;
        images = images.filter(i => i.clicked);

        const filteredImages = this.state.images.filter(i => !i.clicked);
        if (images.length) {
            this._deleteImages(images.map(imageObject => ({
                id: imageObject.id || imageObject.preId,
                questionnaireId: this.props.questionnaireId,
                params: this.props.updateSettings,
            })));

            this.props.update(filteredImages, this.props.updateSettings);
            this.setState({ images: filteredImages, areImagesSelectedForDelete: false });
        }
    }

    addOrDelete() {
        if (this.state.isSelectable) {
            this.deleteImages();
        } else {
            this._addImages({ isState: true });
        }
    }

    selectToggle() {
        let images = this.state.images;
        if (this.state.isSelectable) {
            images = this.state.images.map(i => ({ ...i, clicked: false }));
        }
        this.setState({
            isSelectable: !this.state.isSelectable,
            images,
        });
    }

    render() {
        const isDisabled = this.props.readonly || this.props.draft[this.props.activeTabName];
        const style = {
            width: (this.state.selectedImage ? 70 : 130),
            height: (this.state.selectedImage ? 70 : 130),
        };
        const areImagesInGallery = this.state.images.length > 0;
        const canDeleteImages = this.state.isSelectable && !this.props.imagesUploadingStatus;

        return (
            <View style={{ position: isIOS ? 'relative' : 'absolute', height: $height, width: $width, zIndex: 100, backgroundColor: COLORS.WHITE.WHITE }}>
                <HeaderText style={{ marginLeft: 20, marginTop: isIOS ? -35 : 10, marginBottom: isIOS ? 20 : 10 }}>
                    {!areImagesInGallery
                        ? i18n.t('text.no_photos')
                        : (this.state.isSelectable
                            ? i18n.t('text.select_photos')
                            : i18n.t('text.downloaded_photos'))
                    }
                </HeaderText>
                <ScrollView style={{ height: (this.state.images.length / 5) * 130 + (20 * 5), marginBottom: 100 }}>
                    {
                        this.state.selectedImage && (
                            <TouchableOpacity onPress={() => this.setState({ selectedImage: null })} style={{ marginBottom: isIOS ? 40 : 10, marginLeft: isIOS ? 20 : 0, width: $width, height: 400, backgroundColor: COLORS.GRAY.LIGHT, position: 'relative' }}>
                                <Image
                                    onLoadStart={() => this.setState({ isOriginalLoading: true })}
                                    onLoadEnd={() => this.setState({ isOriginalLoading: false })}
                                    source={{
                                        uri: this.state.selectedImage.id
                                            ? `${config.hostname}picture/${this.state.selectedImage.id}?size=middle`
                                            : this.state.selectedImage.base64
                                    }}
                                    defaultSource={noPicture}
                                    style={{ width: $width, height: 400 }}
                                    resizeMode={'contain'}
                                />
                                {this.state.isOriginalLoading && (
                                    <View style={{ backgroundColor: COLORS.GRAY.LIGHT, position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <Image
                                            source={preloadImage}
                                        />
                                    </View>
                                )}
                            </TouchableOpacity>
                        )
                    }
                    <View>
                        <View
                            style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: $width, width: $width, paddingLeft: 10, paddingRight: 18 }}
                            numColumns={5}
                        >
                            {this.state.images.map(item => {
                                const isLoading = !!this.state.isImagesLoading.filter(i => item.preId === i.preId).length;
                                const imageUri = item.id
                                    ? `${config.hostname}picture/${item.id}`
                                    : item.base64 || item.small;

                                return (
                                    <TouchableOpacity onPress={() => this._onSelectImage(item)} key={(item.id || item.preId)} style={{ ...style, margin: 9, backgroundColor: COLORS.GRAY.LIGHT }}>
                                        <View style={{ position: 'relative' }}>
                                            <Image
                                                style={item.clicked ? { ...style, ...styleClicked } : style}
                                                defaultSource={noPicture}
                                                source={{ uri: imageUri.indexOf(config.hostname) > -1 ? `${imageUri}?size=small` : imageUri}}
                                            />
                                            {!isIOS && this.state.selectedImage &&
                                            <a href={`${imageUri}/download`} target={'_blank'} style={{position: 'absolute', right: 0, top: 0}} >
                                                <Image
                                                    source={download}
                                                    resizeMode={'contain'}
                                                    style={{ width: 25, height: 25 }}
                                                />
                                            </a>
                                            }
                                            {item.clicked && (
                                                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.PURPLE.DARK, position: 'absolute', top: 0, right: 0, width: 36, height: 36 }}>
                                                    <Image
                                                        source={yesIcon}
                                                        resizeMode={'contain'}
                                                        style={{ width: 14, height: 14 }}
                                                    />
                                                </View>
                                            )}
                                            {isLoading && (
                                                <Image
                                                    source={preloadImage}
                                                    style={{ ...style, position: 'absolute', top: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                                                    resizeMode={'cover'}
                                                />
                                            )}
                                        </View>
                                    </TouchableOpacity>
                                );
                            })}
                        </View>
                    </View>
                </ScrollView>
                <View style={buttonsContainerStyle}>
                    {!isDisabled && areImagesInGallery && isIOS &&
                        <ButtonContainer width={130} onPress={this.addOrDelete}>
                            <ButtonText>
                                {canDeleteImages
                                    ? i18n.t('buttons.delete_photo')
                                    : i18n.t('buttons.add_photo')}
                            </ButtonText>
                        </ButtonContainer>}
                    {!isDisabled && areImagesInGallery && canDeleteImages && !isIOS &&
                        <ButtonContainer width={130} onPress={this.deleteImages}>
                            <ButtonText>{i18n.t('buttons.delete_photo')}</ButtonText>
                        </ButtonContainer>}
                    {!isDisabled && areImagesInGallery &&
                        <ButtonContainer width={130} style={{ marginRight: 20 }} onPress={this.selectToggle}>
                            <ButtonText>
                                {this.state.isSelectable
                                    ? (this.state.areImagesSelectedForDelete ? i18n.t('buttons.cancel') : i18n.t('buttons.back'))
                                    : i18n.t('buttons.select')}
                            </ButtonText>
                        </ButtonContainer>}
                    {(!this.state.isSelectable || !areImagesInGallery) && !this.props.imagesUploadingStatus &&
                        <ButtonContainer width={130} style={{ marginRight: 30, backgroundColor: COLORS.GRAY.LIGHT2 }} onPress={() => this.props.closeGallery(this.state.images, this.props.updateSettings)}>
                            <ButtonText style={{ color: COLORS.GRAY.DARK }} >
                                {i18n.t('buttons.back')}
                            </ButtonText>
                        </ButtonContainer>}
                </View>
            </View>
        );
    }
}

const dispatch2props = () => dispatch => ({
    changeImagesUploadStatus: (isUploading) => dispatch(changeImagesUploadStatus(isUploading)),
    uploadImage: (payload, onAfterSaga) => dispatch(uploadImage(payload, onAfterSaga)),
    deleteImages: (payload, onAfterSaga) => dispatch(deleteImagesAction(payload, onAfterSaga)),
    deleteImageWhenPossible: (payload) => dispatch(deleteImageWhenPossible(payload)),
});

const state2props = () => state => ({
    imagesUploadingStatus: (state.imagesUpload || {}).status,
});


export default connect(state2props, dispatch2props)(Gallery);
