export const LOGIN = 'LOGIN';
export const login = (credentials, onAfterSaga) => {
  return ({
    type: LOGIN,
    payload: {
      credentials,
      onAfterSaga,
    },
  });
};

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const loginFailed = payload => ({
  type: LOGIN_FAILED,
  payload,
});

export const LOGOUT = 'LOGOUT';
export const logout = payload => ({
  type: LOGOUT,
  payload,
});

export const SET_AUTH_INFORMATION = 'SET_AUTH_INFORMATION';
export const setAuthInformation = payload => ({
  type: SET_AUTH_INFORMATION,
  payload,
});

export const DESTROY_DATA = 'DESTROY_DATA';
export const destroyData = () => ({
  type: DESTROY_DATA,
});

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const updateAuth = payload => ({
  type: UPDATE_AUTH,
  payload,
});
