import React, { Component } from 'react';
import { Platform, View, TouchableOpacity, Image } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { ListItemText, listItemsStyle } from '../../../constants/styles';
import { sizes } from '../../../constants/sizes';
import sortAsc from '../../../assets/icons/sortAsc.png';
import sortDesc from '../../../assets/icons/sortDesc.png';

const isWeb = (Platform.OS === 'web');
const platformListItemsStyle = listItemsStyle[Platform.OS];
const sortImageStyle = {
    position: isWeb ? 'absolute' : 'relative',
    top: isWeb ? '45%' : 0,
    marginLeft: 10,
    width: 11,
    height: 7,
    resizeMode: 'contain'
};
export class TaskRowCaptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortByAscending: {
                name: true,
                country: true,
                region: true,
                district: true,
                author: true,
                surveyor: true,
                mobileGroupSubmitDate: true,
                status: true,
                historianSubmitted: true,
                editorSubmitted: true
            }
        };
        this.sortField = this.sortField.bind(this);
    }

    sortField(field) {
        const sortByAscending = {...this.state.sortByAscending};
        const isByAscending = sortByAscending[field];
        this.props.sort(field, isByAscending);
        sortByAscending[field] = !isByAscending;
        this.setState({sortByAscending});
    }

    render() {
        const tabsCaptionContainerStyle = {
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 30,
            paddingRight: isWeb ? 0 : 30,
            height: sizes.TASK_ROW_CAPTION_HEIGHT,
            backgroundColor: COLORS.WHITE.LIGHT
        };
        const tabsCaptionItemStyle = {
            fontWeight: '300',
            fontSize: 13
        };
        const getItemStyle = (item)=> {
            return {
                width: platformListItemsStyle[item].width
            }
        };
        const { data } = this.props;
        if(!data) return;

        return (
            <View style={tabsCaptionContainerStyle}>
                <TouchableOpacity onPress={() => this.sortField('name')} style={getItemStyle('name')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.name}
                        <Image source={this.state.sortByAscending.name ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.sortField('country')} style={getItemStyle('country')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.country}
                        <Image source={this.state.sortByAscending.country ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.sortField('region')} style={getItemStyle('region')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.region}
                        <Image source={this.state.sortByAscending.region ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => this.sortField('district')} style={getItemStyle('district')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.district}
                        <Image source={this.state.sortByAscending.district ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>
                {isWeb && <TouchableOpacity onPress={() => this.sortField('author')} style={getItemStyle('author')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.createdBy}
                        <Image source={this.state.sortByAscending.author ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>}
                {isWeb && <TouchableOpacity onPress={() => this.sortField('surveyor')} style={getItemStyle('surveyor')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.surveyor}
                        <Image source={this.state.sortByAscending.surveyor ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>}
               {isWeb && <TouchableOpacity onPress={() => this.sortField('mobileGroupSubmitDate')} style={getItemStyle('createdAt')}>
                   <ListItemText style={tabsCaptionItemStyle} >
                       {data.createdAt}
                       <Image source={this.state.sortByAscending.mobileGroupSubmitDate ? sortAsc : sortDesc} style={sortImageStyle} />
                   </ListItemText>
               </TouchableOpacity>}
                <TouchableOpacity onPress={() => this.sortField('status')} style={getItemStyle('status')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.status}
                        <Image source={this.state.sortByAscending.status ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>
                {isWeb &&  <TouchableOpacity onPress={() => this.sortField('historianSubmitted')} style={getItemStyle('historicalInformation')}>
                    <ListItemText style={tabsCaptionItemStyle} >
                        {data.historicalInformation}
                        <Image source={this.state.sortByAscending.historianSubmitted ? sortAsc : sortDesc} style={sortImageStyle} />
                    </ListItemText>
                </TouchableOpacity>}
               {isWeb &&  <TouchableOpacity onPress={() => this.sortField('editorSubmitted')} style={getItemStyle('editorInformation')}>
                   <ListItemText style={tabsCaptionItemStyle} >
                       {data.editorInformation}
                       <Image source={this.state.sortByAscending.editorSubmitted ? sortAsc : sortDesc} style={sortImageStyle} />
                   </ListItemText>
               </TouchableOpacity>}
            </View>
        );
    }
}