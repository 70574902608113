import React, {Component} from 'react';
import {Platform} from 'react-native';
import {Input} from "./Input";
import {ButtonSubmit} from "./ButtonSubmit";
import {InputView} from '../../constants/styles';
import i18n from '../../i18n';

const COORDINATES_DECIMAL_PLACES = 6;
const coordinatesToFixedDecimalPlaces = (coordinates, decimalPlaces=COORDINATES_DECIMAL_PLACES)=> {
    const parsedCoordinates = coordinates.split(',', 2);
    parsedCoordinates[0] = parseFloat(parsedCoordinates[0]).toFixed(decimalPlaces);
    parsedCoordinates[1] = parseFloat(parsedCoordinates[1]).toFixed(decimalPlaces);
    return `${parsedCoordinates[0]},${parsedCoordinates[1]}`;
};

export class CoordinatesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: ''
        };
        this.setLocation = this.setLocation.bind(this);
        this.setError = this.setError.bind(this);
        this.changeText = this.changeText.bind(this);
        this.checkText = this.checkText.bind(this);
    }
    setLocation(coordinates) {
        this.changeText(coordinatesToFixedDecimalPlaces(coordinates));
        this.setError('');
    }
    setError(errorMessage) {
        this.setState({ errorMessage });
    }
    changeText(coordinates) {
        this.props.onChangeText(coordinates);
    }
    checkText() {
        if(this.props.coordinates && this.props.coordinates.match(/^([0-9]+\.?([0-9]+)?,[0-9]+\.?([0-9]+)?)$/)) {
            this.setError('');
        } else {
            this.setError(i18n.t('text.error_decimal'));
        }
    }
    render() {
        if (Platform.OS === 'ios') {
            return (
                <InputView style={this.props.viewStyle}>
                    <Input
                        disabled={this.props.disabled}
                        pointerEvents={this.props.disabled ? 'none' : 'auto'}
                        placeholder={i18n.t('text.coordinates_format')}
                        defaultValue={this.props.coordinates}
                        onChangeText={this.changeText}
                        _inputStyle={this.props.inputStyle}
                        _onBlur={this.checkText}
                        error={this.state.errorMessage}
                    />
                    <ButtonSubmit
                        disabled={this.props.disabled}
                        style={{width: this.props.buttonWidth, marginLeft: 15}}
                        _onPress={()=>this.props.getLocationAsync(this.setLocation, this.setError)}
                        name={i18n.t('buttons.get_coordinates')}/>
                </InputView>
            );
        } else {
            return (
                <InputView>
                    <Input
                        _viewStyle={this.props.viewStyle}
                        _inputStyle={this.props.inputStyle}
                        _onBlur={this.checkText}
                        defaultValue={this.props.coordinates}
                        onKeyPress={this.props.onKeyPress}
                        placeholder={i18n.t('task.coordinates')}
                        onChangeText={this.changeText}
                        error={this.state.errorMessage}
                    />
                </InputView>
            );
        }
    }
}