import { Record } from 'immutable';

export default new Record({
    token: '',
    user: {
        firstName: '',
        id: 0,
        lastName: '',
        login: '',
        role: {
            isCanCreate: false,
            isCanEdit: false,
            name: '',
        }
    },
    selectedCountry: 0,
    isReadonly: null,
});
