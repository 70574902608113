import { Platform } from 'react-native';

const ENTER_KEY_CODE = 'Enter';
const isWeb = Platform.OS === 'web';

export default (onButtonPress)=> {
    if(isWeb) {
        return (event)=> {
            if(event.key === ENTER_KEY_CODE && !event.shiftKey) {
                onButtonPress();
            }
        }
    } else {
        return null;
    }
}