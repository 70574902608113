export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const getCountries = (onAfterSaga) => {
	return ({
		type: GET_ALL_COUNTRIES,
		payload: {
			onAfterSaga,
		},
	});
};

export const GET_STATES = 'GET_STATES';
export const getStatesByCountryId = (id, onAfterSaga) => ({
	type: GET_STATES,
	payload: {
		id,
		onAfterSaga,
	},
});

export const GET_CITIES = 'GET_CITIES';
export const getCitiesByStateId = (id, onAfterSaga) => ({
	type: GET_CITIES,
	payload: {
		id,
		onAfterSaga,
	},
});
