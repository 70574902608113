import React from 'react';
import { Map, Polygon, Marker, GoogleApiWrapper } from 'google-maps-react';
import domtoimage from 'dom-to-image';
import { ButtonSubmit } from '../ButtonSubmit';
import { convertToRadian, getDistance, callErrorAlert } from '../../../helpers/map';
import { googleMapsApiKey } from '../../../config/development';
import { MapButtonContainer, MapContainer, SquareInfoText, SquareInfoTextContainer } from '../../../constants/styles';
import i18n from '../../../i18n';
import { COLORS } from '../../../constants/colors';
import { MAP_PARAMS } from '../../../constants/map';
import { sizes } from '../../../constants/sizes';
import anchor from '../../../assets/images/anchor.png';
import uuid from 'uuid';

let id = 0;
const getLatLng = (point) => (point && point.latitude && point.longitude && { lat: point.latitude, lng: point.longitude });
const getCenterPolygonPoint = (polygonCoordinates) => {
    let minLat = polygonCoordinates[0].latitude;
    let maxLat = minLat;
    let minLng = polygonCoordinates[0].longitude;
    let maxLng = minLng;
    polygonCoordinates.forEach(coord=>{
        if(coord.latitude < minLat) {
            minLat = coord.latitude;
        }
        if(coord.latitude > maxLat) {
            maxLat = coord.latitude;
        }
        if(coord.longitude < minLng) {
            minLng = coord.longitude;
        }
        if(coord.longitude > maxLng) {
            maxLng = coord.longitude;
        }
    });
    return {
        latitude: minLat + (maxLat - minLat) / 2,
        longitude: minLng + (maxLng - minLng) / 2
    };
};

export class PolygonWrapper extends React.Component {
    map = null;
    constructor(props) {
        super(props);
        this.state = {
            region: {
                latitude: MAP_PARAMS.LATITUDE,
                longitude: MAP_PARAMS.LONGITUDE,
            },
            polygons: [],
            isReadOnly: false,
            isPolygonFinished: false,
            isPolygonNew: true,
            editing: null
        };
        this.onClickMap = this.onClickMap.bind(this);
    }

    componentDidMount() {
        const coordinates = this.props.initialCoordinates && this.props.initialCoordinates.split(',', 2);
        const latitude = Number(coordinates[0]) || 0;
        const longitude = Number(coordinates[1]) || 0;
        const polygonCoordinates = this.props.mapData && this.props.mapData.coordinates;
        if(polygonCoordinates && polygonCoordinates.length > 2) {
            this.setState({
                region: getCenterPolygonPoint(polygonCoordinates)
            });
        } else if (coordinates) {
            this.setState({
                region: {
                    latitude,
                    longitude,
                }
            });
        } else {
            this.getCurrentPosition();
        }
        if (polygonCoordinates) {
            this.setState({
                polygons: polygonCoordinates.length > 0 ? [{ id: id++, coordinates: polygonCoordinates }] : [],
                isPolygonFinished: polygonCoordinates.length > 0,
                isPolygonNew: polygonCoordinates.length === 0,
            });
        }
        const isReadOnly = this.props.isReadOnly || this.props.isDraft;
        this.setState({ isReadOnly });
    }

    finish(event=false) {
        event && event.preventDefault();
        const { polygons, editing } = this.state;
        this.setState({
            polygons: [...polygons, editing], // adding edited polygon to the list of existing
            isPolygonFinished: true,
            editing: null
        });
    }

    reset(event=false) {
        event && event.preventDefault();
        this.setState({
            polygons: [],
            isPolygonFinished: false,
            isPolygonNew: true,
            editing: null
        });
    }

    cancel() {
        this.props.closeMap();
    }

    takeSnapshotAndCloseMap() {
        const squareInfo = this.getSquareAndPerimeter();
        const square = squareInfo.s;
        const perimeter = squareInfo.p;
        const mapElement = this.map.refs.map;
        const coordinates = this.state.polygons.length > 0 ? this.state.polygons[0].coordinates : [];
        domtoimage.toSvg(mapElement, {
            height: sizes.WEB.GOOGLE_MAPS_HEIGHT,
            width: sizes.WEB.GOOGLE_MAPS_WIDTH,
            cacheBust: false })
            .then((svg) => {
                const pictureUri = svg.replace('data:image/svg+xml;charset=utf-8,', '');
                const picture = pictureUri.replace('block', 'flex');
                this.props.closeMap({
                    perimeter,
                    square,
                    coordinates,
                    snapshot: { picture, name: uuid.v4() }
                })
            });
    }

    revertLastPolygon(event) {
        event && event.preventDefault();
        const { editing } = this.state;
        if (editing && editing.coordinates) {
            if (editing.coordinates.length > 3) {
                this.setState({
                    editing: {
                        ...editing,
                        coordinates: editing.coordinates.slice(0, editing.coordinates.length - 1),
                    }
                });
            } else if (editing.coordinates.length > 0) {
                this.reset();
            }
        }
    }

    onClickMap(mapProps, map, clickEvent) {
        // only one polygon is allowed
        // so if it was finished, there will be no ability to create a new one
        if (this.state.isPolygonFinished || this.state.isReadOnly) {
            return;
        }
        const { editing } = this.state;
        const centerPoint = clickEvent.latLng && {latitude: clickEvent.latLng.lat(), longitude: clickEvent.latLng.lng()};
        if (!editing) {
            this.setState({
                editing: { // init editing object first time user is trying to draw a polygon
                    id: id++,
                    coordinates: [centerPoint]
                },
                region: {}
            });
        } else {
            this.setState({
                editing: {
                    ...editing,
                    coordinates: [
                        ...editing.coordinates,
                        centerPoint, // add current coordinate to the list of existing
                    ],
                }
            });
        }
    }

    setRegion(region) {
        this.setState({ region });
    }

    getCurrentPosition() {
        try {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.setRegion({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => callErrorAlert(error)
            );
        } catch (e) {
            callErrorAlert(e);
        }
    };

    getSquareAndPerimeter() {
        var area = 0;
        var perimeter = 0;
        var coordinates;
        if (this.state.editing && this.state.editing.coordinates) {
            coordinates = this.state.editing.coordinates;
        } else if (this.state.polygons.length > 0) {
            coordinates = this.state.polygons[0].coordinates;
        } else {
            return 0;
        }

        if (coordinates.length > 1) {
            const points = [...coordinates, coordinates[0]];
            for (let i = 0; i < points.length - 1; i++) {
                const p1 = points[i];
                const p2 = points[i + 1];
                area += convertToRadian(p2.longitude - p1.longitude)
                    * (2 + Math.sin(convertToRadian(p1.latitude)) + Math.sin(convertToRadian(p2.latitude)));
                perimeter += getDistance(p1, p2);
            }
            if (coordinates.length === 2) {
                perimeter /= 2; // avoid calculating perimeter to third point with only two points
            }
            area = Math.round(Math.abs((area * MAP_PARAMS.EARTH_RADIUS * MAP_PARAMS.EARTH_RADIUS) / 2));
            perimeter = Math.round(perimeter);
        }
        return { s: area, p: perimeter };
    }

    getSquareInfoViews() {
        var squareInfo = this.getSquareAndPerimeter();
        var square = squareInfo.s;
        var perimeter = squareInfo.p;

        return (
            <MapButtonContainer
                flexDirection={'column'}
                style={{ position: 'absolute', bottom: 12, left: 4 }}>
                <SquareInfoTextContainer>
                    <SquareInfoText>{`${i18n.t('map.perimeter')} ${perimeter}${i18n.t('map.m')}`}</SquareInfoText>
                </SquareInfoTextContainer>
                <SquareInfoTextContainer>
                    <SquareInfoText>{`${i18n.t('map.area')} ${square}${i18n.t('map.m2')}`}</SquareInfoText>
                </SquareInfoTextContainer>
            </MapButtonContainer>
        )
    }

    render() {
        this.props.location.state = { ...this.props.location.state, cancelLastAction: this.props.closeMap };

        return (
            <MapContainer style={{ height: this.props.height, position: 'absolute', top: 0, zIndex: 1 }}>
                <Map
                    ref={map => { this.map = map }}
                    style={styles.map}
                    google={this.props.google}
                    mapTypeControl={true}
                    streetViewControl={false}
                    fullscreenControl={false}
                    mapType={'satellite'}
                    mapTypeControlOptions={ {
                        mapTypeIds: ['satellite']
                    }}
                    zoom={18}
                    center={getLatLng(this.state.region)}
                    centerAroundCurrentLocation={false}
                    onClick={this.onClickMap}
                >
                    {this.state.polygons.map(polygon => (
                        <Polygon
                            key={polygon.id}
                            paths={polygon.coordinates.map(coord=>({lat: coord.latitude, lng: coord.longitude}))}
                            strokeColor={COLORS.WHITE.WHITE}
                            fillColor="rgba(82,18,36,0.5)"
                            strokeWidth={3}
                        />
                    ))}
                    {this.state.editing && (
                        <Polygon
                            key={this.state.editing.id}
                            paths={this.state.editing.coordinates.map(coord=>({lat: coord.latitude, lng: coord.longitude}))}
                            strokeColor={COLORS.WHITE.WHITE}
                            fillColor="rgba(82,18,36,0.35)"
                            strokeWidth={6}
                        />
                    )}
                    {this.state.editing && (this.state.editing.coordinates.map((position, index) => (
                        <Marker
                            key={index}
                            icon={{
                                url: anchor,
                                anchor: new window.google.maps.Point(15,15),
                                scaledSize: new window.google.maps.Size(30,30)}}
                            position={getLatLng(position)}
                        />
                    )))}
                </Map>
                {(this.state.editing || this.state.polygons.length > 0) && this.getSquareInfoViews()}
                {(this.state.isReadOnly ||
                    <MapButtonContainer
                        style={{ position: 'absolute', top: 10, right: 10 }}>
                        {this.state.editing && (
                            <ButtonSubmit
                                style={{ marginLeft: 10, width: 110 }}
                                _onPress={(event) => this.revertLastPolygon(event)}
                                name={i18n.t('map.step_back')} />
                        )}
                        {(this.state.editing || this.state.polygons.length > 0) && (
                            <ButtonSubmit
                                style={{ marginLeft: 10, width: 110 }}
                                _onPress={(event) => this.reset(event)}
                                name={i18n.t('map.erase_all')} />
                        )}
                    </MapButtonContainer>
                )}
                <MapButtonContainer
                    style={{ position: 'absolute', bottom: 12, right: 72 }}>
                    <ButtonSubmit
                        style={{ marginLeft: 10, width: 90, backgroundColor: COLORS.GRAY.LIGHT2 }}
                        _onPress={() => this.cancel()}
                        name={i18n.t('map.cancel')} />
                    {!this.state.isReadOnly && this.state.editing && this.state.editing.coordinates && (this.state.editing.coordinates.length > 2) && (
                        <ButtonSubmit
                            style={{ marginLeft: 10, width: 90 }}
                            _onPress={(event) => this.finish(event)}
                            name={i18n.t('map.save')} />
                    )}
                    {!this.state.isReadOnly && (!this.state.editing && this.state.polygons.length > 0 && this.state.isPolygonNew) && (
                        <ButtonSubmit
                            style={{ marginLeft: 10, width: 90 }}
                            _onPress={(event) => this.takeSnapshotAndCloseMap(event)}
                            name={i18n.t('map.submit')} />
                    )}
                </MapButtonContainer>
            </MapContainer>
        );
    }
}

const styles ={
    map: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    },
};

export const PolygonCreator = GoogleApiWrapper({
    apiKey: googleMapsApiKey
})(PolygonWrapper);

