import React, { Component } from 'react';
import { Dimensions, Image, Platform, ScrollView, View } from 'react-native';
import { connect } from 'react-redux';
import config from '../../config';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';
import { ButtonContainer, SubHeaderText, taskContainerStyle, taskContainerWithShadow, taskDetailsStyle, Text } from '../../constants/styles';
import { TASK } from '../../constants/task';
import { formatDate } from '../../helpers/formatDate';
import { getFullName } from '../../helpers/getProperty';
import i18n from '../../i18n';
import { ButtonSubmit, TaskDescription, TaskDetails, TaskHeader } from '../common';
import {checkCanEdit} from "../../helpers/checkPermissions";

const isIOS = (Platform.OS === 'ios');
const MENU_SHIFT = isIOS ? (sizes.BOTTOM_MENU_HEIGHT + sizes.BOTTOM_MARGIN) : 0;
const HEADER_SHIFT = isIOS ? 0 : sizes.SCREEN_HEADER_HEIGHT;
const SCREEN_HEIGHT = Dimensions.get('window').height - (sizes.GLOBAL_HEADER_HEIGHT + MENU_SHIFT + HEADER_SHIFT);
const BUTTON_WRAPPER_HEIGHT = 76;
const BUTTON_WRAPPER_BOTTOM = isIOS ? 80 : 0;

const taskViewScreenStyle = {
    marginTop: 20,
    paddingTop: isIOS ? 30 : 10,
    paddingRight: 30,
    paddingLeft: 30,
    width: '100%',
};

const buttonWrapperStyle = {
    display: 'flex',
    marginBottom: BUTTON_WRAPPER_BOTTOM,
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 310,
    height: BUTTON_WRAPPER_HEIGHT,
    width: '100%',
    backgroundColor: COLORS.GRAY.LIGHT3
};

class TaskViewButton extends Component {
    render() {
        return (
            <ButtonContainer style={{ width: 400, marginLeft: 30 }} onPress={this.props.onPress}>
                <Text color={COLORS.WHITE.WHITE} bold={'true'} size={2}>{this.props.text}</Text>
            </ButtonContainer>
        );
    }
}

class TaskView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            isBackHome: false,
            questionnaireButtonName: ''
        };
    }

    componentDidMount() {
        const locationData = this.props.location && this.props.location.state;
        if (!locationData) {
            this.props.history.push(this.props.paths.home);
            return;
        }
        const data = locationData.data;

        const readonly = locationData.readonly;
        if (readonly) {
            data.readonly = true;
        }
        if (locationData.canEdit && !isIOS) {
            data.canEdit = true;
        }
        const isBackHome = !!locationData.isDataUpdated;
        this.setState({ data, isBackHome });
        if (data.task && data.task.status) {
            if (readonly || checkCanEdit(this.props.permissions, readonly)) {
                this.setState({ questionnaireButtonName: i18n.t('buttons.view_questionnaire') });
            } else if(data.task.status.name === TASK.STATUS.NAME.CREATED) {
                this.setState({ questionnaireButtonName: i18n.t('buttons.start_questionnaire') });
            } else {
                this.setState({ questionnaireButtonName: i18n.t('buttons.open_questionnaire') });
            }
        }
    }

    render() {
        if (this.state.data) {
            const data = this.state.data;
            const description = data.task && data.task.description ? data.task.description : '';
            const historicalInformation = data.task && data.task.historicalInformation ? data.task.historicalInformation : '';
            const createdAt = formatDate(data.task.createdAt);
            const createdBy = getFullName(data.task.author);
            const created = data.task && createdAt ? i18n.t('text.created') + ' ' + formatDate(data.task.createdAt) + ' ' + (createdBy ? i18n.t('text.by') + ' ' + createdBy : '') : '';
            const arePicturesAttached = data.pictures && data.pictures.length > 0;
            return (
                <View style={{ height: SCREEN_HEIGHT }}>
                    {data.canEdit && <TaskHeader text={i18n.t('task.add_task_for_object_survey')} />}
                    <ScrollView style={{ height: '100%' }} contentContainerStyle={{ flexGrow: 1 }}>
                        <View style={taskViewScreenStyle}>
                            <View style={taskDetailsStyle}>
                                <TaskDetails data={data} />
                                {!!data.task && <TaskDescription title={i18n.t('text.task')} description={description} created={created} />}
                            </View>
                            {!!historicalInformation &&
                                <View style={{ marginTop: 15 }}>
                                    {!isIOS && <SubHeaderText style={{ marginBottom: 15 }}>{i18n.t('task.historical_information')}</SubHeaderText>}
                                    <View style={{ ...taskContainerWithShadow, ...taskContainerStyle, borderLeftColor: COLORS.GREEN.LIGHT, marginRight: 10, height: arePicturesAttached ? 121 : 200 }}>
                                        {isIOS && <SubHeaderText style={{ marginBottom: 10 }} >{i18n.t('task.historical_information')}</SubHeaderText>}
                                        <ScrollView>
                                            <Text>{historicalInformation}</Text>
                                        </ScrollView>
                                    </View>
                                </View>
                            }
                            {arePicturesAttached &&
                                <View style={{ marginTop: 15, height: 400, width: '100%' }}>
                                    <SubHeaderText style={{ marginBottom: 15 }}>{i18n.t('text.added_pictures')}</SubHeaderText>
                                    <ScrollView style={{ width: '100%' }} horizontal={true}>
                                        {data.pictures.map(picture => {
                                            const imageURI = picture.base64 || `${config.hostname}picture/${picture.id}` + (isIOS ? '?size=middle' : '');
                                            return (
                                                <Image
                                                    key={picture.id}
                                                    source={{ uri: imageURI }}
                                                    style={{ width: isIOS ? 350 : 450, height: isIOS ? 350 : 450, resizeMode: 'contain', marginRight: 20 }} />
                                            );
                                        })}
                                    </ScrollView>
                                </View>
                            }
                            {data.canEdit &&
                                <View style={{
                                    marginBottom: 50,
                                    marginTop: 20,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row'
                                }}>
                                    {!this.state.isBackHome && <ButtonSubmit
                                        style={{ marginRight: 30, width: 100 }}
                                        _onPress={() => this.props.history.push(this.props.paths.edit, { data: this.state.data })}
                                        name={i18n.t('buttons.edit')}
                                    />}
                                    {this.state.isBackHome && <ButtonSubmit
                                        style={{ marginRight: 30, width: 200 }}
                                        _onPress={() => this.props.history.push(this.props.paths.home)}
                                        name={i18n.t('buttons.back_to_objects_list')}
                                    />}
                                </View>
                            }
                        </View>
                    </ScrollView>
                    {!!data.task && !!this.state.questionnaireButtonName &&
                        <View style={buttonWrapperStyle}>
                            <TaskViewButton text={this.state.questionnaireButtonName}
                                onPress={(event) => { event.preventDefault(); this.props.history.push(this.props.paths.questionnaire, this.state.data) }} />
                        </View>
                    }
                </View>);
        } else {
            return <View />;
        }
    }
}

const mapStateToProps = () => state => ({
    permissions: state.auth.user.role
});

export const TaskViewScreen = connect(mapStateToProps)(TaskView);
