export const SYNC_DATA = 'SYNC_DATA';
export const sync = (payload) => ({
    type: SYNC_DATA,
    payload
});

export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';
export const setSyncStatus = (payload) => ({
    type: SET_SYNC_STATUS,
    payload,
})
