import React, { Component } from 'react';
import { Dimensions, Image, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { addTask } from '../../actions/tasks';
import { multiPlatformAlert } from '../../helpers/alert';
import { getFailedLoadedImages } from '../../helpers/updateImages';
import { checkIsReadonly } from '../../helpers/checkPermissions';
import pressButtonOnEnter from "../../helpers/pressButtonOnEnter";
import AddImages from '../../components/common/AddImages';
import Gallery from '../common/Gallery';
import { ButtonSubmit, CoordinatesView, Input, LocationView, PlusButton, TaskHeader } from '../common';
import {
    BaseContainer,
    InputView,
    SeparatedView,
    taskContainerWithShadow,
    Text
} from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { ROLES } from '../../constants/roles';
import { sizes } from '../../constants/sizes';
import crossImage from '../../assets/icons/cross.png';
import i18n from '../../i18n';

const isIOS = Platform.OS === 'ios';
const MENU_SHIFT = isIOS ? (sizes.BOTTOM_MENU_HEIGHT + sizes.BOTTOM_MARGIN) : 0;
const SCREEN_HEIGHT = Dimensions.get('window').height - (sizes.GLOBAL_HEADER_HEIGHT + MENU_SHIFT);
const PLUS_BUTTON_CONTAINER_WIDTH = 200;
const IMAGES_SCROLL_LEFT_MARGIN = 20;
const IMAGES_SCROLL_WIDTH = Dimensions.get('window').width - sizes.WEB.LEFT_MENU_WIDTH - IMAGES_SCROLL_LEFT_MARGIN - PLUS_BUTTON_CONTAINER_WIDTH;
const addImagesContainerStyle = {
    web: {
        paddingLeft: 30,
        paddingRight: 30,
        width: Dimensions.get('window').width
    },
    ios: {
        marginLeft: 30,
        marginTop: 20,
        paddingLeft: 30,
        paddingRight: 30,
        width: Dimensions.get('window').width - 60
    }
};

const alertOKButton = {
    id: 'ok',
    text: i18n.t('ok'),
};

class CreateObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: '',
            selectedRegion: '',
            selectedCity: '',
            objectName: '',
            objectNameError: '',
            coordinates: '',
            coordinatesError: '',
            taskDescription: '',
            taskHistoricalInformation: '',
            taskDescriptionError: '',
            showTaskDescription: isIOS,
            showHistoricalInformation: isIOS,
            images: [],
            viewImage: false,
            isGalleryOpen: false,
        };

        this.saveTask = this.saveTask.bind(this);
        this.cancelCreateTask = this.cancelCreateTask.bind(this);
        this.showButton = this.props.location.state && this.props.location.state.enableCreateButton;
        this.pictures = React.createRef();
        this.onAfterTaskCreation = this.onAfterTaskCreation.bind(this);
    }

    onAfterTaskCreation(response) {
        if (!response || 'error' in response) {
            multiPlatformAlert(
                i18n.t('error'),
                i18n.t('task.object_not_created') + '\n' + i18n.t('task.internet_connection_issue'),
                [alertOKButton]
            );
        } else if (response && 'imagesLoaded' in response) {
            const imagesLoaded = response.imagesLoaded || [];
            const failedLoadedImages = getFailedLoadedImages(imagesLoaded);
            if (failedLoadedImages.length > 0) {
                multiPlatformAlert(
                    i18n.t('error'),
                    i18n.t('task.error_image_load'),
                    [alertOKButton]
                );
            } else if(imagesLoaded.length > 0) {
                multiPlatformAlert(
                    i18n.t('success'),
                    i18n.t('task.success_images_loaded'),
                    [alertOKButton]
                );
            }
            if (this.showButton) {
                this.showButton();
            }
            this.props.history.goBack();
        } else {
            multiPlatformAlert(
                i18n.t('success'),
                i18n.t('task.object_data_created'),
                [alertOKButton]
            );
        }
    }

    cancelCreateTask() {
        multiPlatformAlert(
            i18n.t('warning'),
            i18n.t('task.confirm_cancel'),
            [
                {
                    id: 'ok',
                    text: i18n.t('ok'),
                    onPress: () => {
                        if (this.showButton) {
                            this.showButton();
                        }
                        this.props.history.goBack();
                    }
                },
                {
                    id: 'cancel',
                    text: i18n.t('cancel')
                },
            ]
        );
    }

    saveTask() {
        const data = {
            name: this.state.objectName,
            country: this.state.selectedCountry,
            region: this.state.selectedRegion,
            district: this.state.selectedCity,
            coordinates: this.state.coordinates,
            description: this.state.taskDescription,
            historicalInformation: this.state.taskHistoricalInformation,
            pictures: this.state.images,
        };

        if (data.name && data.country && data.region) {
            this.props.addTask(data, this.onAfterTaskCreation);
        } else {
            multiPlatformAlert(
                i18n.t('error'),
                i18n.t('text.error_create_task'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                    },
                ]
            );
        }
    }

    onChangeFileInputCallback = (images) => {
        this.setState({ images: [...this.state.images, ...images] });
    }

    addPlusContainer(hide, onPress, text, style = {}, opt = {}) {
        const FileInput = this.props.elements.fileInput;

        return (
            <View style={{ ...style, display: hide ? 'none' : 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', height: 40 }}>
                    <Text style={{ width: PLUS_BUTTON_CONTAINER_WIDTH }}>{text}</Text>
                </View>

                <PlusButton _onPress={onPress}>
                    {opt.field === 'pictures' && (
                        <FileInput
                            onChangeCallback={this.onChangeFileInputCallback}
                            width={40}
                            height={40}
                            type="file"
                            data={this.state.images}
                        />
                    )}
                </PlusButton>

            </View>
        );
    }

    addTaskImages = (images) => {
        this.setState({ images });
    };

    render() {
        const isHistorian = this.props.user.role.name === ROLES.NAMES.HISTORIAN;
        const { imageManipulator, imagePicker, permissions } = this.props.elements;
        const readonly = checkIsReadonly(this.props.user.role);

        const picturesPlusStyle = {
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 20,
            marginRight: 10,
            borderStyle: 'solid',
            borderRightWidth: 1,
            borderColor: COLORS.GRAY.LIGHT,
        }

        return (!readonly &&
            <View style={{ height: SCREEN_HEIGHT }}>
                <TaskHeader text={i18n.t('task.add_new_object')} />
                <ScrollView style={{ height: SCREEN_HEIGHT - sizes.SCREEN_HEADER_HEIGHT }} contentContainerStyle={{ flexGrow: 1 }}>
                    <BaseContainer style={{ ...taskContainerWithShadow, marginLeft: 30, marginRight: 30, marginBottom: 10, marginTop: 25, display: !this.state.viewImage ? 'block' : 'none'}}>
                        <View style={{ marginTop: 20, marginBottom: 15, height: 205, justifyContent: 'space-between' }}>
                            <InputView style={{ marginBottom: 10, marginRight: 25, marginLeft: 20 }}>
                                <Input
                                    _viewStyle={{ marginBottom: 0, width: isIOS ? 657 : '100%' }}
                                    _onBlur={() => this.setState({ objectNameError: this.state.objectName ? '' : i18n.t('text.error_object_name') })}
                                    onKeyPress={pressButtonOnEnter(this.saveTask)}
                                    placeholder={i18n.t('task.object_name')}
                                    onChangeText={(val) => this.setState({ objectName: val })}
                                    value={this.state.objectName}
                                    error={this.state.objectNameError}
                                />
                            </InputView>
                            <SeparatedView style={{ marginTop: 15, marginBottom: 15, paddingTop: 15, paddingBottom: 5, paddingRight: 25, paddingLeft: 20 }}>
                                <LocationView
                                    viewStyle={{ marginBottom: 0, width: '100%' }}
                                    initCountry={this.props.selectedCountry || ''}
                                    onChangeCountry={(val) => this.setState({ selectedCountry: val.label })}
                                    countryWidth={isIOS ? 170 : '32%'}
                                    onChangeRegion={(val) => this.setState({ selectedRegion: val.label })}
                                    regionWidth={isIOS ? 170 : '32%'}
                                    onChangeDistrict={(val) => this.setState({ selectedCity: val.label })}
                                    districtWidth={isIOS ? 290 : '32%'}
                                    select={this.props.elements.select}
                                />
                            </SeparatedView>
                            <CoordinatesView
                                onKeyPress={pressButtonOnEnter(this.saveTask)}
                                getLocationAsync={this.props.getLocationAsync}
                                viewStyle={{ marginTop: 0, marginRight: 25, marginLeft: 20, width: isIOS ? 490 : '100%', display: isIOS ? 'flex' : 'block' }}
                                buttonWidth={157}
                                inputStyle={{ width: isIOS ? 490 : '100%' }}
                                coordinates={this.state.coordinates}
                                onChangeText={(coordinates) => this.setState({ coordinates })}
                            />
                        </View>
                    </BaseContainer>
                        <View>
                            <SeparatedView style={{ marginTop: 20, paddingLeft: 30, paddingRight: 30, marginBottom: isHistorian ? 20 : 0, borderBottomWidth: isHistorian ? 1 : 0}}>
                                {!isIOS && (
                                    this.addPlusContainer(
                                        this.state.showTaskDescription,
                                        () => this.setState({ showTaskDescription: true }),
                                        i18n.t('task.add_task')
                                    )
                                )}

                                {this.state.showTaskDescription && (
                                    <Input
                                        _close={!isIOS && (() => this.setState({ showTaskDescription: false, taskDescription: '' }))}
                                        _viewStyle={{ width: '100%', marginBottom: 0 }}
                                        _inputStyle={{ paddingRight: 14 }}
                                        onKeyPress={pressButtonOnEnter(this.saveTask)}
                                        height={120}
                                        multiline={true}
                                        numberOfLines={4}
                                        placeholder={i18n.t('task.add_task')}
                                        onChangeText={(val) => this.setState({ taskDescription: val })}
                                    />
                                )}
                            </SeparatedView>

                            {isHistorian && !isIOS && (
                                this.addPlusContainer(
                                    this.state.showHistoricalInformation,
                                    () => this.setState({ showHistoricalInformation: true }),
                                    i18n.t('task.add_historical_information'),
                                    { marginLeft: 30, marginRight: 30 }
                                )
                            )}

                            {isHistorian && this.state.showHistoricalInformation && (
                                <InputView style={{ marginTop: 0, marginBottom: 0, marginLeft: 30, marginRight: 30 }}>
                                    <Input
                                        _close={!isIOS && (() => this.setState({ showHistoricalInformation: false, taskHistoricalInformation: '' }))}
                                        _viewStyle={{ width: '100%', marginBottom: 0 }}
                                        _inputStyle={{ paddingRight: 14 }}
                                        onKeyPress={pressButtonOnEnter(this.saveTask)}
                                        height={120}
                                        multiline={true}
                                        numberOfLines={4}
                                        placeholder={i18n.t('task.add_historical_information')}
                                        onChangeText={(val) => this.setState({ taskHistoricalInformation: val })}
                                    />
                                </InputView>
                            )}
                        </View>
                    {!isIOS &&
                        <SeparatedView style={addImagesContainerStyle.web}>
                            {this.addPlusContainer(
                                isIOS, null, i18n.t('task.add_pictures'),
                                picturesPlusStyle,
                                { field: 'pictures' },
                            )}
                            <ScrollView style={{ width: IMAGES_SCROLL_WIDTH, marginLeft: IMAGES_SCROLL_LEFT_MARGIN }} horizontal={true}>
                                {this.state.images.map(image => (
                                    <TouchableOpacity
                                        key={image.id}
                                        onPress={() => this.setState({ viewImage: image.id })}
                                        style={{marginRight: 10, position: 'relative' }}>
                                        <Image
                                            style={{ width: 70, height: 70 }}
                                            source={{ uri: image.base64 }}
                                        />
                                        <TouchableOpacity
                                            style={{ position: 'absolute', top: 5, right: 5 }}
                                            onPress={() => this.setState({ images: this.state.images.filter(i => i.id !== image.id) })}
                                        >
                                            <Image
                                                style={{ width: 10, height: 10 }}
                                                source={crossImage}
                                            />
                                        </TouchableOpacity>
                                    </TouchableOpacity>
                                ))}
                            </ScrollView>
                        </SeparatedView>
                    }
                    {isIOS && (
                        <AddImages
                            elements={{ imageManipulator, imagePicker, permissions }}
                            images={this.state.images}
                            update={this.addTaskImages}
                            isUploadAfterAction={true}
                            containerStyle={addImagesContainerStyle.ios}
                            openGallery={()=>this.setState({isGalleryOpen: true})}
                        />
                    )}
                    <View style={{ marginBottom: 50, marginTop: 20, display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                        <ButtonSubmit
                            style={{ marginRight: 30, width: 100, backgroundColor: COLORS.GRAY.LIGHT2 }}
                            buttonColor={COLORS.GRAY.DARK}
                            _onPress={this.cancelCreateTask}
                            name={i18n.t('buttons.cancel')}
                        />
                        <ButtonSubmit
                            disabled={this.props.imagesUploadingStatus}
                            style={{ marginRight: 30, width: 100 }}
                            _onPress={this.saveTask}
                            name={i18n.t('buttons.save')}
                        />
                    </View>
                    {this.state.isGalleryOpen &&
                        <View style={{position: 'absolute', top: 0, width: '100%', backgroundColor: COLORS.WHITE.WHITE}}>
                            <Gallery
                                readonly={true}
                                elements={{ imageManipulator, imagePicker, permissions }}
                                images={this.state.images}
                                location={this.props.location}
                                closeGallery={(items) => {
                                    this.setState({ isGalleryOpen: false });
                                }}
                            />
                        </View>
                    }
                    {!!this.state.viewImage &&
                        <TouchableOpacity
                            onPress={() => this.setState({ viewImage: false })}
                            style={{position: 'absolute', top: 0, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 600, backgroundColor: COLORS.GRAY.LIGHT}}
                        >
                            {this.state.images.map(image => image.id === this.state.viewImage && (
                                <Image
                                    key={image.id}
                                    style={{ marginRight: 20, width: 600, height: 600, resizeMode: 'contain'}}
                                    source={{ uri: image.base64 }}
                                />
                            ))}
                        </TouchableOpacity>
                    }
                </ScrollView>
            </View>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    imagesUploadingStatus: (state.imagesUpload || {}).status,
});

const mapDispatchToProps = dispatch => ({
    addTask: (data, onAfterSaga) => dispatch(addTask(data, onAfterSaga)),
});

export const CreateObjectScreen = connect(mapStateToProps, mapDispatchToProps)(CreateObject);
