export const sortByCriteria = (tasks, sortCriteria, isByAscending, getItemHandler)=> {
    const sortResult = { 'yes': isByAscending ? -1 : 1, 'no': isByAscending ? 1 : -1};
    return [...tasks].sort((a,b)=>{
        const aItem = getItemHandler(a, sortCriteria);
        const bItem = getItemHandler(b, sortCriteria);
        return aItem < bItem ? sortResult.yes : sortResult.no
    });
};

export default (response, isByAscending) => {
    const getItemHandler = (item, sortCriteria)=>(item.questionnaire && item.questionnaire[sortCriteria]) || '';
    return sortByCriteria(response, 'mobileGroupSubmitDate', isByAscending, getItemHandler);
}