export default function _serilize(obj) {
    let str = '';
    for (const key in obj) {
        if (str !== '') {
            str += '&';
        }

        if (obj[key]) {
            str += key + '=' + obj[key]
        } else str += '';
    }

    return str;
}
