import { call, put, select, takeLatest } from 'redux-saga/effects';
import { destroyData, LOGIN, LOGOUT, setAuthInformation } from '../actions/auth';
import { clearQuestionnaireList } from '../actions/questionnaire';
import { getAuth } from '../reducers';
import AuthService from '../services/AuthService';

function* login({ payload }) {
    const { selectedCountry, isReadonly, login, password } = payload.credentials;
    const oldAuth = yield select(getAuth);

    const response = yield call(
        AuthService.login,
        { login, password },
    );

    if (response.token) {
        if (oldAuth.isReadonly) {
            yield put(clearQuestionnaireList());
        }

        yield put(setAuthInformation({ ...response, selectedCountry, isReadonly }));
    }

    if (response.error) {
        console.log(response);
    }

    payload.onAfterSaga(!!response.token);
}

function* logout({ payload }) {
    yield call(AuthService.logout, payload.token);
    yield put(clearQuestionnaireList());
    yield put(destroyData());
}

function* authWatcher() {
    yield takeLatest(LOGIN, login);
    yield takeLatest(LOGOUT, logout);
}

export default authWatcher;
