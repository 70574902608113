import React, { createContext } from 'react';

const QuestionnaireContext = createContext({
    recordVoice: () => { },
    stopRecording: () => { },
    stopPlaying: () => { },
    startPlaying: () => { },
});

const RATE_SCALE = 3.0;

export class QuestionnaireProvider extends React.Component {
    constructor(props) {
        super(props);
        this.recording = null;
        this.sound = null;
        this.isSeeking = false;
        this.shouldPlayAtEndOfSeek = false;
        this._onPlayPausePressed = this._onPlayPausePressed.bind(this);

        this.state = {
            recordVoice: this.recordVoice.bind(this),
            stopRecording: this.stopRecording.bind(this),
            stopPlaying: this.stopPlaying.bind(this),
            startPlaying: this.startPlaying.bind(this),
            isLoading: false,
            isPlaybackAllowed: false,
            muted: false,
            soundPosition: null,
            soundDuration: null,
            recordingDuration: null,
            shouldPlay: true,
            isPlaying: false,
            isRecording: false,
            fontLoaded: false,
            shouldCorrectPitch: true,
            volume: 1.0,
            rate: 1.0,
        };

        this.recordingSettings = JSON.parse(JSON.stringify({
            ...this.props.elements.audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY,
            ios: {
                extension: '.wav',
                audioQuality: this.props.elements.audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MAX,
                sampleRate: 44100,
                numberOfChannels: 2,
                bitRate: 128000,
                linearPCMBitDepth: 16,
                linearPCMIsBigEndian: false,
                linearPCMIsFloat: false,
              }
        }));
    }

    _updateScreenForSoundStatus = status => {
        if (status.isLoaded) {
            if (status.durationMillis === status.positionMillis) {
                this._setSoundPosition(0);
            }

            this.setState({
                soundDuration: status.durationMillis,
                soundPosition: status.positionMillis,
                shouldPlay: true,
                isPlaying: status.isPlaying,
                rate: status.rate,
                muted: status.isMuted,
                volume: status.volume,
                shouldCorrectPitch: status.shouldCorrectPitch,
                isPlaybackAllowed: true,
            });
        } else {
            this.setState({
                soundDuration: null,
                soundPosition: null,
                isPlaybackAllowed: false,
            });
            if (status.error) {
                console.log(`FATAL PLAYER ERROR: ${status.error}`);
            }
        }
    };

    _updateScreenForRecordingStatus = status => {
        if (status.canRecord) {
            this.setState({
                isRecording: status.isRecording,
                recordingDuration: status.durationMillis,
            });
        } else if (status.isDoneRecording) {
            this.setState({
                isRecording: false,
                recordingDuration: status.durationMillis,
            });
            if (!this.state.isLoading) {
                this._stopRecordingAndEnablePlayback();
            }
        }
    };

    async _stopPlaybackAndBeginRecording() {
        const Audio = this.props.elements.audio;
        this.setState({
            isLoading: true,
        });

        if (this.sound !== null) {
            await this.sound.unloadAsync();
            this.sound.setOnPlaybackStatusUpdate(null);
            this.sound = null;
        }

        await Audio.setAudioModeAsync({
            allowsRecordingIOS: true,
            interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
            playsInSilentModeIOS: true,
            shouldDuckAndroid: true,
            interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
            playThroughEarpieceAndroid: false,
        });

        if (this.recording !== null) {
            this.recording.setOnRecordingStatusUpdate(null);
            this.recording = null;
        }

        const recording = new Audio.Recording();
        await recording.prepareToRecordAsync(this.recordingSettings);
        recording.setOnRecordingStatusUpdate(this._updateScreenForRecordingStatus);

        this.recording = recording;
        await this.recording.startAsync(); // Will call this._updateScreenForRecordingStatus to update the screen.
        this.setState({
            isLoading: false,
        });
    }

    async _stopRecordingAndEnablePlayback() {
        const Audio = this.props.elements.audio;
        const FileSystem = this.props.elements.filesystem;

        this.setState({
            isLoading: true,
        });
        try {
            await this.recording.stopAndUnloadAsync();
        } catch (error) {
            // Do nothing -- we are already unloaded.
        }

        const info = await FileSystem.getInfoAsync(this.recording.getURI());
        console.log(`FILE INFO: ${JSON.stringify(info)}`);

        const base64 = await FileSystem.readAsStringAsync(this.recording.getURI(), { encoding: FileSystem.EncodingTypes.Base64 });

        await Audio.setAudioModeAsync({
            allowsRecordingIOS: false,
            interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
            playsInSilentModeIOS: true,
            playsInSilentLockedModeIOS: true,
            shouldDuckAndroid: true,
            interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
            playThroughEarpieceAndroid: false,
        });

        const { sound } = await this.recording.createNewLoadedSoundAsync({
            isLooping: false,
            isMuted: this.state.muted,
            volume: this.state.volume,
            rate: this.state.rate,
            shouldCorrectPitch: this.state.shouldCorrectPitch,
        }, this._updateScreenForSoundStatus);

        this.sound = sound;

        this.setState({
            isLoading: false,
        });

        const splitedUri = info.uri.split('/');
        const name = splitedUri[splitedUri.length - 1];

        return { sound, name, base64, filepath: info.uri };
    }

    _onRecordPressed = () => {
        if (this.state.isRecording) {
            this._stopRecordingAndEnablePlayback();
        } else {
            this._stopPlaybackAndBeginRecording();
        }
    };

    async _onPlayPausePressed() {
        if (this.sound !== null) {
            if (this.state.isPlaying) {
                await this.sound.stopAsync();
            } else {
                this.sound.playAsync();
            }
        }
    };

    _onStopPressed = () => {
        if (this.sound !== null) {
            this.sound.stopAsync();
        }
    };

    _onMutePressed = () => {
        if (this.sound !== null) {
            this.sound.setIsMutedAsync(!this.state.muted);
        }
    };

    _onVolumeSliderValueChange = value => {
        if (this.sound !== null) {
            this.sound.setVolumeAsync(value);
        }
    };

    _trySetRate = async (rate, shouldCorrectPitch) => {
        if (this.sound !== null) {
            try {
                await this.sound.setRateAsync(rate, shouldCorrectPitch);
            } catch (error) {
                // Rate changing could not be performed, possibly because the client's Android API is too old.
            }
        }
    };

    _onRateSliderSlidingComplete = async value => {
        this._trySetRate(value * RATE_SCALE, this.state.shouldCorrectPitch);
    };

    _onPitchCorrectionPressed = async value => {
        this._trySetRate(this.state.rate, !this.state.shouldCorrectPitch);
    };

    _onSeekSliderValueChange = value => {
        if (this.sound !== null && !this.isSeeking) {
            this.isSeeking = true;
            this.shouldPlayAtEndOfSeek = this.state.shouldPlay;
            this.sound.pauseAsync();
        }
    };

    _onSeekSliderSlidingComplete = async value => {
        if (this.sound !== null) {
            this.isSeeking = false;
            const seekPosition = value * this.state.soundDuration;
            if (this.shouldPlayAtEndOfSeek) {
                this.sound.playFromPositionAsync(seekPosition);
            } else {
                this.sound.setPositionAsync(seekPosition);
            }
        }
    };

    _getSeekSliderPosition() {
        if (
            this.sound !== null &&
            this.state.soundPosition !== null &&
            this.state.soundDuration !== null
        ) {
            return this.state.soundPosition / this.state.soundDuration;
        }
        return 0;
    }

    _getMMSSFromMillis(millis) {
        const totalSeconds = millis / 1000;
        const seconds = Math.floor(totalSeconds % 60);
        const minutes = Math.floor(totalSeconds / 60);

        const padWithZero = number => {
            const string = number.toString();
            if (number < 10) {
                return '0' + string;
            }
            return string;
        };
        return padWithZero(minutes) + ':' + padWithZero(seconds);
    }

    _getPlaybackTimestamp() {
        if (
            this.sound !== null &&
            this.state.soundPosition !== null &&
            this.state.soundDuration !== null
        ) {
            return `${this._getMMSSFromMillis(this.state.soundPosition)} / ${this._getMMSSFromMillis(
                this.state.soundDuration
            )}`;
        }
        return '';
    }

    _getRecordingTimestamp() {
        if (this.state.recordingDuration !== null) {
            return `${this._getMMSSFromMillis(this.state.recordingDuration)}`;
        }
        return `${this._getMMSSFromMillis(0)}`;
    }

    async recordVoice() {
        this._stopPlaybackAndBeginRecording();
    }

    async stopRecording() {
        return await this._stopRecordingAndEnablePlayback();
    }

    async startPlaying(filepath) {
        if (!filepath) return;

        const { sound: soundObject } = await this.props.elements.audio.Sound.createAsync(
            { uri: filepath },
            { shouldPlay: true },
            this._updateScreenForSoundStatus,
        );

        this.sound = soundObject;

        this.sound.playAsync();
    }

    async stopPlaying() {
        if (!this.sound) return;
        this.sound.stopAsync();
    }

    _setSoundPosition = (seekPosition) => {
        if (this.sound && this.sound.setPositionAsync) {
            this.sound.setPositionAsync(seekPosition)
        }
    }

    render() {
        return (
            <QuestionnaireContext.Provider value={this.state}>
                {this.props.children}
            </QuestionnaireContext.Provider>
        );
    }
}

export const QuestionnaireConsumer = QuestionnaireContext.Consumer;
