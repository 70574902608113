import React, { Component } from 'react';
import { View } from 'react-native';
import AddImages from '../AddImages';
import { ButtonSubmit } from '../ButtonSubmit';
import AddInterview from '../AddInterview';
import { DraftItem } from './DraftItemsContainer';
import { multiPlatformAlert } from '../../../helpers/alert';
import { attachComponentField } from '../../../helpers/attachComponentField';
import {
    BaseContainer,
    DraftLabelText,
    Text
} from '../../../constants/styles';
import { YES_OR_NO } from '../../../constants/radiobuttons';
import {COLORS} from "../../../constants/colors";
import i18n from '../../../i18n';

const SELECTS = {
    urgency_of_erecting_fence: i18n.t('SELECTS.urgency_of_erecting_fence').map(attachComponentField),
    ownership: i18n.t('SELECTS.ownership').map(attachComponentField),
};

const customAlert = (errorMessage)=> {
    multiPlatformAlert(
        i18n.t('error'),
        errorMessage,
        [
            {
                id: 'ok',
                text: i18n.t('ok')
            }
        ]
    );
};

export default class DraftTabDetails extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.openGallery = this.openGallery.bind(this);
    }

    validate() {
        const { questionnaire, task } = this.props;
        let isValid = false;
        const location = questionnaire.location || {};
        const isCoordinates = (location.coordinates || task.coordinates || '').trim() !== '';
        if(!isCoordinates) {
            customAlert(i18n.t('text.error_coordinates'));
        }
        const isCemeteryHasNoAddress = location.address && location.address.cemeteryAddressId && location.address.cemeteryAddressId === 3;
        const isLocationDescription = ((location.address && location.address.description) || '').trim() !== '';
        const isLocationDescriptionValid = isCemeteryHasNoAddress ? isLocationDescription : true;
        if(!isLocationDescriptionValid) {
            customAlert(i18n.t('text.error_location_description'));
        }
        if(isCoordinates && isLocationDescriptionValid) {
            isValid = true;
        }
        return isValid;
    }

    onSubmit() {
        const isValid = this.validate();

        if (isValid) {
            multiPlatformAlert(
                i18n.t('warning'),
                i18n.t('task.confirm_submit'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                        onPress: () => {
                            this.props.submitQuestionnaire(this.props.questionnaire);
                        }
                    },
                    {
                        id: 'cancel',
                        text: i18n.t('cancel')
                    },
                ]
            );
        }
    }

    openGallery(data) {
        this.props.openGallery(data, { tabId: 3 });
    }

    render() {
        const questionnaire = this.props.questionnaire || {};
        const details = questionnaire.details;
        const { imageManipulator, imagePicker, permissions } = this.props.elements;
        if(details) {
            const urgencyOfErectingId = details.erectingFence && details.erectingFence.urgencyOfErectingId;
            const fenceUrgencyType = urgencyOfErectingId && SELECTS.urgency_of_erecting_fence[urgencyOfErectingId - 1];
            const ownershipId = details.ownership && details.ownership.ownershipId;
            const ownershipType = SELECTS.ownership[ownershipId - 1];
            const gravestones = details.gravestones || {};
            const vandalism = details.vandalism || {};
            const ownership = details.ownership || {};
            const droneSurvey = details.droneSurvey || {};

            return (
                <View style={{height: 'auto', marginBottom: 50}}>
                    <BaseContainer>
                        <View style={{flexDirection: 'row'}}>
                            <DraftItem
                                width={330}
                                label={i18n.t('text.gravestones')}
                                data={YES_OR_NO[gravestones.isGravestones ? 1 : 0].label}
                                noSemicolon={true}
                            />
                            <DraftItem
                                width={220}
                                label={i18n.t('text.number_of_gravestones')}
                                data={gravestones.isGravestones ? gravestones.numberOfGravestones : '-'}
                            />
                        </View>
                        <DraftItem
                            label={i18n.t('text.comments_and_additional_info')}
                            data={gravestones.comment}
                            flexDirection={'column'}
                            leftShift={30}
                        />
                        <DraftItem
                            width={500}
                            label={i18n.t('text.date_of_the_earliest_gravestone')}
                            data={gravestones.dateOfEarliest}
                        />
                        <DraftItem
                            width={520}
                            label={i18n.t('text.date_of_the_last_gravestone')}
                            data={gravestones.dateOfLast}
                        />
                        <DraftLabelText style={{marginTop: 20}}>
                            {i18n.t('text.photos') + ': '}
                        </DraftLabelText>
                        {gravestones.pictures && gravestones.pictures.length > 0 &&
                        <AddImages
                            disabled={true}
                            flileinput={this.props.elements.fileInput}
                            elements={{imageManipulator, imagePicker, permissions}}
                            questionnaireId={questionnaire.cemeteryId}
                            openGallery={this.openGallery}
                            images={gravestones.pictures}
                            hideButton={true}
                        />
                        }
                        {gravestones.pictures && gravestones.pictures.length < 1 && <Text>{i18n.t('text.no_photos')}</Text>}
                        <DraftItem
                            width={500}
                            label={i18n.t('text.urgency_of_erecting_fence')}
                            data={(fenceUrgencyType || {}).label}
                        />
                        <DraftItem
                            label={i18n.t('text.additional_comment')}
                            data={details.erectingFence && details.erectingFence.comment}
                            flexDirection={'column'}
                            leftShift={30}
                        />
                        <DraftItem
                            width={500}
                            label={i18n.t('text.any_vandalism')}
                            data={YES_OR_NO[vandalism.isVandalism ? 1 : 0].label}
                            noSemicolon={true}
                        />
                        {vandalism.isVandalism &&
                        <DraftItem
                            label={i18n.t('text.additional_comment')}
                            data={vandalism.isVandalism ? vandalism.comment : '-'}
                            flexDirection={'column'}
                            leftShift={30}
                        />}
                        <DraftLabelText style={{marginTop: 20}}>
                            {i18n.t('text.photos') + ': '}
                        </DraftLabelText>
                        {vandalism.pictures && vandalism.pictures.length > 0 &&
                        <AddImages
                            disabled={true}
                            flileinput={this.props.elements.fileInput}
                            elements={{imageManipulator, imagePicker, permissions}}
                            questionnaireId={questionnaire.cemeteryId}
                            updateSettings={{rootField: 'vandalism', valueField: 'pictures', sectionName: 'details'}}
                            openGallery={this.openGallery}
                            images={vandalism.pictures}
                            hideButton={true}
                        />
                        }
                        {vandalism.pictures && vandalism.pictures.length < 1 && <Text>{i18n.t('text.no_photos')}</Text>}
                        <DraftItem
                            width={500}
                            label={i18n.t('text.ownership')}
                            data={(ownershipType || {}).label}
                        />
                        <DraftItem
                            label={i18n.t('text.additional_comment')}
                            data={ownership.comment}
                            flexDirection={'column'}
                            leftShift={30}
                        />
                        <DraftLabelText style={{marginTop: 20}}>
                            {i18n.t('text.photos') + ': '}
                        </DraftLabelText>
                        {ownership.pictures && ownership.pictures.length > 0 &&
                        <AddImages
                            disabled={true}
                            flileinput={this.props.elements.fileInput}
                            elements={{imageManipulator, imagePicker, permissions}}
                            questionnaireId={questionnaire.cemeteryId}
                            openGallery={this.openGallery}
                            images={ownership.pictures}
                            hideButton={true} />
                        }
                        {ownership.pictures && ownership.pictures.length < 1 && <Text>{i18n.t('text.no_photos')}</Text>}
                        <DraftItem
                            label={i18n.t('text.any_structures')}
                            data={details.comment}
                            flexDirection={'column'}
                            noSemicolon={true}
                        />
                        <DraftItem
                            label={i18n.t('text.any_mass_graves')}
                            data={details.anyMassGravesComment}
                            flexDirection={'column'}
                            noSemicolon={true}
                        />
                        <DraftItem
                            label={i18n.t('text.any_specific_threats')}
                            data={details.anySpecificThreatsComment}
                            flexDirection={'column'}
                            noSemicolon={true}
                        />
                        <DraftItem
                            label={i18n.t('text.description_historical_things')}
                            data={details.description}
                            flexDirection={'column'}
                        />
                        <DraftLabelText style={{marginTop: 20}}>
                            {i18n.t('text.photos') + ': '}
                        </DraftLabelText>
                        {details.pictures && details.pictures.length > 0 &&
                        <AddImages
                            disabled={true}
                            flileinput={this.props.elements.fileInput}
                            openGallery={this.openGallery}
                            hideButton={true}
                            elements={{imageManipulator, imagePicker, permissions}}
                            questionnaireId={questionnaire.cemeteryId}
                            images={details.pictures}
                            containerStyle={{borderColor: 'transparent'}} />
                        }
                        {details.pictures && details.pictures.length < 1 && <Text>{i18n.t('text.no_photos')}</Text>}
                        <View style={{width: '100%', flexDirection: 'row', marginTop: 20}}>
                            <DraftLabelText>
                                {i18n.t('text.interview') + ': '}
                            </DraftLabelText>
                            {details.interview && details.interview.length > 0  &&
                            <AddInterview
                                disabled={true}
                                fileInput={this.props.elements.fileInput}
                                questionnaireId={questionnaire.cemeteryId}
                                data={questionnaire.details.interview}
                                elements={this.props.elements}
                            />}
                            {details.interview && details.interview.length < 1  && <Text>{'No interview'}</Text>}
                        </View>
                        <DraftItem
                            width={620}
                            label={i18n.t('text.contact_person_without_comment')}
                            data={details.contact}
                        />
                        <DraftItem
                            label={i18n.t('text.drone_survey')}
                            data={YES_OR_NO[droneSurvey.isDroneSurvey ? 1 : 0].label}
                        />
                        {!droneSurvey.isDroneSurvey &&
                        <DraftItem
                            label={i18n.t('text.drone_survey_description')}
                            data={droneSurvey.comment}
                            flexDirection={'column'}
                            leftShift={30}
                        />}
                        {this.props.submitQuestionnaire &&
                        <View style={{ marginBottom: 50, marginTop: 20, width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {this.props.cancelSubmit &&
                                <ButtonSubmit
                                    style={{marginRight: 30, width: 140, backgroundColor: COLORS.GRAY.LIGHT2}}
                                    buttonColor={COLORS.GRAY.DARK}
                                    _onPress={this.props.cancelSubmit}
                                    name={i18n.t('buttons.cancel')}
                                />
                            }
                            <ButtonSubmit
                                style={{ marginRight: 30, width: 'auto', paddingRight: 15, paddingLeft: 15 }}
                                _onPress={this.onSubmit}
                                name={this.props.submitButtonText}
                            />
                        </View>
                        }
                    </BaseContainer>
                </View>
            );
        }

    }
}
