import React, { Component } from "react";
import i18n from '../../i18n';
import { Platform, Picker } from 'react-native';
import {ButtonContainer, ButtonText, ItemContainer, ItemVerticalContainer, ListItemText } from '../../constants/styles';
import { Input } from './Input';

// const isIOS = (platform === 'ios');


const isWeb = Platform.OS === 'web';

const ROLES = ['HISTORIAN', 'MOBILE_GROUP', 'HISTORIAN_READONLY', 'EDITOR', 'ADMIN'];

const inputCss = {
    width: '15%',
    paddingRight: 20
}

const passCss = {
    width: '20%',
    paddingRight: 20
}

const btnCss = {
    width: '18%',
    paddingRight: 20
}

export default class UserRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleId: 1,
            selectedValue: '',
            login: '',
            firstName: '',
            lastName: '',
            password: ''
        }
        this.onAddUser = this.onAddUser.bind(this);
        this.cleanValues = this.cleanValues.bind(this);
    }
    setNewRoleId(val) {
        const roleId = ROLES.findIndex(role => role === val);
        this.setState({ selectedValue: val, roleId: roleId + 1 });
    }
    updateLogin(val) {
        this.setState({ login: val });
    }
    updateFirstName(val) {
        this.setState({ firstName: val });
    }
    updateLastName(val) {
        this.setState({ lastName: val });
    }
    updatePassword(val) {
        this.setState({ password: val });
    }
    cleanValues() {
        this.setState({ roleId: 1,
            selectedValue: '',
            login: '',
            firstName: '',
            lastName: '',
            password: ''});
    }
    onAddUser() {
        const { firstName, lastName, login, roleId, password } = this.state;
        const newUser = {
            firstName,
            lastName,
            login,
            roleId,
            password
        };
        this.cleanValues();
        return this.props.onAddUser(newUser);
    }
    render() {
        const initValue = ROLES[this.state.roleId - 1];
        const otherRoles = ROLES.filter(role => role !== initValue);
        const arr = [initValue, ...otherRoles];
        const filterInputWidth = isWeb ? '100%' : 145;
        return (
            <ItemVerticalContainer style={{marginLeft: 20, marginRight: 20}}>
                <ItemContainer>
                    <ListItemText style={inputCss}>
                        <Input
                            placeholder={i18n.t('text.login')}
                            onChangeText={value => this.updateLogin(value)}
                            value={this.state.login}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={inputCss}>
                        <Input
                            placeholder={i18n.t('text.userName')}
                            onChangeText={value => this.updateFirstName(value)}
                            value={this.state.firstName}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={inputCss}>
                    <Input
                            placeholder={i18n.t('text.lastName')}
                            onChangeText={value => this.updateLastName(value)}
                            value={this.state.lastName}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={inputCss}>
                        <Picker
                            selectedValue={this.state.selectedValue}
                            style={{ height: 40, width: 150 }}
                            onValueChange={(itemValue, itemIndex) => this.setNewRoleId(itemValue)}
                        >
                            {arr.map((role, i) => <Picker.Item label={role} value={role} key={i}/>)}
                        </Picker>
                    </ListItemText>
                    <ListItemText style={passCss}>
                        <Input
                            placeholder={i18n.t('text.password')}
                            onChangeText={value => this.updatePassword(value)}
                            value={this.state.password}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={btnCss}>
                        <ButtonContainer 
                            onPress={this.onAddUser} 
                            disabled={false}
                            style={{ width: '100%', marginRight: '10px', alignSelf: 'center', height: 40 }}
                        >
                            <ButtonText style={{ color: '#fff' }}>
                                Create User
                            </ButtonText>
                        </ButtonContainer>
                    </ListItemText>
                </ItemContainer>
            </ItemVerticalContainer>
        );
    }
}