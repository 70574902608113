import _serilize from '../helpers/_serilize';
import http from './http';

const ENDPOINT = {
    UPLOAD_IMAGE: (id, serializedParams) => `questionnaire/${id}/upload?${serializedParams}`,
    DELETE_IMAGE: (id) => `picture/${id}`,

    UPLOAD_AUDIO: (id, serializedParams) => `questionnaire/${id}/audio/upload?${serializedParams}`,
    DELETE_AUDIO: (id) => `audio/${id}`,
    UPLOAD_CEMETERY_IMAGE: (id) => `cemetery/${id}/picture/upload`
};

export default class ContentService {
    static async uploadImage(data, token, isSync) {
        const response = await http.post(ENDPOINT.UPLOAD_IMAGE(data.questionnaireId, _serilize(data.params)), { name: data.preId || data.id, picture: data.base64 }, token);
        return isSync ? { response, data } : response;
    }

    static async uploadCemeteryImages(images, id, token) {
        return await Promise.all(images.map(data => http.post(ENDPOINT.UPLOAD_CEMETERY_IMAGE(id), { name: data.preId || data.id, picture: data.base64 }, token)));
    }

    static async deleteImage(data, token) {
        if (typeof data === 'number') {
            return await http.delete(ENDPOINT.DELETE_IMAGE(data), token);
        } else {
            return await Promise.all(
                data.map(async item => ({ result: await http.delete(ENDPOINT.DELETE_IMAGE(item.id), token), item })),
            );
        }
    }

    static async uploadAudio(data, token, isSync) {
        const response = await http.post(ENDPOINT.UPLOAD_AUDIO(data.questionnaireId, _serilize(data.params)), {
            name: data.name,
            audio: data.base64,
            filepath: data.filepath,
        }, token);

        return isSync ? { response, data } : response;
    }

    static async deleteAudio(data, token) {
        if (data || data.length) {
            if (typeof data === 'number') {
                console.log('\t --> deleting..');
                return await http.delete(ENDPOINT.DELETE_AUDIO(data), token);
            } else {
                return await Promise.all(
                    data.map(async item => ({ result: await http.delete(ENDPOINT.DELETE_AUDIO(item.questionnaireId), token), item })),
                );
            }
        } else {
            return { status: 'OK' };
        }
    }
}
