import isEqual from 'lodash.isequal';
import React, { Component } from 'react';
import { Dimensions, Platform, ScrollView, View } from 'react-native';
import { connect } from 'react-redux';
import { createQuestionnaire, submitQuestionnaire, updateQuestionnaire } from '../../actions/questionnaire';
import { multiPlatformAlert } from '../../helpers/alert';
import updateQuestionnaireHelper from '../../helpers/updateQuestionnaireHelper';
import { getFullName } from '../../helpers/getProperty';
import { setStatus, setStatusReady, getNamedStatusElement } from '../../helpers/getStatusElement';
import { QuestionnaireProvider } from '../contexts/QuestionnaireContext';
import { TaskHeader } from '../common';
import Gallery from '../common/Gallery';
import Tabs from '../common/Tabs';
import { TaskPreview } from '../common/TaskPreview';
import TabDetails from '../common/tab-items/TabDetails';
import TabGeneralCondition from '../common/tab-items/TabGeneralCondition';
import TabLocationAndPerimeter from '../common/tab-items/TabLocationAndPerimeter';
import DraftTabLocationAndPerimeter from '../common/draft-tab-items/TabLocationAndPerimeter';
import DraftTabGeneralCondition from '../common/draft-tab-items/TabGeneralCondition';
import DraftTabDetails from '../common/draft-tab-items/TabDetails';
import {
    BaseContainer,
    Text
} from '../../constants/styles';
import { tabs } from '../../constants/tabs';
import { TASK } from '../../constants/task';
import { ROLES } from '../../constants/roles';
import { sizes } from '../../constants/sizes';
import { COLORS } from '../../constants/colors';
import i18n from '../../i18n';
import {MapPolygonView} from '../../components/common/web/MapEditor';
import MapService from '../../services/MapService';

const isIOs = (Platform.OS === 'ios');
const TABS_AMOUNT = 3;
const MENU_SHIFT = isIOs ? (sizes.BOTTOM_MENU_HEIGHT + sizes.BOTTOM_MARGIN) : 0;
const HEADER_SHIFT = isIOs ? 0 : (sizes.SCREEN_HEADER_HEIGHT + sizes.BOTTOM_MARGIN);
const QUESTIONNAIRE_NON_CONTENT_HEIGHT = sizes.GLOBAL_HEADER_HEIGHT + sizes.QUESTIONNAIRE_HEADER_HEIGHT + MENU_SHIFT + HEADER_SHIFT;
const QUESTIONNAIRE_CONTENT_HEIGHT = Dimensions.get('window').height - QUESTIONNAIRE_NON_CONTENT_HEIGHT;
const TAB_CONTENT_HEIGHT = QUESTIONNAIRE_CONTENT_HEIGHT - TABS_AMOUNT * sizes.TAB_HEADER_HEIGHT;
const MAP_HEIGHT = Dimensions.get('window').height - sizes.GLOBAL_HEADER_HEIGHT - sizes.BOTTOM_MENU_HEIGHT;

class Questionnaire extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDraft: {
                tabLocation: false,
                tabGeneral: false,
                tabDetails: false,
            },
            isMapOpened: false,
            isDraftMapOpened: false,
            objectPerimeter: 0,
            objectSquare: 0,
            polygonCoordinates: [],
            mapImageUri: null,
            isGalleryOpen: false,
            galleryItems: [],
            galleryUpdateSettings: {},
            activeItemId: 1,
            wasClicked: [],
            submitted: {
                mobileGroup: false,
                historian: false,
                editor: false
            },
            readonly: true
        };

        this.data = [
            { id: 1, title: i18n.t('TABS.TAB1'), content: (props) => {
                if(this.state.isDraft[tabs[0]])
                {
                    return <DraftTabLocationAndPerimeter {...props} />
                } else {
                    return <TabLocationAndPerimeter syncMap={this.syncMap} getLocationAsync={this.props.getLocationAsync} {...props} />
                }
            } },
            { id: 2, title: i18n.t('TABS.TAB2'), content: (props) => {
                if(this.state.isDraft[tabs[1]]) {
                    return <DraftTabGeneralCondition {...props} />
                } else {
                    return <TabGeneralCondition {...props} />
                }
            } },
            { id: 3, title: i18n.t('TABS.TAB3'), content: (props) => {
                if(this.state.isDraft[tabs[2]]) {
                    return (
                        <DraftTabDetails
                            submitQuestionnaire={this.getButtonHandler(this._onSubmit)}
                            cancelSubmit={this.getButtonHandler(this.state.submitted.mobileGroup ? this.goHome : () => this.setAllDraft(false))}
                            submitButtonText={this.getSubmittedButtonName(this.state.submitted)}
                            {...{ task: this.props.location.state, ...props}}
                        />
                    );
                } else {
                    return <TabDetails saveQuestionnaire={this.getButtonHandler(this._onSave)} {...props} />
                }
             } },
        ];

        this.updateQuestionnaireHelper = updateQuestionnaireHelper.bind(this);
        this.updateQuestionnaire = this.updateQuestionnaire.bind(this);
        this._onSave = this._onSave.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this.syncMap = this.syncMap.bind(this);
        this.openMap = this.openMap.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.toggleActiveTab = this.toggleActiveTab.bind(this);
        this.toggleDraft = this.toggleDraft.bind(this);
        this.setAllDraft = this.setAllDraft.bind(this);
        this.getButtonHandler = this.getButtonHandler.bind(this);
        this.goHome = this.goHome.bind(this);
    }

    toggleDraft(tabId) {
        const tabName = tabs[tabId-1];
        const isDraft = {...this.state.isDraft};
        isDraft[tabName] = !isDraft[tabName];
        this.setState({isDraft})
    }

    setAllDraft(newState = true) {
        const isDraft = {...this.state.isDraft};
        tabs.forEach(tab=>isDraft[tab]=newState);
        this.setState({isDraft});
    }

    toggleActiveTab(activeItemId) {
        if (this.state.activeItemId !== activeItemId && true) {
            this.setState({ activeItemId, wasClicked: [...this.state.wasClicked, this.state.activeItemId] });
        }
    }

    getButtonHandler(buttonHandler, additionalCondition = false) {
        if(this.state.readonly || additionalCondition) {
            return null;
        } else {
            return buttonHandler;
        }
    }

    goHome() {
        this.props.history.push(this.props.paths.home);
    }

    _onSubmit(data) {
        const onAfterSaga = (isSubmitted) => {
            const msg = i18n.t('task.internet_connection_issue');
            if (isSubmitted) {
                this.goHome();
            } else {
                multiPlatformAlert(
                    i18n.t('warning'),
                    msg,
                    [
                        {
                            id: 'ok',
                            text: i18n.t('ok'),
                        },
                    ]
                );
            }
        };
        this.props.submitQuestionnaire(data, onAfterSaga);
    }

    _onSave(data) {
        this.setAllDraft();
    }

    updateQuestionnaire(...args) {
        if(!this.state.readonly) {
            this.updateQuestionnaireHelper(...args);
        }
    }

    openMap(isDraft = false) {
        if (isDraft) {
            this.setState({ isDraftMapOpened: true });
        } else {
            this.setState({ isMapOpened: true });
        }
    }

    openGallery(data, options) {
        this.setState({
            isGalleryOpen: true,
            galleryItems: data,
            galleryUpdateSettings: options.updateData,
            activeItemId: options.tabId,
        });
    }

    syncMap(data) {
        const map = this.questionnaire.location.map;
        data.comment = (map && map.comment) || '';
        this.updateQuestionnaire(data, { rootField: 'map', sectionName: 'location' });
    }

    syncGallery(items, options) {
        this.updateQuestionnaire(items, options);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(this.state, nextState);
    }

    renderQuestionnaireTabs(props, isDraft) {
        const ViewElement = isIOs ? ScrollView : View;
        if(isDraft) {
            return (
                <View style={{ height: isIOs ? QUESTIONNAIRE_CONTENT_HEIGHT : 'auto' }}>
                    <ViewElement contentContainerStyle={{ flexGrow: 1, height: isIOs ? 'auto' : QUESTIONNAIRE_CONTENT_HEIGHT }} >
                        <Tabs {...props} />
                    </ViewElement>
                </View>
            );
        } else {
            return <Tabs {...props} />;
        }
    }

    getSubmittedButtonName(submitted) {
        if(submitted.editor) {
            return '';
        } else if(submitted.historian) {
            return i18n.t('buttons.ready_for_publish');
        } else if(submitted.mobileGroup) {
            return i18n.t('buttons.ready_for_editor');
        } else {
            return i18n.t('buttons.submit');
        }
    }

    checkCanEdit(roleName, submitted) {
        switch (roleName) {
            case ROLES.NAMES.MOBILE_GROUP:
                return !submitted.mobileGroup;

            case ROLES.NAMES.HISTORIAN:
                return submitted.mobileGroup && !submitted.historian;

            default:
                return submitted.mobileGroup && submitted.historian && !submitted.editor;
        }
    }

    componentDidMount() {
        const { questionnaires } = this.props;
        const data = this.props.location.state;
        if (!data) {
            this.props.history.push(this.props.paths.home);
            return;
        }

        const submitted = {
            mobileGroup: data.task && data.task.status.name === TASK.STATUS.NAME.SUBMITTED,
            historian: data.questionnaire && data.questionnaire.historian,
            editor: data.questionnaire && data.questionnaire.editor
        };
        this.setState({submitted});
        const readonly = data.readonly || !this.checkCanEdit(this.props.role.name, submitted);
        this.setState({readonly});
        this.questionnaire = questionnaires.find(i => i.cemeteryId === data.id);
        if (!this.questionnaire) {
            const { country, region, district } = data;
            const address = {};
            this.props.createQuestionnaire({ cemeteryId: data.id, location: { country, region, district, address } });
        }
        if(readonly || submitted.mobileGroup) {
            this.setAllDraft();
        }
    }

    getCoordinates(questionnaire, defaultCoordinates) {
        const location = questionnaire.location || {};
        return ((location.map && location.map.coordinates && `${location.map.coordinates[0].latitude},${location.map.coordinates[0].longitude}`) || location.coordinates) || defaultCoordinates;
    }
    async updateSnapshot(mapData) {
        try {
            console.log(mapData);
            await MapService.updateSnapshot(
                this.questionnaire.location.pictureMap.pictureId,
                mapData.snapshot.picture
            );
            await MapService.updateMap(
                this.questionnaire.location.id, 
                mapData,
                'token'
            );
            this.setState({ isDraftMapOpened: false });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const data = this.props.location.state;
        const PolygonCreator = this.props.elements.polygon;
        const { imageManipulator, imagePicker, permissions, audio, filesystem } = this.props.elements;
        this.questionnaire = data && this.props.questionnaires.find(i => i.cemeteryId === data.id);
        const ViewElement = isIOs ? View : ScrollView;

        if (this.questionnaire) {
            const galleryProps = {
                elements: { imageManipulator, imagePicker, permissions },
                images: this.state.galleryItems,
                updateSettings: this.state.galleryUpdateSettings,
                location: this.props.location,
                questionnaireId: this.questionnaire.cemeteryId,
                update: this.updateQuestionnaire,
                readonly: this.state.readonly,
                draft: this.state.isDraft,
                activeTabName: tabs[this.state.activeItemId - 1],
                closeGallery: (items, options) => {
                    if (items && options) {
                        this.syncGallery(items, options);
                    }
                    this.setState({ isGalleryOpen: false });
                    this.forceUpdate();
                },
            };

            return (
                <QuestionnaireProvider elements={{permissions, audio, filesystem}} >
                    <View>
                        {!isIOs && <TaskHeader text={i18n.t('text.questionnaire')}/> }
                        <ViewElement style={{ height: isIOs ? 'auto' : (Dimensions.get('window').height - HEADER_SHIFT - sizes.GLOBAL_HEADER_HEIGHT) }}>
                            <BaseContainer style={{ height: sizes.QUESTIONNAIRE_HEADER_HEIGHT, position: 'relative', zIndex: 1 }}>
                                {!isIOs &&
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 80 }}>
                                        <Text>
                                            <span style={{color: COLORS.PURPLE.DARK}}>{i18n.t('task.surveyor') + ': '}</span>
                                            {getFullName(data.questionnaire && data.questionnaire.author)}
                                        </Text>
                                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                            {getNamedStatusElement(setStatus(data, 0), i18n.t('task.status'))}
                                            {getNamedStatusElement(setStatusReady(data.questionnaire, 'historian', 0), i18n.t('task.historical_researcher_2'))}
                                            {getNamedStatusElement(setStatusReady(data.questionnaire, 'editor', 0), i18n.t('task.editor'))}
                                        </View>
                                    </View>
                                }
                                <TaskPreview data={data} />
                                {(isIOs && !this.state.isMapOpened && !this.state.isGalleryOpen) && <Text size={4} style={{ position: 'relative', top: sizes.QUESTIONNAIRE_HEADER_HEIGHT - 35 }}>{i18n.t('text.questionnaire')}</Text>}
                            </BaseContainer>
                            {(!this.state.isMapOpened && !this.state.isGalleryOpen) &&
                                this.renderQuestionnaireTabs(
                                    {
                                        questionnaire: this.questionnaire,
                                        readonly: this.state.readonly,
                                        draft: this.state.isDraft,
                                        task: data,
                                        openGallery: this.openGallery,
                                        update: this.updateQuestionnaire,
                                        activeItemId: this.state.activeItemId,
                                        data: this.data,
                                        disabled: data.task.status.name === TASK.STATUS.NAME.SUBMITTED,
                                        elements: this.props.elements,
                                        toggleActiveTab: this.toggleActiveTab,
                                        toggleDraft: this.toggleDraft,
                                        wasClicked: this.state.wasClicked,
                                        height: TAB_CONTENT_HEIGHT,
                                        openMap: this.openMap
                                    },
                                    this.state.isDraft.tabLocation || this.state.isDraft.tabGeneral || this.state.isDraft.tabDetails
                                )}
                            {this.state.isMapOpened &&
                                <PolygonCreator
                                    radioGroup={this.props.elements.radio}
                                    location={this.props.location}
                                    height={MAP_HEIGHT}
                                    mapData={this.questionnaire.location && this.questionnaire.location.map}
                                    initialCoordinates={this.getCoordinates(this.questionnaire, data && data.coordinates)}
                                    closeMap={(mapData) => {
                                        if (mapData) {
                                            this.syncMap(mapData);
                                        }
                                        this.setState({ isMapOpened: false });
                                        this.forceUpdate()
                                    }}
                                />
                            }
                            {!isIOs && this.state.isDraftMapOpened &&
                                <MapPolygonView
                                    radioGroup={this.props.elements.radio}
                                    location={this.props.location}
                                    height={275}
                                    mapData={this.questionnaire.location && this.questionnaire.location.map}
                                    initialCoordinates={this.getCoordinates(this.questionnaire, data && data.coordinates)}
                                    closeMap={(mapData) => {
                                        // this.updateSnapshot(mapData);
                                        if (mapData) {
                                            // this.syncMap(mapData);
                                        }
                                        this.setState({ isDraftMapOpened: false });
                                        // this.forceUpdate()
                                    }}
                                />
                            }
                            {isIOs && this.state.isGalleryOpen && <Gallery {...galleryProps} /> }
                        </ViewElement>
                        {!isIOs && this.state.isGalleryOpen && <Gallery {...galleryProps} /> }
                    </View>
                </QuestionnaireProvider>
            );
        } else {
            return <View />;
        }
    }
}

const state2props = state => ({
    questionnaires: state.questionnaires,
    role: state.auth.user.role,
});

const dispatch2props = () => dispatch => ({
    submitQuestionnaire: (data, onAfterSaga) => dispatch(submitQuestionnaire(data, onAfterSaga)),
    createQuestionnaire: (taskId) => dispatch(createQuestionnaire(taskId)),
    updateQuestionnaire: (data) => dispatch(updateQuestionnaire(data))
});

export const QuestionnaireScreen = connect(state2props, dispatch2props)(Questionnaire);
