import { call, put, select, takeEvery } from 'redux-saga/effects';
import { deleteImageWhenPossible, DELETE_AUDIO, DELETE_IMAGE, postponeAudioDelete, UPLOAD_AUDIO, UPLOAD_IMAGE } from '../actions/questionnaire';
import { getAuth } from '../reducers';
import ContentService from '../services/ContentService';

function* uploadImageHandler({ payload, onAfterSaga }) {
    if (!payload) return null;
    const { token } = yield select(getAuth)
    // console.log('??? => ', { ...payload, base64: (payload.base64 || '').slice(0, 100), small: (payload.small || '').slice(0, 100) });

    const response = yield call(
        ContentService.uploadImage,
        payload,
        token
    );

    if (response.error) {
        console.log('[SERVER ERROR]: uploadImageHandler -> ', response.error);
        onAfterSaga(false, response.error);
        return null;
    }

    if (onAfterSaga) {
        onAfterSaga(response);
    }
}

function* deleteImageHandler({ payload, onAfterSaga }) {
    if (!payload) return null;
    const { token } = yield select(getAuth);

    const responses = yield call(
        ContentService.deleteImage,
        payload,
        token
    );

    const errors = responses.filter(i => i.result.error);
    if (errors.length) {
        yield put(deleteImageWhenPossible(errors.map(i => i.item)));
    }


    if (onAfterSaga) {
        onAfterSaga(errors);
    }
}

function* deleteAudioHandler({ payload, onAfterSaga }) {
    if (!payload) return null;
    const { token } = yield select(getAuth);

    const response = yield call(
        ContentService.deleteAudio,
        payload.id,
        token
    );

    if (response && onAfterSaga) {
        console.log('mediacontent | audio-delete response => ', response);

        onAfterSaga();
    }

    if (response.error) {
        yield put(postponeAudioDelete(payload));
    }
}

function* uploadAudioHandler({ payload, onAfterSaga }) {
    if (!payload) return null;
    const { token } = yield select(getAuth);

    const response = yield call(
        ContentService.uploadAudio,
        payload,
        token
    );

    if (!response.error && onAfterSaga) {
        onAfterSaga(response);
    }
}



function* tasksWatcher() {
    yield takeEvery(UPLOAD_IMAGE, uploadImageHandler);
    yield takeEvery(DELETE_IMAGE, deleteImageHandler);

    yield takeEvery(UPLOAD_AUDIO, uploadAudioHandler);
    yield takeEvery(DELETE_AUDIO, deleteAudioHandler);
}

export default tasksWatcher;
