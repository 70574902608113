import React, { Component } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { Input } from '../';
import { FENCE_OR_NO, YES_OR_NO } from '../../../constants/radiobuttons';
import { BaseContainer, selectorStyle, SmallText, iosTooltipContainerBorder, modalSelectorTextStyle } from '../../../constants/styles';
import { attachComponentField } from '../../../helpers/attachComponentField';
import { updateData } from '../../../helpers/updateQuestionnaireHelper';
import i18n from '../../../i18n';
import AddImages from '../AddImages';

const isWeb = (Platform.OS === 'web');
const SELECTS = {
    general_conditions: i18n.t('SELECTS.general_conditions').map(attachComponentField)
};

export default class TabGeneralCondition extends Component {
    constructor(props) {
        super(props);
        const tabData = (props.questionnaire && props.questionnaire.general) || {};
        const isDemolished = (tabData.demolished || {}).isDemolished || 0;
        const isFence = (tabData.fence || {}).isFence || 0;
        const conditionId = (tabData.condition && tabData.condition.conditionId) || '';
        const conditionType = SELECTS.general_conditions.find(i => i.key === conditionId) || '';
        this.state = {
            isDemolished,
            isFence,
            conditionId,
            conditionType,
            pictures: []
        };
        this.openGallery = this.openGallery.bind(this);
    }

    componentDidMount() {
        this.update = (val, opt, isInput=false) => {
            updateData(val, 'general', opt, this.props.update, isInput);
        }
    }

    componentWillUnmount() {
        updateData('', 'general', {}, this.props.update, true);
    }

    _renderAddCommentView(rootField) {
        const { questionnaire } = this.props;
        return (
            <Input
                _viewStyle={{ marginTop: 10, marginBottom: 10 }}
                height={100}
                defaultValue={questionnaire.general[rootField].comment}
                placeholder={i18n.t('text.add_comment')}
                multiline={true}
                numberOfLines={4}
                onChangeText={(comment) => this.update(comment, { rootField, valueField: 'comment' }, true)}
            />);
    }

    openGallery(data) {
        this.props.openGallery(data, { tabId: 2, updateData: { rootField: 'pictures', sectionName: 'general' } });
    }

    render() {
        const tabData = this.props.questionnaire && this.props.questionnaire.general;
        const { imageManipulator, imagePicker, permissions } = this.props.elements;
        const ModalSelector = this.props.elements.select;
        const RadioGroup = this.props.elements.radio;
        const CustomTooltip = this.props.elements.tooltip;
        if(tabData) {
            const isDemolished = (tabData.demolished || {}).isDemolished;
            const isFence = (tabData.fence || {}).isFence;
            return (
                <View style={{ height: isWeb ? 'auto' : this.props.height }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, height: 'auto' }}>
                        <BaseContainer>
                            <View style={{ marginTop: 25, marginBottom: 15 }}>
                                <RadioGroup
                                    caption={i18n.t('text.has_been_demolished')}
                                    captionWidth={290}
                                    labelWidth={130}
                                    initialValue={isDemolished ? 1 : 0}
                                    formData={YES_OR_NO}
                                    reverse={true}
                                    onSelect={(val) => {
                                        this.setState({isDemolished: val});
                                        this.update(val, { rootField: 'demolished', valueField: 'isDemolished', isBool: true })
                                    }}
                                    tooltipHeight={70}
                                    tooltipParagraphs={[i18n.t('tooltips.has_been_demolished')]}
                                />
                                {!!this.state.isDemolished && this._renderAddCommentView('demolished')}

                                <RadioGroup
                                    style={{ justifyContent: 'flex-start', marginTop: 20 }}
                                    caption={i18n.t('text.type_height_of_fence')}
                                    captionWidth={290}
                                    labelWidth={130}
                                    initialValue={isFence ? 1 : 0}
                                    formData={FENCE_OR_NO}
                                    onSelect={(val) => {
                                        this.setState({isFence: val});
                                        this.update(val, { rootField: 'fence', valueField: 'isFence', isBool: true })}}
                                    tooltipHeight={120}
                                    tooltipParagraphs={[i18n.t('tooltips.type_height_of_fence')]}
                                />
                                {!!this.state.isFence && this._renderAddCommentView('fence')}

                                <AddImages
                                    isWeb={isWeb}
                                    fileInput={this.props.elements.fileInput}
                                    elements={{ imageManipulator, imagePicker, permissions }}
                                    questionnaireId={this.props.questionnaire.cemeteryId}
                                    updateSettings={{ rootField: 'pictures', sectionName: 'general' }}
                                    openGallery={this.openGallery}
                                    images={tabData.pictures}
                                    update={this.props.update}
                                >
                                    <SmallText style={{width: 150, textAlign: 'left'}}>
                                        {i18n.t('text.photo_of_sings_on_fence')}
                                    </SmallText>
                                </AddImages>

                                <View style={{ marginBottom: 10 }}>
                                    <CustomTooltip
                                        position={'top'}
                                        height={120}
                                        childWidth={'100%'}
                                        paragraphs={[i18n.t('tooltips.general_condition')]}>
                                        <View style={{ ...iosTooltipContainerBorder, marginTop: 10, marginBottom: 10 }}>
                                            <ModalSelector
                                                cancelText={i18n.t('cancel')}
                                                animationType={'none'}
                                                data={SELECTS.general_conditions}
                                                initValueTextStyle={modalSelectorTextStyle}
                                                selectTextStyle={modalSelectorTextStyle}
                                                selectStyle={{ ...selectorStyle, marginBottom: 0}}
                                                width={isWeb ? 450 : 0}
                                                initValue={(this.state.conditionType || {}).label || i18n.t('text.general_condition')}
                                                onChange={(val) =>{
                                                    this.setState({conditionType: val, conditionId: val.id});
                                                    this.update(val.id, { rootField: 'condition', valueField: 'conditionId' })}}
                                            />
                                        </View>
                                    </CustomTooltip>
                                    {!!this.state.conditionId && this._renderAddCommentView('condition')}
                                </View>
                            </View>
                        </BaseContainer>
                    </ScrollView>
                </View>
            );
        } else {
            return <View/>
        }
    }
}
