import React, { Component } from 'react';
import { Image, View } from 'react-native';
import { TabContainer, TabHeader, Text } from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { tabs } from '../../constants/tabs';
import pencil from '../../assets/images/white-pencil.png';
import i18n from '../../i18n';

export default class Tabs extends Component {
    constructor(props) {
        super(props);

        this._renderRow = this._renderRow.bind(this);
        this.toggleActiveTab = this.toggleActiveTab.bind(this);
    }
    toggleActiveTab(rowItemId, isAnyDraft) {
        if(isAnyDraft) {
            this.props.toggleDraft(rowItemId);
        }
        this.props.toggleActiveTab(rowItemId);
    }

    _renderRow(rowItem) {
        const readonly = this.props.readonly;
        const isAnyDraft = tabs.some(tab=>this.props.draft[tab]);
        const isDraft = this.props.draft[tabs[rowItem.id-1]];
        const isActive = isDraft || this.props.activeItemId === rowItem.id;
        const color = isDraft ? COLORS.PINK.LIGHT : (isActive ? COLORS.PURPLE.DARK : COLORS.GRAY.LIGHT);
        const wasClicked = !!this.props.wasClicked.find(i => i === rowItem.id);
        const tabCaptionColor = (!isActive && !wasClicked) ? COLORS.GRAY.DARK : (isDraft ? COLORS.BLACK.BLACK : COLORS.WHITE.WHITE);
        return (
            <View key={rowItem.id}>
                <TabHeader
                    isDraft={isAnyDraft}
                    visited={wasClicked && !isActive}
                    color={color}
                    onPress={readonly ? null : ()=>this.toggleActiveTab(rowItem.id, isAnyDraft)}
                >
                    <View>
                        <Text bold={'true'} size={2} color={tabCaptionColor}>{rowItem.title}</Text>
                    </View>
                    <View style={{
                        display: !readonly && isAnyDraft ? 'flex' : 'none',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'absolute',
                        right: 60,
                        top: '13%',
                        width: 80,
                        height: '76%',
                        borderRadius: 5,
                        backgroundColor: COLORS.PURPLE.DARK }}>
                        <Image style={{ width: 14, height: 14, resizeMode: 'contain' }} source={pencil} />
                        <Text bold={'true'} size={2} color={COLORS.WHITE.WHITE} style={{ marginLeft: 10}}>{isDraft ? i18n.t('buttons.edit') : i18n.t('buttons.save')}</Text>
                    </View>
                </TabHeader>

                <View style={{ display: isActive ? 'flex' : 'none' }}>
                    {rowItem.content({
                        questionnaire: this.props.questionnaire,
                        update: this.props.update,
                        task: this.props.task,
                        openMap: this.props.openMap,
                        elements: this.props.elements,
                        openGallery: this.props.openGallery,
                        height: this.props.height,
                    })}
                </View>
            </View>
        );
    }

    render() {
        return (
            <TabContainer>
                {(this.props.data || []).map(item => this._renderRow(item))}
            </TabContainer>
        )
    }
}
