import http from './http';


export default class MapService {
    static updateSnapshot(pictureId, picture, token) {
        return http.put(`maps/snapshot/${pictureId}`, { picture }, token);
    }
    static updateMap(locationId, map, token) {
        return http.put(`maps/edit/${locationId}`, { map }, token);
    }
}