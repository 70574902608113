import React, { Component } from 'react';
import { View } from 'react-native';
import { withRouter } from 'react-router-dom';
import packageJson from '../../../../package.json';
import { COLORS } from '../../../constants/colors';
import { sizes } from '../../../constants/sizes';
import { BaseContainer, headerStyles, Version } from '../../../constants/styles';
import i18n from '../../../i18n';
import AccessControl from '../../roles/AccessControl';
import { ButtonSubmit } from '../ButtonSubmit';
import { Logo } from '../Logo';

const createObjectButtonStyle = {
    width: 150,
    marginLeft: 'auto',
    alignSelf: 'center'
};

class Header extends Component {
    render() {
        return (
            <View style={{ borderColor: COLORS.GRAY.LIGHT2, borderBottomWidth: 1, height: sizes.GLOBAL_HEADER_HEIGHT }}>
                <BaseContainer style={headerStyles}>
                    <Logo top={1} width={50} height={62} marginLeft={16} />
                    <Version>
                        {`version ${packageJson.version}`}
                    </Version>
                    <AccessControl
                        allowedPermission={'isCanEdit'}>
                        <ButtonSubmit
                            disabled={this.props.history.location.pathname === this.props.paths.object}
                            style={createObjectButtonStyle}
                            _onPress={() => {
                                this.props.history.push(this.props.paths.object);
                            }}
                            name={i18n.t('buttons.add_new_object')} />
                    </AccessControl>
                </BaseContainer>
            </View>
        );
    }
}

export default withRouter(Header);
