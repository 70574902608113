import React, { Component } from 'react';
import Header from '../components/common/web/Header';
import LeftMenu from '../components/common/web/LeftMenu';
import { CreateObjectScreen, QuestionnaireScreen, TaskEditScreen, TaskListScreen, TaskViewScreen } from '../components/screens';
import { sizes } from '../constants/sizes';
import { CustomSelect, CustomTooltip, FileInput, Link, RadioGroup, Route, Router, PolygonCreator } from './Routing.web';
import AdminPanel from '../components/screens/AdminPanel';

const paths = { questionnaire: '/questionnaire', task: '/task', edit: '/edit', object: '/object', home: '/', admin: '/admin' };
const emptyComponent = (props) => <select ref={props.ref}>{props.children}</select>;
const emptyAudioComponent = { RECORDING_OPTIONS_PRESET_LOW_QUALITY: { haveRecordingPermissions: false, isLoading: false }, Sound: { askAsync: () => ({ status: true }) } };
const emptyPermissionsComponent = { askAsync: () => ({ status: true }) };

const CONTENT_WIDTH = window.innerWidth - sizes.WEB.LEFT_MENU_WIDTH;
const webElements = {
    link: Link,
    radio: RadioGroup,
    select: CustomSelect,
    tooltip: CustomTooltip,
    polygon: PolygonCreator,
    imageManipulator: emptyComponent,
    imagePicker: emptyComponent,
    permissions: emptyPermissionsComponent,
    audio: emptyAudioComponent,
    filesystem: emptyComponent,
    fileInput: FileInput
};

class PageLayout extends Component {

    render() {
        console.log(this.props.location);
        return (
            <div style={{ position: 'relative' }}>
                <LeftMenu
                    paths={this.props.paths} />
                <div style={{ position: 'relative', left: sizes.WEB.LEFT_MENU_WIDTH, width: CONTENT_WIDTH, height: window.innerHeight }}>
                    <Header
                        paths={this.props.paths} 
                    />
                    {this.props.children}
                </div>
            </div>);
    }
}

export default class extends Component {
    render() {
        return (
            <Router>
                <PageLayout paths={paths} >
                    <Route path={paths.home} exact render={props => <TaskListScreen {...props} paths={paths} link={webElements.link} />} />
                    <Route path={paths.object} exact render={props => <CreateObjectScreen {...props} paths={paths} elements={webElements} getLocationAsync={() => console.log('@todo')} />} />
                    <Route path={paths.edit} exact render={props => <TaskEditScreen {...props} paths={paths} elements={webElements} />} />
                    <Route path={paths.task} exact render={props => <TaskViewScreen {...props} paths={paths} />} />
                    <Route path={paths.questionnaire} exact render={props => <QuestionnaireScreen {...props} paths={paths} elements={webElements} getLocationAsync={() => console.log('@todo')} />} />
                    <Route path={paths.admin} exact render={props => <AdminPanel paths={paths} />} />
                </PageLayout>
            </Router>);
    }
}
