import React, { Component } from 'react';
import {Image, Platform, ScrollView, TouchableOpacity, View} from 'react-native';
import svgToDataURL from 'svg-to-dataurl';
import { ButtonSubmit, Input } from '../';
import pencil from '../../../assets/images/pencil.png';
import { CoordinatesView, LocationView } from '../../../components/common';
import { COLORS } from '../../../constants/colors';
import { BaseContainer, ButtonContainer, ErrorText, inputStyle, InputView, modalSelectorSelectWithErrorStyle, modalSelectorTextStyle, modalSelectorStyle, modalSelectorTextWithErrorStyle, SeparatedView, Text, ButtonText, iosTooltipContainerBorder, selectorStyle } from '../../../constants/styles';
import { multiPlatformAlert } from '../../../helpers/alert';
import { updateData } from '../../../helpers/updateQuestionnaireHelper';
import { attachComponentField } from '../../../helpers/attachComponentField';
import i18n from '../../../i18n';
import config from '../../../config';
import noPicture from '../../../assets/icons/no-picture.png';

const isWeb = (Platform.OS === 'web');
const cemetery_addresses = i18n.t('SELECTS.cemetery_addresses').map(attachComponentField);
export default class TabLocationAndPerimeter extends Component {
    constructor(props) {
        super(props);
        const location = (props.questionnaire && props.questionnaire.location) || {};
        const { country='', region='', district='', address={} } = location;
        const addressOfSite = (address.cemeteryAddressId && i18n.t('SELECTS.cemetery_addresses')[address.cemeteryAddressId - 1]) || '';
        this.state = {
            country,
            region,
            district,
            addressOfSite,
            addressOfSiteError: '',
            isLocationSubmitted: true,
            viewMapSnapshot: false,
            isWebEditPolygon: false,
            snapshotImage: null,
            polygonPerimeter: 0,
            polygonSquare: 0,
        };

        this.openMap = ()=> {
            this.update('', '');
            this.props.openMap();
        };
        this._onChangeLocation = this._onChangeLocation.bind(this);
        this._renderAddressView = this._renderAddressView.bind(this);
        this._renderLocationView = this._renderLocationView.bind(this);
        this._renderDescribeLocationView = this._renderDescribeLocationView.bind(this);
        this._renderCommentView = this._renderCommentView.bind(this);
        this.checkAddressOfSite = this.checkAddressOfSite.bind(this);
        this.isLocationFilled = this.isLocationFilled.bind(this);
        this.onChangeSnapshotCallback = this.onChangeSnapshotCallback.bind(this);
    }

    componentDidMount() {
        const location = this.props.questionnaire && this.props.questionnaire.location;
        const map = location && location.map;
        this.resetMap = (map)=> {
            if(map) {
                const polygonPerimeter = (map.perimeter || 0).toString();
                const polygonSquare = (map.square || 0).toString();
                const snapshotImage = (map.snapshot || {}).picture;
                this.setState({ polygonPerimeter, polygonSquare, snapshotImage });
            }
        };
        this.resetMap(map);

        this.closeWebEditPolygon = (map)=> {
            this.setState({isWebEditPolygon: false});
            this.resetMap(map)
        };

        this.update = (val, opt, isInput=false) => {
            updateData(val, 'location', opt, this.props.update, isInput);
        };

        this.saveMap = () => {
            const oldMap = map ? {...map} : {};
            const newMap = {...oldMap};
            let isMapUpdated = false;
            if(this.state.polygonPerimeter !== oldMap.perimeter) {
                newMap.perimeter = Number(this.state.polygonPerimeter) || 0;
                isMapUpdated = true;
            }
            if(this.state.polygonSquare !== oldMap.square) {
                newMap.square = Number(this.state.polygonSquare) || 0;
                isMapUpdated = true;
            }
            if(this.state.snapshotImage) {
                newMap.snapshot = {
                    picture: this.state.snapshotImage
                };
                isMapUpdated = true;
            }
            if(isMapUpdated) {
                this.props.syncMap(newMap);
            }
            this.closeWebEditPolygon(newMap)
        };
    }

    componentWillUnmount() {
        updateData('', 'location', {}, this.props.update, true);
    }

    onChangeSnapshotCallback(images) {
        this.setState({
            snapshotImage: images[0].base64
        })
    }

    isLocationFilled() {
        const { country, region, district } = this.state;
        return (country && region) || (country && district);
    }

    _onChangeLocation() {
        if (this.state.isLocationSubmitted) {
            this.setState({ isLocationSubmitted: false });
        } else if (this.isLocationFilled()) {
            this.update(this.state.country, { rootField: 'country' });
            this.update(this.state.region, { rootField: 'region' });
            this.update(this.state.district || '', { rootField: 'district' });
            this.setState({ isLocationSubmitted: true })
        } else {
            multiPlatformAlert(
                i18n.t('error'),
                i18n.t('text.error_submit_location'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                    },
                ]
            );
        }
    }

    updateAddressOfSite(addressOfSite, opt) {
        if (this.state.addressOfSiteError) {
            this.checkAddressOfSite(addressOfSite.label);
        }
        this.setState({ addressOfSite: addressOfSite.label });
        this.update(addressOfSite.id, opt);
    }

    checkAddressOfSite(val = '') {
        const addressOfSite = val || this.state.addressOfSite;
        if (addressOfSite === i18n.t('text.address_of_site')) {
            this.setState({ addressOfSiteError: i18n.t('text.error_address_of_site') });
        } else {
            this.setState({ addressOfSiteError: '' })
        }
    }

    _renderLocationView() {
        const { country, region, district } = this.props.questionnaire.location;
        const viewStyle = {
            marginTop: 20,
            marginBottom: 0,
            justifyContent: isWeb ? 'flex-start' : 'space-between',
            width: '100%',
        };
        const selectorStyle = {
            marginRight: isWeb ? 20 : 0,
            borderRadius: 5,
            height: 38,
            backgroundColor: this.state.isLocationSubmitted ? COLORS.GRAY.LIGHT : null,
            borderColor: this.state.isLocationSubmitted ? COLORS.GRAY.DARK : COLORS.GRAY.LIGHT
        };

        return (
            <LocationView
                viewStyle={viewStyle}
                selectorStyle={selectorStyle}
                isLocationSubmitted={this.state.isLocationSubmitted}
                initCountry={country}
                onChangeCountry={(val) => this.setState({ country: val.label })}
                countryWidth={isWeb ? '30%' : 165}
                initRegion={region}
                onChangeRegion={(val) => this.setState({ region: val.label })}
                regionWidth={isWeb ? '30%' : 165}
                initDistrict={district}
                onChangeDistrict={(val) => this.setState({ district: val.label })}
                districtWidth={isWeb ? '30%' : 230}
                select={this.props.elements.select}
                onPressButton={this._onChangeLocation}
            />
        );
    }

    _renderAddressView() {
        const { address } = this.props.questionnaire.location;
        if (this.state.addressOfSite && this.state.addressOfSite !== i18n.t('SELECTS.cemetery_addresses')[2]) {
            return (
                <InputView>
                    <Input
                        width={'19%'}
                        placeholder={i18n.t('text.street_number')}
                        defaultValue={address && address.number}
                        onChangeText={(val) => this.update(val, { rootField: 'address', valueField: 'number' }, true)}
                    />
                    <Input
                        width={'79%'}
                        defaultValue={address.street}
                        placeholder={i18n.t('text.street')}
                        onChangeText={(val) => this.update(val, { rootField: 'address', valueField: 'street' }, true)}
                    />
                </InputView>
            );
        } else {
            return <View />;
        }
    }

    _renderDescribeLocationView() {
        const { address } = this.props.questionnaire.location;
        if (this.state.addressOfSite && this.state.addressOfSite === i18n.t('SELECTS.cemetery_addresses')[2]) {
            return (
                <InputView>
                    <Input
                        placeholder={i18n.t('text.describe_location')}
                        defaultValue={address.description}
                        onChangeText={(val) => this.update(val, { rootField: 'address', valueField: 'description' }, true)}
                    />
                </InputView>
            );
        } else {
            return <View />;
        }
    }

    _renderCommentView() {
        const { address } = this.props.questionnaire.location;
        if (this.state.addressOfSite && this.state.addressOfSite !== i18n.t('SELECTS.cemetery_addresses')[2]) {
            return (
                <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Input
                        height={100}
                        placeholder={i18n.t('text.write_comment')}
                        defaultValue={address.comment}
                        onChangeText={(val) => this.update(val, { rootField: 'address', valueField: 'comment' }, true)}
                        multiline={true}
                        numberOfLines={4}
                    />
                </View>
            );
        } else {
            return <View />;
        }
    }

    render() {
        const { task, questionnaire } = this.props;
        const ModalSelector = this.props.elements.select;
        const CustomTooltip = this.props.elements.tooltip;
        const FileInput = this.props.elements.fileInput;
        const location = questionnaire && questionnaire.location;

        if (location) {
            const locationMapSnapshot = location.map && location.map.snapshot;
            let locationMapSnapshotPicture = locationMapSnapshot && (locationMapSnapshot.picture || locationMapSnapshot.base64);
            if (locationMapSnapshotPicture && locationMapSnapshotPicture.startsWith('<svg')) {
                locationMapSnapshotPicture = svgToDataURL(locationMapSnapshotPicture);
            }
            const locationMapSnapshotId = locationMapSnapshot && locationMapSnapshot.id;
            const mapSnapshotURI = locationMapSnapshotId
                ? `${config.hostname}picture/${locationMapSnapshotId}`
                : locationMapSnapshotPicture;
            return (
                <View style={{ height: (isWeb ? 'auto' : this.props.height) }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, height: 'auto' }}>
                        <BaseContainer style={{position: 'relative', height: this.state.isWebEditPolygon ? 610 : 'auto' }}>
                            <View style={{ marginTop: 15}}>
                                <CustomTooltip
                                    height={120}
                                    childWidth={'100%'}
                                    paragraphs={[i18n.t('tooltips.name_of_site')]}>
                                    <View style={{...iosTooltipContainerBorder, marginBottom: 10, marginTop: 10}}>
                                        <Text
                                        style={{ ...inputStyle, width: '100%', marginBottom: 0 }}
                                        color={COLORS.PURPLE.DARK}
                                        >
                                            {task.name}
                                        </Text>
                                    </View>
                                </CustomTooltip>
                                <View style={{ flexDirection: 'column', justifyContent: 'space-between',
                                }}>
                                    {this._renderLocationView()}
                                    <Input
                                        width={'30%'}
                                        _viewStyle={{ marginTop: isWeb ? 5 : 20, marginBottom: isWeb ? 30 : 20 }}
                                        placeholder={i18n.t('text.city')}
                                        defaultValue={location.city}
                                        onChangeText={(val) => this.update(val, { rootField: 'city' }, true)}
                                    />
                                </View>
                                <CustomTooltip
                                    height={270}
                                    childWidth={'100%'}
                                    paragraphs={[
                                        i18n.t('tooltips.address_of_site.part_a'),
                                        i18n.t('tooltips.address_of_site.part_b'),
                                        i18n.t('tooltips.address_of_site.part_c')
                                    ]}
                                >
                                    <ModalSelector
                                        cancelText={i18n.t('cancel')}
                                        animationType={'none'}
                                        data={cemetery_addresses}
                                        width={isWeb ? 600 : 0}
                                        initValueTextStyle={modalSelectorTextStyle}
                                        selectTextStyle={this.state.addressOfSiteError ? modalSelectorTextWithErrorStyle : modalSelectorTextStyle}
                                        selectStyle={this.state.addressOfSiteError ? modalSelectorSelectWithErrorStyle : {...selectorStyle, marginBottom: 0}}
                                        style={{...modalSelectorStyle, ...iosTooltipContainerBorder}}
                                        initValue={this.state.addressOfSite || i18n.t('text.address_of_site')}
                                        onChange={(item) => {
                                            this.updateAddressOfSite(item, {
                                                rootField: 'address',
                                                valueField: 'cemeteryAddressId'
                                            })
                                        }}
                                        onModalClose={() => {
                                            this.checkAddressOfSite()
                                        }}
                                    />
                                </CustomTooltip>
                                <ErrorText style={{ marginBottom: 10 }}>{this.state.addressOfSiteError}</ErrorText>
                                {this._renderAddressView()}
                                {this._renderDescribeLocationView()}
                                {this._renderCommentView()}
                                <View style={{ justifyContent: isWeb ? 'flex-start' : 'space-between', flexDirection: 'row', marginTop: 10 }}>
                                    <CustomTooltip
                                        height={50}
                                        width={250}
                                        position={'top'}
                                        paragraphs={[i18n.t('tooltips.coordinates')]}>
                                        <CoordinatesView
                                            getLocationAsync={this.props.getLocationAsync}
                                            coordinates={location.coordinates}
                                            viewStyle={{ marginTop: 10, marginBottom: 10, ...iosTooltipContainerBorder }}
                                            inputStyle={{ width: 520 }}
                                            buttonWidth={190}
                                            onChangeText={(val) => this.update(val, { rootField: 'coordinates' })}
                                        />
                                    </CustomTooltip>
                                </View>
                            </View>
                            {!(location.map && location.map.perimeter) &&
                                <SeparatedView style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 50, display: 'flex', zIndex: -1 }}>
                                    <ButtonSubmit
                                        style={{ width: 350 }}
                                        name={i18n.t('buttons.create_perimeter')}
                                        _onPress={this.props.openMap}
                                    />
                                </SeparatedView>}
                            {location.map && location.map.perimeter !== undefined &&
                                <View style={{ marginBottom: this.state.viewMapSnapshot ? this.props.height : 140, zIndex: -1, display: this.state.isWebEditPolygon ? 'none' : 'flex' }}>
                                    <SeparatedView style={this.state.viewMapSnapshot ? {borderColor: 'transparent'} : { width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        {!this.state.viewMapSnapshot && <Text style={{ marginRight: 20 }}>{`${task.district || ''} ${i18n.t('map.perimeter_map')}`}</Text>}
                                        {location.map.snapshot && (
                                            <TouchableOpacity onPress={() => isWeb && this.setState({ viewMapSnapshot: !this.state.viewMapSnapshot })} style={this.state.viewMapSnapshot ? { marginRight: -20, marginLeft: -20, marginBottom: 40, width: '105%', height: 400, backgroundColor: COLORS.GRAY.LIGHT, position: 'absolute', left: 0, top: 0 } : {marginRight: 20}}>
                                                <Image
                                                    defaultSource={noPicture}
                                                    source={{ uri: mapSnapshotURI}}
                                                    style={this.state.viewMapSnapshot ? { marginRight: 20, width: '100%', height: 400, resizeMode: 'contain' } : {width: 70, height: 60}}
                                                />
                                            </TouchableOpacity>
                                        )}
                                        <View style={this.state.viewMapSnapshot ? { position: 'absolute', top: 0, left: -20, backgroundColor: COLORS.GRAY.LIGHT2, padding: 10 } : {marginRight: 20}}>
                                            <Text>{`${i18n.t('map.perimeter')} ${location.map.perimeter}${i18n.t('map.m')}`}</Text>
                                            <Text>{`${i18n.t('map.area')} ${location.map.square}${i18n.t('map.m2')}`}</Text>
                                        </View>
                                        {!this.state.viewMapSnapshot &&
                                            <ButtonContainer style={{ width: 24, height: 24, backgroundColor: 'transparent', display: 'flex' }} onPress={this.openMap}>
                                                <Image source={pencil} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
                                            </ButtonContainer>}
                                    </SeparatedView>
                                    {!this.state.viewMapSnapshot &&
                                    <Input
                                        _viewStyle={{ marginTop: 15, marginBottom: 15 }}
                                        height={100}
                                        defaultValue={location.map.comment || ''}
                                        placeholder={i18n.t('text.add_comment')}
                                        multiline={true}
                                        numberOfLines={4}
                                        onChangeText={(val) => {
                                            this.update({ ...location.map, comment: val }, { rootField: 'map', sectionName: 'location' }, true)
                                        }}
                                    />}
                                </View>
                            }
                            {isWeb && this.state.isWebEditPolygon &&
                                <View style={{ position: 'absolute', top: 0, left: 0, backgroundColor: COLORS.WHITE.WHITE, padding: 10, width: '100%' }}>
                                    <View style={{ width: 250, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10}}>
                                        <Text>{i18n.t('map.perimeter') }</Text>
                                        <Input
                                            width={80}
                                            _viewStyle={{ marginLeft: 10, marginRight: 5 }}
                                            defaultValue={this.state.polygonPerimeter}
                                            onChangeText={(val) => {
                                                this.setState({ polygonPerimeter : val })
                                            }}
                                        />
                                        <Text>{i18n.t('map.m')}</Text>
                                    </View>
                                    <View style={{ width: 250, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                        <Text>{i18n.t('map.area') }</Text>
                                        <Input
                                            width={102}
                                            _viewStyle={{ marginLeft: 10, marginRight: 5 }}
                                            defaultValue={this.state.polygonSquare}
                                            onChangeText={(val) => {
                                                this.setState({ polygonSquare : val })
                                            }}
                                        />
                                        <Text>{i18n.t('map.m2')}</Text>
                                    </View>
                                    <Image
                                        defaultSource={noPicture}
                                        source={{ uri: this.state.snapshotImage || mapSnapshotURI}}
                                        style={{ marginRight: 20, width: '100%', height: 420, resizeMode: 'contain' }}
                                    />
                                    <View style={{ marginBottom: 20, marginTop: 20, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <ButtonContainer style={{marginRight: 30}} width={180}>
                                            <ButtonText>{location.map && location.map.snapshot ? i18n.t('buttons.update_snapshot') : i18n.t('buttons.add_snapshot')}</ButtonText>
                                            <FileInput
                                                onChangeCallback={this.onChangeSnapshotCallback}
                                                width={180}
                                                height={40}
                                                type='file'
                                                multiple={false}
                                            />
                                        </ButtonContainer>
                                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <ButtonSubmit
                                                style={{marginRight: 30, width: 140, backgroundColor: COLORS.GRAY.LIGHT2}}
                                                buttonColor={COLORS.GRAY.DARK}
                                                _onPress={()=>{ this.closeWebEditPolygon(location.map)} }
                                                name={i18n.t('buttons.cancel')}
                                            />
                                            <ButtonSubmit
                                                style={{ marginRight: 30, width: 'auto', paddingRight: 15, paddingLeft: 15 }}
                                                _onPress={this.saveMap}
                                                name={i18n.t('buttons.save_polygon')}
                                            />
                                        </View>
                                    </View>
                                </View>}
                        </BaseContainer>
                    </ScrollView>
                </View>
            );
        } else {
            return <View />
        }
    }
}
