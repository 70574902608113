import { combineReducers } from 'redux';
import auth from './auth';
import imagesUpload from './images-upload';
import questionnaires from './questionnaires';
import sync from './sync';
import tasks from './tasks';

export default combineReducers({
    auth,
    tasks,
    questionnaires,
    sync,
    imagesUpload,
});

export const getAuth = state => state.auth;
export const getQuestionnaireData = state => state.questionnaires;
export const getTasks = state => state.tasks;
export const getPostponed = state => state.sync;
