import { MAP_PARAMS } from '../constants/map';
import { multiPlatformAlert } from './alert';
import i18n from '../i18n';

export const convertToRadian = (input) => {
    return input * (Math.PI / 180);
};

export const getDistance = (p1, p2) => {
    var dLat = convertToRadian(p2.latitude - p1.latitude);
    var dLon = convertToRadian(p2.longitude - p1.longitude);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(convertToRadian(p1.latitude)) * Math.cos(convertToRadian(p2.latitude)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    return MAP_PARAMS.EARTH_RADIUS * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
};

export const callErrorAlert = (error) => {
    multiPlatformAlert(
        i18n.t('error'),
        error.message ? error.message.toString() : '',
        [
            {
                id: 'ok',
                text: i18n.t('ok'),
            },
        ]
    );
};