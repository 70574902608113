import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import {
    HeaderText, ItemDivider,
    SubHeaderText,
    taskContainerWithShadow,
    Text,
    taskTextContainerStyle
} from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';
import i18n from '../../i18n';

const isWeb = (Platform.OS === 'web');

export class TaskDetails extends Component {
    render() {
        const { data } = this.props;
        if (!data) return null;
        return (
            <View>
                {isWeb && <SubHeaderText style={{marginBottom: 15}}>{i18n.t('task.object')}</SubHeaderText>}
                <View style={{  ...taskContainerWithShadow, width: sizes.TASK_DETAILS_WIDTH, height: sizes.TASK_DETAILS_HEIGHT }}>
                    <View style={{ ...taskTextContainerStyle, height: 72}}>
                        <HeaderText size={3} style={{color: COLORS.BLACK.BLACK}}>{data.name}</HeaderText>
                    </View>
                    <ItemDivider/>
                    <View style={{ ...taskTextContainerStyle, height: 90}}>
                        <Text style={{marginBottom: 10}}>{data.country + (data.region ? ', ' + data.region : '')}</Text>
                        {!!data.district && <Text>{data.district + ' ' + i18n.t('task.district')}</Text>}
                    </View>
                    <ItemDivider/>
                    <View style={{ ...taskTextContainerStyle, height: 90}}>
                        <Text>{data.coordinates || ''}</Text>
                    </View>
                </View>
            </View>
        );
    }
}
