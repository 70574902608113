import { AUDIO_DELETED, DELETE_IMAGE_WHEN_POSSIBLE, IMAGE_DELETED, POSTPONE_AUDIO_DELETE } from '../actions/questionnaire';
import { SET_SYNC_STATUS } from '../actions/sync';

const DEFAULT_STATE = { audio: [], images: [], status: null };

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case DELETE_IMAGE_WHEN_POSSIBLE: {
            return { ...state, images: [...state.images, ...payload] };
        }

        case IMAGE_DELETED: {
            return { ...state, images: (state.images || []).filter(i => i.id !== payload.id) };
        }

        case POSTPONE_AUDIO_DELETE: {
            return { ...state, audio: [...state.audio, payload] };
        }

        case AUDIO_DELETED: {
            return { ...state, audio: (state.audio || []).filter(i => i.id !== payload.id) };
        }

        case SET_SYNC_STATUS: {
            return { ...state, status: payload };
        }

        default:
            return state;
    }
};
