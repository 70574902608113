import styled from 'styled-components/native';
import { COLORS } from '../colors';
import { Platform } from 'react-native';

const isIOS = (Platform.OS === 'ios');
const TEXT_SIZES = [16, 14, 12, 18, 10];
const HEADER_SIZES = [25, 22, 20];

export const HeaderText = styled.Text`
  position: relative;
  top: ${props => props.top ? props.top : 0};
  color: ${props => props.color || COLORS.BLACK.BLACK};
  font-size: ${props => props.size ? HEADER_SIZES[(props.size - 1)] : HEADER_SIZES[0]};
  font-family: Lato-Bold;
`;

export const SubHeaderText = styled.Text`
  color: ${props => props.color || COLORS.BLACK.BLACK};
  font-size: ${props => props.size ? HEADER_SIZES[(props.size - 1)] : HEADER_SIZES[2]};
  font-family: Lato;
`;

export const Text = styled.Text`
  font-size: ${props => props.size ? TEXT_SIZES[(props.size - 1)] : TEXT_SIZES[0]};
  font-family: Lato;
  ${isIOS ? 'line-height: 28;' : 'line-height: 1.1em;'}
  width: ${props => props.width || 'auto'};
  color: ${props => props.color || COLORS.BLACK.BLACK};
  ${props => props.bold ? 'font-family: Lato-Bold' : ''}
`;

export const DraftText = styled.Text`
  flex-wrap: wrap;
  font-size: ${props => props.size ? TEXT_SIZES[(props.size - 1)] : TEXT_SIZES[0]};
  ${isIOS ? 'line-height: 28;' : 'line-height: 1.1em;'}
`;

export const DraftLabelText = styled.Text`
  margin-right: 4;
  margin-bottom: 5;
  font-size: 15;
  ${isIOS ? 'line-height: 28;' : 'line-height: 1.1em; white-space: nowrap;'}
  color: ${props => props.color || COLORS.PURPLE.DARK}; 
`;

export const SmallText = styled.Text`
  padding-top: 3;
  text-align: center;
  font-family: Lato;
  font-size: ${props => props.fontSize || 12};
  color: ${props => props.color || COLORS.GRAY.DARK};
  ${props => props.bold ? 'font-family: Lato-Bold;' : ''}
`;

export const TextInput = styled.TextInput`
  width: ${props => props.width || '100%'};
  margin-bottom: ${props => props.marginBottom || 0};
  padding-left: 10;
  height: ${props => props.height || 40};
  border-width: 1;
  border-style: solid;
  border-radius: 5;
  font-family: Lato;
  font-size: 14;  
  color: ${COLORS.PURPLE.DARK};
  ${isIOS ? `` : `outline-color: transparent;`}

  ${props => props.disabled && `
    background-color: ${COLORS.GRAY.LIGHT};
    border-color: ${COLORS.GRAY.DARK};
  `}
`;

export const ErrorText = styled.Text`
  display: flex;
  font-family: Lato;
  font-size: 14;  
  color: ${COLORS.RED.LIGHT};
`;

export const Version = styled.Text`
  position: absolute;
  top: 63;
  left: 13;
  font-family: Lato-Bold;
  font-size: 10;  
  color: ${COLORS.PURPLE.DARK};
`;

export const inputStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 30,
  paddingLeft: 10,
  width: '100%',
  height: 40,
  lineHeight: isIOS ? 40 : 1.2,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: COLORS.GRAY.LIGHT,
  borderRadius: 5,
  fontFamily: 'Lato-Bold',
  fontSize: 14,
  color: COLORS.PURPLE.DARK,
  zIndex: 100
};

export const iosTooltipContainerBorder = isIOS ? {
  borderColor:COLORS.PINK.LIGHT,
  borderWidth: 5,
  borderRadius: 10,
  borderStyle: 'solid'
} : {};

export const anchorStyle = {
  color: 'transparent',
};

export const taskTextContainerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  paddingTop: 10,
  paddingRight: 35,
  paddingBottom: 10,
  paddingLeft: 20,
};

export const taskContainerStyle = {
  paddingTop: 20,
  paddingRight: 20,
  paddingBottom: 20,
  paddingLeft: 20,
};
