import React, { Component } from 'react';
import logoImg from '../../assets/icons/esjf-icon.png';
import { IconContainer, LogoImage } from '../../constants/styles';

export class Logo extends Component {
    render() {
        return (
            <IconContainer top={this.props.top}>
                <LogoImage {...this.props} source={logoImg} />
            </IconContainer>
        );
    }
}
