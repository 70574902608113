import { List, Record } from 'immutable';

export default new Record({
	id: 0,
	name: '',
	country: '',
	region: '',
	district: '',
	coordinates: null,
	createdAt: null,
	author: {
		firstName: '',
		lastName: ''
	},
	pictures: List(),
	task: {
		description: '',
		historicalInformation: '',
		historianSubmitted: false,
		editorSubmitted: false,
		createdAt: null,
		author: {
			firstName: '',
			lastName: ''
		},
		status: {
			name: ''
		}
	},
	questionnaire: {
		updatedAt: null,
		mobileGroupSubmitDate: null,
		historianSubmitDate: null,
		editorSubmitDate: null,
		author: {
			firstName: '',
			lastName: ''
		},
		historian: {
			firstName: '',
			lastName: ''
		},
		editor: {
			firstName: '',
			lastName: ''
		}
	}
});
