import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCitiesByStateId, getCountries, getStatesByCountryId } from '../../actions/location';
import { InputView, selectorStyle, modalSelectorTextStyle } from '../../constants/styles';
import { attachComponentFieldWithId } from '../../helpers/attachComponentField';
import i18n from '../../i18n';
import { ButtonSubmit } from './ButtonSubmit';
import {multiPlatformAlert} from "../../helpers/alert";

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            regions: [],
            districts: [],
            selectedCountry: '',
            isRegionSelected: false,
            locationSyncUnavailable: true
        };
        this.countrySelector = React.createRef();
        this.regionSelector = React.createRef();
        this.districtSelector = React.createRef();
        this.onGetCountries = this.onGetCountries.bind(this);
        this.onGetRegions = this.onGetRegions.bind(this);
        this.onGetCities = this.onGetCities.bind(this);
        this.cleanSelectors = this.cleanSelectors.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.onPressButton = this.onPressButton.bind(this);
        this.setCountry = this.setCountry.bind(this);
        this.changeRegion = this.changeRegion.bind(this);
    }

    setCountry(country) {
        this.countrySelector.current.setState({ selected: country });
        this.props.onChangeCountry({ label: country });
        this.setState({ selectedCountry: country});
    }

    componentDidMount() {
        this.initCountry = this.props.initCountry || this.props.selectedCountry;
        if(this.initCountry) {
            this.setCountry(this.initCountry);
        }
        if(!this.props.onPressButton) {
            this.props.getCountries(this.onGetCountries);
        }
    }

    onGetCountries(countriesFromServer) {
        if (countriesFromServer && countriesFromServer.length) {
            const countries = countriesFromServer.map(attachComponentFieldWithId);
            this.setState({
                countries,
                locationSyncUnavailable: false
            });
            if(this.initCountry) {
                const country = countriesFromServer.find(country=>country.name === this.initCountry);
                const countryId = country && country.id;
                this.props.getStatesByCountryId(countryId, this.onGetRegions);
                this.setState({ selectedCountry: country.label });
            }
            if(this.props.onPressButton) {
                this.props.onPressButton();
            }
        } else {
            this.setState({locationSyncUnavailable: true});
            this.locationsSyncWarning();
        }
    }

    onGetRegions(regions) {
        if (regions && regions.length) {
            this.setState({
                regions: (regions || []).map(attachComponentFieldWithId)
            });
        }
    }
    onGetCities(cities) {
        if (cities) {
            this.setState({
                districts: (cities || []).map(attachComponentFieldWithId)
            });
        }
    }

    cleanSelectors(selectors) {
        selectors.forEach((selector) => {
            if (selector === 'region') {
                this.props.onChangeRegion({ name: '' });
                this.regionSelector.current.setState({ selected: i18n.t('task.region') });
                this.setState({ isRegionSelected: false });
            } else if (selector === 'district') {
                this.props.onChangeDistrict({ name: '' });
                this.districtSelector.current.setState({ selected: i18n.t('task.district') });
            }
        });
    }

    onChangeCountry(newCountry) {
        const selectedCountry = this.state.selectedCountry || this.initCountry;
        const isDefaultCountry = this.initCountry && selectedCountry === this.initCountry;
        if(isDefaultCountry && this.initCountry !== newCountry.label) {
            multiPlatformAlert(
                i18n.t('warning'),
                i18n.t('text.error_change_country'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                        onPress: () => {
                            this.changeCountry(newCountry);
                        }
                    },
                    {
                        id: 'cancel',
                        text: i18n.t('cancel'),
                        onPress: () => {
                            this.setCountry(this.initCountry);
                        }
                    },
                ]
            );
        } else {
            this.changeCountry(newCountry);
        }
    }

    changeCountry(newCountry) {
        this.props.onChangeCountry(newCountry);
        this.cleanSelectors(['region', 'district']);
        this.setState({ selectedCountry: newCountry.label });
        this.props.getStatesByCountryId(newCountry.id, this.onGetRegions)
    }

    changeRegion(newRegion) {
        this.props.onChangeRegion(newRegion);
        this.cleanSelectors(['district']);
        this.setState({ isRegionSelected: !!newRegion });
        this.props.getCitiesByStateId(newRegion.id, this.onGetCities);
    }

    locationsSyncWarning() {
        multiPlatformAlert(
            i18n.t('warning'),
            i18n.t('text.warning_localizations_unavailable'),
            [
                {
                    id: 'ok',
                    text: i18n.t('ok'),
                },
            ]
        );
    }

    onPressButton() {
        this.props.getCountries(this.onGetCountries);
    }

    render() {
        const ModalSelector = this.props.select;
        const _selectorStyle = {...selectorStyle, ...(this.props.selectorStyle || {})};
        return (
            <InputView style={this.props.viewStyle}>
                <ModalSelector
                    disabled={this.props.disabled || this.props.isLocationSubmitted || !!this.props.onPressButton}
                    cancelText={i18n.t('cancel')}
                    animationType={'none'}
                    initValue={this.initCountry || i18n.t('task.country')}
                    data={this.state.countries}
                    ref={this.countrySelector}
                    initValueTextStyle={modalSelectorTextStyle}
                    selectTextStyle={modalSelectorTextStyle}
                    style={{..._selectorStyle, width: this.props.countryWidth}}
                    width={this.props.countryWidth}
                    onChange={(val) => {
                        this.onChangeCountry(val)
                    }}
                />
                <ModalSelector
                    disabled={this.props.disabled || this.props.isLocationSubmitted || !(this.state.selectedCountry || this.initCountry)}
                    cancelText={i18n.t('cancel')}
                    animationType={'none'}
                    initValue={this.props.initRegion || i18n.t('task.region')}
                    data={this.state.regions}
                    ref={this.regionSelector}
                    initValueTextStyle={modalSelectorTextStyle}
                    selectTextStyle={modalSelectorTextStyle}
                    style={{..._selectorStyle, width: this.props.regionWidth}}
                    width={this.props.regionWidth}
                    onChange={(val) => this.changeRegion(val)}
                />
                <ModalSelector
                    disabled={this.props.disabled || !this.state.isRegionSelected || this.props.isLocationSubmitted}
                    cancelText={i18n.t('cancel')}
                    animationType={'none'}
                    initValue={this.props.initDistrict || i18n.t('task.district')}
                    data={this.state.districts}
                    ref={this.districtSelector}
                    initValueTextStyle={modalSelectorTextStyle}
                    selectTextStyle={modalSelectorTextStyle}
                    style={{..._selectorStyle, width: this.props.districtWidth}}
                    width={this.props.districtWidth}
                    onChange={this.props.onChangeDistrict}
                />
                {this.props.onPressButton &&
                    <ButtonSubmit
                        disabled={this.props.disabled}
                        style={{ width: 130 }}
                        name={this.props.isLocationSubmitted || this.props.disabled ? i18n.t('buttons.change') : i18n.t('buttons.submit')}
                        _onPress={this.onPressButton}
                    />}
            </InputView>
        );
    }
}

const state2props = state => ({
    selectedCountry: state.auth.selectedCountry,
});

const mapDispatchToProps = dispatch => ({
    getCountries: (onAfterSaga) => dispatch(getCountries(onAfterSaga)),
    getStatesByCountryId: (id, onAfterSaga) => dispatch(getStatesByCountryId(id, onAfterSaga)),
    getCitiesByStateId: (id, onAfterSaga) => dispatch(getCitiesByStateId(id, onAfterSaga)),
});

export const LocationView = connect(state2props, mapDispatchToProps)(Location);
