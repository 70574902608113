import isEqual from 'lodash.isequal';
import React, { Component } from 'react';
import { Image, Platform, View } from 'react-native';
import { connect } from 'react-redux';
import { sync } from '../../actions/sync';
import { getAllTasks } from '../../actions/tasks';
import sortBySubmittedDate, { sortByCriteria } from '../../helpers/sortBySubmittedDate';
import preloadImage from '../../assets/icons/preload.gif';
import search from '../../assets/images/search.png';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';
import { BaseContainer, ButtonContainer, Text } from '../../constants/styles';
import { checkIsReadonly } from '../../helpers/checkPermissions';
import getProperty, { getFullName } from '../../helpers/getProperty';
import pressButtonOnEnter from '../../helpers/pressButtonOnEnter';
import i18n from '../../i18n';
import { ButtonSubmit, Input, TasksList } from '../common';

const isWeb = Platform.OS === 'web';

class TaskListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            inputData: {
                name: '',
                country: '',
                region: '',
                district: '',
                mobileGroupSubmitDate: '',
                status: '',
                surveyor: ''
            },
            readonly: true,
            isLoaded: false,
            ignoreCountry: false,
        };

        this.fields = {
            mobileGroupSubmitDate: 'questionnaire.mobileGroupSubmitDate',
            status: 'task.status.name',
            surveyor: 'questionnaire.author',
            author: 'author',
            historianSubmitted: 'questionnaire.historian',
            editorSubmitted: 'questionnaire.editor'
        };

        this.filters = [
            'name',
            'country',
            'region',
            'district',
            'mobileGroupSubmitDate',
            'status',
            'surveyor',
        ];

        this.filterTasks = this.filterTasks.bind(this);
        this.onFilterFieldChange = this.onFilterFieldChange.bind(this);
        this.filterIsEmpty = this.filterIsEmpty.bind(this);
        this.getTaskItem = this.getTaskItem.bind(this);
        this.getFilteredTasks = this.getFilteredTasks.bind(this);
        this.sortTasks = this.sortTasks.bind(this);
    }

    filterIsEmpty() {
        return !(this.filters.some(filter => this.state.inputData[filter] !== ''));
    }

    getTaskItem(task, filter) {
        const levels = this.fields[filter];
        let item = '';
        if (levels) {
            item = getProperty(task, levels);
        } else {
            item = task[filter];
        }
        return (item || '').toString().toLowerCase();
    }

    getFilteredTasks(inputsData, ignoreFilters = []) {
        return this.props.tasks.filter((task) => {
            let isMatched = true;
            this.filters.forEach((filter) => {
                if (
                    isMatched
                    && inputsData[filter]
                    && (ignoreFilters || []).indexOf(filter) < 0
                ) {
                    const item = this.getTaskItem(task, filter);
                    isMatched = item && (item.indexOf(inputsData[filter].toLowerCase()) > -1);
                }
            });
            return isMatched;
        });
    }

    filterTasks(event, ignoreFilters = []) {
        if (this.filterIsEmpty()) {
            return;
        }
        if(this.state.ignoreCountry) {
            (ignoreFilters || []).push('country');
        }
        const tasks = this.getFilteredTasks(this.state.inputData, ignoreFilters);
        this.setState({ tasks });
    }

    onFilterFieldChange(filterNewState) {
        const value = Object.values(filterNewState)[0];
        this.setState({
            inputData: {...this.state.inputData, ...filterNewState}
        });
        if (!value) {
            this.filterTasks(false, Object.keys(filterNewState));
        }

        return value;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(nextProps.tasks, this.props.tasks) && nextProps.tasks.length !== 0) {
            nextState.tasks = nextProps.tasks.length ? nextProps.tasks : this.props.tasks;
        }
        if (!isWeb && nextProps.location.state) {
            const surveyor = nextProps.location.state.isCreatedByGroup ? getFullName(this.props.auth.user) : '';
            if((surveyor && !nextState.inputData.surveyor) || (!surveyor && nextState.inputData.surveyor)) {
                nextState.inputData.surveyor = surveyor;
                nextState.tasks = this.getFilteredTasks(nextState.inputData);
            }
        }

        nextState.isLoaded = true;

        return true;
    }

    sortTasks(sortCriteria, isByAscending) {
        let tasks = [];
        if(sortCriteria === 'mobileGroupSubmitDate') {
            tasks = sortBySubmittedDate(this.state.tasks, isByAscending);
        } else {
            tasks = sortByCriteria(this.state.tasks, sortCriteria, isByAscending, this.getTaskItem);
        }
        this.setState({tasks});
    }

    componentDidMount() {
        const readonly = this.props.auth.isReadonly || checkIsReadonly(this.props.auth.user.role);
        this.setState({
            tasks: this.props.tasks,
            readonly,
            ignoreCountry: !!this.props.auth.selectedCountry,
        });
        const params = { selectedCountry: this.props.auth.selectedCountry };
        if (readonly) {
            this.props.getAllTasks(params);
        } else {
            this.props.sync(params);
        }
    }

    render() {
        const filterInputWidth = isWeb ? '12.5%' : (this.props.selectedCountry ? 185 : 145);
        return (
            <View>
                <BaseContainer style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, height: sizes.SEARCH_HEIGHT }}>
                    <Input
                        placeholder={i18n.t('task.object')}
                        onChangeText={name => this.onFilterFieldChange({ name })}
                        value={this.state.inputData.name}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />
                    {!this.state.ignoreCountry &&
                        <Input
                            placeholder={i18n.t('task.country')}
                            onChangeText={country => this.onFilterFieldChange({ country })}
                            value={this.state.inputData.country}
                            width={filterInputWidth}
                            onKeyPress={pressButtonOnEnter(this.filterTasks)}
                        />
                    }
                    <Input
                        placeholder={i18n.t('task.region')}
                        onChangeText={region => this.onFilterFieldChange({ region })}
                        value={this.state.inputData.region}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />
                    <Input
                        placeholder={i18n.t('task.district')}
                        onChangeText={district => this.onFilterFieldChange({ district })}
                        value={this.state.inputData.district}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />
                    {isWeb && <Input
                        placeholder={i18n.t('task.submitted_date')}
                        onChangeText={mobileGroupSubmitDate => this.onFilterFieldChange({ mobileGroupSubmitDate })}
                        value={this.state.inputData.mobileGroupSubmitDate}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />}
                    {isWeb && <Input
                        placeholder={i18n.t('task.status')}
                        onChangeText={status => this.onFilterFieldChange({ status })}
                        value={this.state.inputData.status}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />}
                    {isWeb && <Input
                        placeholder={i18n.t('task.surveyor')}
                        onChangeText={surveyor => this.onFilterFieldChange({ surveyor })}
                        value={this.state.inputData.surveyor}
                        width={filterInputWidth}
                        onKeyPress={pressButtonOnEnter(this.filterTasks)}
                    />}
                    {!isWeb && <ButtonSubmit
                        style={{ width: 90, marginLeft: 30 }}
                        name={i18n.t('buttons.search')}
                        _onPress={this.filterTasks}
                    />}
                    {isWeb && <ButtonContainer onPress={this.filterTasks} style={{ width: 44, marginLeft: 10 }} >
                        <Image
                            source={search}
                            style={{ width: 23, height: 24 }}
                        />
                    </ButtonContainer>}
                </BaseContainer>

                {this.state.tasks.length
                    ? (
                        <TasksList
                            router={this.props.router}
                            link={this.props.link}
                            data={this.state.tasks}
                            paths={this.props.paths}
                            readonly={this.state.readonly}
                            role={this.props.auth.user.role}
                            sort={this.sortTasks}
                        />
                    ) : this.state.isLoaded && !this.props.tasks.length ? (
                        <View>
                            <Text style={{ textAlign: 'center', color: COLORS.GRAY.LIGHT, marginTop: 85, fontSize: 22 }}>
                                {i18n.t('text.empty')}
                            </Text>
                        </View>
                    ) : (
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Image
                                    source={preloadImage}
                                />
                            </View>
                        )
                }
            </View>
        );
    }
}

const mapStateToProps = () => state => ({
    tasks: state.tasks,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    sync: (payload) => dispatch(sync(payload)),
    getAllTasks: (payload) => dispatch(getAllTasks(payload)),
});

export const TaskListScreen = connect(mapStateToProps, mapDispatchToProps)(TaskListContainer);
