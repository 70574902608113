export const GET_ALL_TASKS = 'GET_ALL_TASKS';
export const getAllTasks = (payload) => {
	return ({
		type: GET_ALL_TASKS,
		payload,
	});
};

export const SET_TASKS = 'SET_TASKS';
export const setTasks = (tasks) => {
	return ({
		type: SET_TASKS,
		payload: {
			tasks,
		},
	});
};

export const GET_TASK_BY_ID = 'GET_TASK_BY_ID';
export const getTaskById = (id, onAfterSaga) => ({
	type: GET_TASK_BY_ID,
	payload: {
		id,
		onAfterSaga,
	},
});

export const NEW_TASK = 'NEW_TASK';
export const setNewTask = (payload) => ({
	type: NEW_TASK,
	payload,
});

export const EDIT_TASK = 'EDIT_TASK';
export const editTask = (id, data, onAfterSaga) => ({
	type: EDIT_TASK,
	payload: {
		id,
		data,
		onAfterSaga,
	},
});

export const ADD_TASK = 'ADD_TASK';
export const addTask = (data, onAfterSaga) => ({
	type: ADD_TASK,
	payload: {
		data,
		onAfterSaga,
	},
});
