import React from 'react';
import { Platform, View } from 'react-native';
import { TaskStatus, TaskStatusText, Text } from '../constants/styles';
import { COLORS } from '../constants/colors';
import { TASK } from '../constants/task';
import i18n from '../i18n';

const isIOS = (Platform.OS === 'ios');

export const getStatusElement = (statusValue)=> {
    const status = {
        name: i18n.t(`task.status_name.${statusValue}`),
        textColor: COLORS.WHITE.WHITE,
    };
    switch (statusValue) {
        case TASK.STATUS.NAME.IN_PROGRESS:
            status.background = COLORS.BLUE.LIGHT;
            break;
        case TASK.STATUS.NAME.CREATED:
            status.background = COLORS.ORANGE.LIGHT;
            break;
        case TASK.STATUS.NAME.SUBMITTED:
        case TASK.STATUS.READY:
            status.background = COLORS.GREEN.LIGHT;
            break;
        case TASK.STATUS.NOT_READY:
            status.background = COLORS.RED.LIGHT;
            break;
        case TASK.STATUS.NAME.NO_TASK:
        default:
            if(isIOS) {
                status.textColor = COLORS.PURPLE.DARK;
                status.background = COLORS.WHITE.WHITE;
            } else {
                status.textColor = COLORS.BLACK.BLACK;
                status.background = COLORS.WHITE.WHITE;
                status.borderColor = COLORS.PURPLE.DARK;
            }
            break;
    }
    return (
        <TaskStatus backgroundColor={status.background} borderColor={status.borderColor}>
            <TaskStatusText color={status.textColor}>{status.name}</TaskStatusText>
        </TaskStatus>
    );
};

export const setStatus = (data)=> {
    const taskName = data.task && data.task.status && data.task.status.name;
    return getStatusElement(taskName || TASK.STATUS.NAME.NO_TASK);
};

export const setStatusReady = (taskData, field)=> {
    const statusValue = taskData && taskData[field] ? TASK.STATUS.READY : TASK.STATUS.NOT_READY;
    return getStatusElement(statusValue);
};

export const getNamedStatusElement = (statusElement, name)=> {
    return <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 100}}>
        <Text size={5} style={{ marginBottom: 10}}>{name}</Text>
        {statusElement}
    </View>
};