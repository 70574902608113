import React, { Component } from 'react';
import { View } from 'react-native';
import { ErrorText, TextInput } from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { CloseButton } from './CloseButton';

const FOCUS_COLOR = COLORS.PURPLE.DARK;
const DEFAULT_COLOR = COLORS.GRAY.LIGHT;
const ERROR_COLOR = COLORS.RED.LIGHT;

export class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFocus: false,
        };
        this.blurFunctionality = this.blurFunctionality.bind(this);
    }

    blurFunctionality() {
        if(this.props._onBlur) {
            this.props._onBlur();
        }
        this.setState({hasFocus: false});
    }

    render() {
        const customColor = this.props._inputStyle && this.props._inputStyle.borderColor;
        const errorColor = this.props.error && ERROR_COLOR;
        const borderColor = errorColor || customColor || DEFAULT_COLOR;
        const propsToCopy = {};
        const viewStyle = {
            ...this.props._viewStyle
        };
        const inputStyle = {
            ...this.props._inputStyle,
            borderColor: this.state.hasFocus ? FOCUS_COLOR : borderColor,
        };
        Object.keys(this.props).forEach(prop=>{if(prop.indexOf('_') < 0) { propsToCopy[prop] = this.props[prop] }});

        if(this.props.error) {
            viewStyle.marginBottom -= 17;
            return (
                <View style={viewStyle}>
                    <TextInput
                        {...propsToCopy}
                        style={inputStyle}
                        onFocus={()=>this.setState({hasFocus: true})}
                        onBlur={this.blurFunctionality}
                        error={this.props.error} />
                    <ErrorText>{this.props.error}</ErrorText>
                    {this.props._close && <CloseButton onPress={this.props._close} />}
                </View>
            );
        } else if(this.props._close){
            return (
                <View style={viewStyle}>
                    <TextInput
                        {...propsToCopy}
                        style={inputStyle}
                        onFocus={()=>this.setState({hasFocus: true})}
                        onBlur={this.blurFunctionality} />
                    {this.props._close && <CloseButton onPress={this.props._close} />}
                </View>)
        } else {
            return (
                <TextInput
                    {...propsToCopy}
                    style={{...viewStyle, ...inputStyle}}
                    onFocus={()=>this.setState({hasFocus: true})}
                    onBlur={this.blurFunctionality} />
            );
        }
    }
}
