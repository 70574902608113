import React, { Component } from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { BaseContainer, ButtonContainer, DraftLabelText, SeparatedView, Text } from '../../../constants/styles';
import { COLORS } from "../../../constants/colors";
import { DraftItem, DraftItemsContainer } from './DraftItemsContainer';
import noPicture from "../../../assets/icons/no-picture.png";
import config from '../../../config';
import i18n from '../../../i18n';
import view from '../../../assets/icons/view.png';

const isWeb = (Platform.OS === 'web');

export default class DraftTabLocationAndPerimeter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMapSnapshot: false,
        };
    }

    _renderLocationView(location) {
        return (
            <DraftItemsContainer
                leftShift={30}
                width={140}
                items={['country', 'region', 'district', 'city']}
                object={location}
                translation={'task'}
            />
        );
    }

    _renderAddressView(address) {
        return (
            <DraftItemsContainer
                leftShift={55}
                width={260}
                items={['number', 'street']}
                object={address}
                translation={'text'}
            />
        );
    }

    _renderDescribeLocationView(address) {
        return (
            <DraftItem
                width={600}
                label={i18n.t('text.location_description')}
                data={address.description}
            />
        );
    }

    _renderCommentView(address) {
        return (
            <DraftItem
                label={i18n.t('text.additional_comment')}
                data={address.comment}
                flexDirection={'column'}
                leftShift={30}
            />
        );
    }

    render() {
        const { task, questionnaire } = this.props;
        const location = questionnaire && questionnaire.location;
        if(location) {
            const locationMapSnapshotId = location.map && location.map.snapshot && location.map.snapshot.id;
            const locationMapViewHeight = isWeb ? 400 : this.props.height;
            const address = location.address || {};
            const isAddress = address.cemeteryAddressId && address.cemeteryAddressId < 3;
            const isOwnAddress = isAddress && address.cemeteryAddressId === 1;

        const mapSnapshotURI = locationMapSnapshotId
            ? `${config.hostname}picture/${locationMapSnapshotId}`
            : '';

        let snapshotContainerStyle = { marginRight: 20 };
        if (this.state.viewMapSnapshot) {
            snapshotContainerStyle = {
                marginRight: -20,
                marginLeft: -20,
                marginBottom: 40,
                width: '105%',
                height: locationMapViewHeight,
                backgroundColor: COLORS.GRAY.LIGHT,
                position: 'absolute',
                left: 0,
                top: 0
            }
        }

        return (
            <View style={{height: 'auto', marginBottom: 20}}>
                <BaseContainer>
                    <View>
                        <DraftItem
                            width={600}
                            label={i18n.t('task.object_name')}
                            data={task.name}
                        />
                        {this._renderLocationView(location)}
                        {isAddress &&
                            (isOwnAddress ?
                                <DraftLabelText style={{ marginTop: 20}}>{i18n.t('text.address_of_site')}</DraftLabelText> :
                                <DraftLabelText style={{ marginTop: 20}}>{i18n.t('SELECTS.cemetery_addresses')[1]}</DraftLabelText>)
                        }
                        {isAddress && this._renderAddressView(address)}
                        {!isAddress && this._renderDescribeLocationView(address)}
                        {isAddress && this._renderCommentView(address)}
                        <DraftItemsContainer
                            width={500}
                            items={['coordinates']}
                            object={location}
                            translation={'text'}
                        />
                    </View>
                    {location.map && (
                        <View style={{ marginBottom: this.state.viewMapSnapshot ? locationMapViewHeight : 20 }}>
                            <SeparatedView style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <Text style={{ marginRight: 20 }}>{`${task.district || ''} ${i18n.t('map.perimeter_map')}`}</Text>
                                {locationMapSnapshotId && (
                                    <TouchableOpacity
                                        onPress={() => this.setState({ viewMapSnapshot: !this.state.viewMapSnapshot })}
                                        style={snapshotContainerStyle}
                                        activeOpacity={0.95}
                                    >
                                        <Image
                                            defaultSource={noPicture}
                                            source={{ uri: mapSnapshotURI }}
                                            style={this.state.viewMapSnapshot ? { marginRight: 20, width: '100%', height: locationMapViewHeight, resizeMode: 'contain' } : {width: 70, height: 60}}
                                        />
                                    </TouchableOpacity>
                                )}

                                {location.map.perimeter && (
                                    <View style={this.state.viewMapSnapshot ? { position: 'absolute', top: 0, left: -20, backgroundColor: COLORS.GRAY.LIGHT2, padding: 10 } : { marginRight: 20 }}>
                                        <Text>{`${i18n.t('map.perimeter')} ${location.map.perimeter || '-'}${i18n.t('map.m')}`}</Text>
                                        <Text>{`${i18n.t('map.area')} ${location.map.square || '-'}${i18n.t('map.m2')}`}</Text>
                                    </View>
                                )}
                                {isWeb && location.map.perimeter && !this.state.viewMapSnapshot &&
                                <ButtonContainer style={{ width: 24, height: 24, backgroundColor: 'transparent', display: 'flex' }} onPress={() => this.props.openMap(true)}>
                                    <Image source={view} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
                                </ButtonContainer>}
                            </SeparatedView>

                            {!this.state.viewMapSnapshot && (
                                <DraftItem
                                    label={i18n.t('text.additional_comment')}
                                    data={location.map && location.map.comment}
                                    flexDirection={'column'}
                                    leftShift={30}
                                />
                            )}
                        </View>
                    )}
                    </BaseContainer>
                </View>
            );
        } else {
            return <View/>
        }
    }
}
