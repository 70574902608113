export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export default req => Promise.race([
  new Promise(resolve => setTimeout(() => resolve({
    error: 'Oops.. Something went wrong..',
  }), 85000)),
  req,
]);
