import React, { Component } from 'react';
import { Image, View } from 'react-native';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { multiPlatformAlert } from "../../helpers/alert";
import arrowLeft from '../../assets/images/menu/arrow-left.png';
import arrowRight from '../../assets/images/menu/arrow-right.png';
import list from '../../assets/images/menu/list.png';
import target from '../../assets/images/menu/target.png';
import { COLORS } from "../../constants/colors";
import { bottomBarStyles, bottomButtonsContainerStyles, MenuButton, Text } from '../../constants/styles';
import { TASK } from '../../constants/task';
import i18n from "../../i18n";
import { sync, setSyncStatus } from "../../actions/sync";
import statuses from '../../constants/statuses';

const ignorePaths = ['/object'];

const isIgnoredPath = (pathname) => {
    return ignorePaths.indexOf(pathname) > -1;
};

class BottomBar extends Component {
    constructor(props) {
        super(props);
        this.onLogoutSubmit = this.onLogoutSubmit.bind(this);
        this.go = this.go.bind(this);
        this.goBack = this.goBack.bind(this);
        this.goForward = this.goForward.bind(this);
        this.clearHistory = this.clearHistory.bind(this);
        this.removeIgnoredPathsAndGo = this.removeIgnoredPathsAndGo.bind(this);
        this.goHome = this.goHome.bind(this);
        this.cancelButtonAction = this.cancelButtonAction.bind(this);
        this.leaveObjectCreation = this.leaveObjectCreation.bind(this);
        this._renderNotificationBlock = this._renderNotificationBlock.bind(this);
    }

    componentDidMount() {
        this._clearSyncStatus();
    }

    componentDidUpdate() {
        this._clearSyncStatus();
    }

    removeIgnoredPathsAndGo(direction) {
        if (this.props.history.canGo(direction)) {
            this.leaveObjectCreation(() => this.props.history.go(direction));
        } else if (direction < 0) {
            this.onLogoutSubmit();
        }
        this.props.history.entries = this.props.history.entries.filter(historyEntry => !isIgnoredPath(historyEntry.pathname));
    }

    leaveObjectCreation(historyAction) {
        const state = this.props.history.location && this.props.history.location.state;
        const leaveAction = state && state[TASK.ACTION.ENABLE_CREATE_BUTTON_ACTION];
        if (leaveAction) {
            multiPlatformAlert(
                i18n.t('warning'),
                i18n.t('task.confirm_cancel'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok'),
                        onPress: () => {
                            leaveAction();
                            this.props.history.location.state[TASK.ACTION.ENABLE_CREATE_BUTTON_ACTION] = null;
                            historyAction();
                        }
                    },
                    {
                        id: 'cancel',
                        text: i18n.t('cancel'),
                    },
                ]
            );
        } else {
            historyAction();
        }
    }

    cancelButtonAction() {
        const state = this.props.history.location && this.props.history.location.state;
        const action = state && state[TASK.ACTION.CANCEL_BUTTON_ACTION];
        if (action) {
            action();
            this.props.history.location.state[TASK.ACTION.CANCEL_BUTTON_ACTION] = null;
            return true;
        }
        return false;
    }

    go(direction) {
        if (this.props.history) {
            if (this.cancelButtonAction()) {
                return;
            }
            this.removeIgnoredPathsAndGo(direction);
            this.props.updateForwardButton();
        }
    }

    goBack() {
        if (this.props.history.location.pathname === this.props.paths.questionnaire) {
            const params = { selectedCountry: this.props.auth.selectedCountry };
            this.props.sync(params);
        }
        this.go(-1);
    }

    goForward() {
        this.go(1);
    }

    clearHistory() {
        this.props.history.entries = [this.props.history.entries[0]];
    }

    goHome(isCreatedByGroup) {
        this.leaveObjectCreation(() => {
            this.props.history.push(this.props.paths.home, { isCreatedByGroup });
            this.props.history.location.state = { isCreatedByGroup };
        });
    }

    onLogoutSubmit() {
        multiPlatformAlert(
            i18n.t('warning'),
            i18n.t('login_page.confirm_logout'),
            [
                {
                    id: 'ok',
                    text: i18n.t('ok'),
                    onPress: () => {
                        this.clearHistory();
                        this.props.logout(this.props.auth.token);
                    }
                },
                {
                    id: 'cancel',
                    text: i18n.t('cancel')
                },
            ]
        );
    }

    _clearSyncStatus() {
        if (this.props.syncStatus) {
            setTimeout(() => {
                this.props.setSyncStatus(null);
            }, 3500)
        }
    }

    _renderNotificationBlock() {
        if (!this.props.syncStatus) return null;

        const backgroundColor = this.props.syncStatus === statuses.sync.failed ? COLORS.PINK.LIGHT : COLORS.GREEN.LIGHT2;
        const color = this.props.syncStatus === statuses.sync.failed ? COLORS.BLACK.LIGHT : COLORS.WHITE.WHITE;

        return (
            <View style={{ padding: 10, backgroundColor }}>
                <Text style={{ textAlign: 'center', color, }}>
                    {i18n.t(`statuses.sync.${this.props.syncStatus}`)}
                </Text>
            </View>
        );
    }

    render() {
        return (
            <View style={bottomBarStyles}>
                {this._renderNotificationBlock()}
                <View style={bottomButtonsContainerStyles} pointerEvents={this.props.imagesUploadingStatus ? 'none' : 'auto'}>
                    <MenuButton
                        style={{ backgroundColor: COLORS.WHITE.WHITE }}
                        onPress={this.goBack}>
                        <Image source={arrowLeft} style={{ width: 17, height: 32 }} />
                    </MenuButton>
                    <MenuButton
                        disabled={this.props.forwardButtonDisabled}
                        style={{ backgroundColor: COLORS.PURPLE.DARK }}
                        onPress={this.goForward}>
                        <Image source={arrowRight} style={{ width: 17, height: 32 }} />
                    </MenuButton>
                    <MenuButton
                        style={{ backgroundColor: COLORS.PURPLE.DARK }}
                        onPress={() => this.goHome(true)}>
                        <Image source={target} style={{ width: 29, height: 41 }} />
                    </MenuButton>
                    <MenuButton
                        style={{ backgroundColor: COLORS.PURPLE.DARK }}
                        onPress={() => this.goHome(false)}>
                        <Image source={list} style={{ width: 43, height: 45 }} />
                    </MenuButton>
                </View>
            </View>
        );
    }
}

const state2props = () => state => ({
    auth: state.auth,
    syncStatus: state.sync.status,
    imagesUploadingStatus: (state.imagesUpload || {}).status,
});

const dispatch2props = () => dispatch => ({
    setSyncStatus: (payload) => dispatch(setSyncStatus(payload)),
    sync: (payload) => dispatch(sync(payload)),
    logout: (auth) => dispatch(logout(auth))
});

export default connect(state2props, dispatch2props)(BottomBar);
