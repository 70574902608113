import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {COLORS} from "../../../constants/colors";

const selectorStyle = {
    marginBottom: 10,
    borderColor: COLORS.PURPLE.DARK,
    borderRadius: 5,
    width: '100%',
    height: 40
};
export class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ''
        };
        this.keys = [];
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const selected = event.target.value;
        const id = this.keys[event.target.selectedIndex];
        this.setState({selected});
        if(this.props.onChange) {
            this.props.onChange({label: selected, id: id});
        }
    }

    render() {
        this.keys = this.props.data.map(item=> item.key);
        return (
            <div style={{marginRight: this.props.selectStyle && this.props.selectStyle.marginRight, width: this.props.width || '32%'}}>
                <select
                    name={this.props.initValue}
                    style={{...selectorStyle, width: '100%'}}
                    value={this.state.selected ? this.state.selected : this.props.initValue}
                    disabled={this.props.disabled}
                    onChange={this.onChange}>
                    {this.props.data.map((dataItem)=> (
                        <option
                            value={dataItem.label}
                            id={dataItem.key}
                            key={dataItem.key} >
                            {dataItem.label}
                        </option>))}
                    <option
                        value={this.props.initValue}
                        id={0}
                        key={0}
                        style={{display: 'none'}}>
                        {this.props.initValue}
                    </option>
                </select>
            </div>
        )
    }
}

CustomSelect.propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    initValue: PropTypes.string,
    selectStyle: PropTypes.object,
    selectTextStyle: PropTypes.object,
    disabled: PropTypes.bool
};
