import http from './http';

export default class UserService {
    static createUser(newUser, token) {
        return http.post('users', { newUser }, token);
    }

    static getUser(id, token) {
        return http.get(`users/${id}`, token);
    }

    static getUsers(token) {
        return http.get('users', token);
    }

    static updateUser(id, update, token) {
        return http.put(`users/${id}`, { update }, token);
    }
    static removeUser(id, token) {
        return http.delete(`users/${id}`, token);
    }
}