import React, { Component } from "react";
import i18n from '../../../i18n';
import { ItemContainer, ItemVerticalContainer, ListItemText, listItemsStyle } from '../../../constants/styles';
import { Platform, Picker } from 'react-native';
import {ButtonContainer, ButtonText} from '../../../constants/styles';
import { Input } from '../Input';
const platform = Platform.OS;
// const isIOS = (platform === 'ios');
const platformListItemsStyle = listItemsStyle[platform];

const isWeb = Platform.OS === 'web';

const ROLES = ['HISTORIAN', 'MOBILE_GROUP', 'HISTORIAN_READONLY', 'EDITOR'];

export default class UserRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.user.id,
            roleId: props.user.roleId,
            selectedValue: ROLES[props.user.roleId - 1],
            login: props.user.login,
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            password: props.user.password || ''
        }
        this.onPressUpdateUser = this.onPressUpdateUser.bind(this);
    }
    onPressUpdateUser() {
        const { login, roleId, firstName, lastName, id, password } = this.state;
        const update = {
            id,
            login,
            firstName,
            lastName,
            roleId,
            password
        }
       this.props.updateUser(update);
    }
    setNewRoleId(val) {
        const roleId = ROLES.findIndex(role => role === val);
        this.setState({ selectedValue: val, roleId: roleId + 1 });
    }
    updateLogin(val) {
        this.setState({ login: val });
    }
    updateFirstName(val) {
        this.setState({ firstName: val });
    }
    updateLastName(val) {
        this.setState({ lastName: val });
    }
    updatePassword(val) {
        this.setState({ password: val });
    }
    render() {
        const { user } = this.props;
        const initValue = ROLES[user.roleId - 1];
        const otherRoles = ROLES.filter(role => role !== initValue);
        const arr = [initValue, ...otherRoles];
        const filterInputWidth = isWeb ? '100%' : 145;
        return (
            <ItemVerticalContainer>
                <ItemContainer>
                    <ListItemText style={platformListItemsStyle.name}>
                        <Input
                            placeholder={i18n.t('text.userName')}
                            onChangeText={value => this.updateLogin(value)}
                            value={this.state.login}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={platformListItemsStyle.name}>
                        <Input
                            placeholder={i18n.t('text.userName')}
                            onChangeText={value => this.updateFirstName(value)}
                            value={this.state.firstName}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText style={platformListItemsStyle.name}>
                    <Input
                            placeholder={i18n.t('text.userName')}
                            onChangeText={value => this.updateLastName(value)}
                            value={this.state.lastName}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText>
                    <Picker
                        selectedValue={this.state.selectedValue}
                        style={{ height: 40, width: 150, marginRight: 10 }}
                        onValueChange={(itemValue, itemIndex) => this.setNewRoleId(itemValue)}
                    >
                        {arr.map((role, i) => <Picker.Item label={role} value={role} key={i}/>)}
                    </Picker>
                    </ListItemText>
                    <ListItemText style={platformListItemsStyle.name}>
                        <Input
                            placeholder={i18n.t('text.password')}
                            onChangeText={value => this.updatePassword(value)}
                            value={this.state.password}
                            width={filterInputWidth}
                        />
                    </ListItemText>
                    <ListItemText>
                        <ButtonContainer 
                            onPress={this.onPressUpdateUser} 
                            disabled={false}
                            style={{ width: 100, marginLeft: '30px', alignSelf: 'center', height: 40 }}
                        >
                            <ButtonText style={{ color: '#fff' }}>
                                Save
                            </ButtonText>
                        </ButtonContainer>
                    </ListItemText>
                    <ListItemText>
                        <ButtonContainer 
                            onPress={this.props.onPressDeleteUser} 
                            disabled={false}
                            style={{ width: 100, marginLeft: '30px', alignSelf: 'center', height: 40 }}
                        >
                            <ButtonText style={{ color: '#fff' }}>
                                Delete
                            </ButtonText>
                        </ButtonContainer>
                    </ListItemText>
                </ItemContainer>
            </ItemVerticalContainer>
        );
    }
}