import React, { Component } from 'react';
import {View, ScrollView, Platform, Dimensions} from 'react-native';
import {
    SubHeaderText,
    Text,
    taskContainerWithShadow,
    taskContainerStyle
} from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';
import i18n from '../../i18n';

const isWeb = (Platform.OS === 'web');
const WEB_TASK_DESCRIPTION_WIDTH = Dimensions.get('window').width - sizes.WEB.LEFT_MENU_WIDTH - sizes.TASK_DETAILS_WIDTH - sizes.TASK_MARGIN;
const TASK_DESCRIPTION_WIDTH = isWeb ? WEB_TASK_DESCRIPTION_WIDTH : sizes.TASK_DETAILS_WIDTH;

export class TaskDescription extends Component {
    render() {
        const { description, title, created } = this.props;
        return (
            <View>
                {isWeb && <SubHeaderText style={{marginBottom: 15}}>{title}</SubHeaderText>}
                <View style={{ ...taskContainerWithShadow, ...taskContainerStyle, borderLeftColor: COLORS.RED.LIGHT, width: TASK_DESCRIPTION_WIDTH, marginRight: 10, height: sizes.TASK_DETAILS_HEIGHT }}>
                    {!isWeb && <SubHeaderText style={{marginBottom: 10}} >{title}</SubHeaderText>}
                    <ScrollView>
                        <Text style={{fontStyle: description ? 'normal' : 'italic'}}>{description || i18n.t('task.status_name.no_task')}</Text>
                    </ScrollView>
                </View>
                <Text size={3} style={{width: TASK_DESCRIPTION_WIDTH, color: isWeb ? COLORS.BLACK.BLACK : COLORS.PURPLE.DARK, marginTop: isWeb ? 10 : 0}}>
                    {isWeb && !!created && <span style={{color: COLORS.PURPLE.DARK}}>{i18n.t('task.status') + ': '}</span>}
                    {created}
                </Text>
            </View>
        );
    }
}
