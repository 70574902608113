import { call, put, select, takeLatest } from 'redux-saga/effects';
import { audioWasDeleted, imageWasDeleted, setQuestionnaires } from '../actions/questionnaire';
import { setSyncStatus, SYNC_DATA } from '../actions/sync';
import { setTasks } from '../actions/tasks';
import statuses from '../constants/statuses';
import { TASK } from '../constants/task';
import sortBySubmittedDate from '../helpers/sortBySubmittedDate';
import * as selectors from '../reducers';
import ContentService from '../services/ContentService';
import SyncService from '../services/SyncService';

const checkUserCanUpdateQuestionnaire = (taskStatusName, userRole) => {
    switch (taskStatusName) {
        case TASK.STATUS.NAME.IN_PROGRESS:
        case TASK.STATUS.NAME.CREATED:
            return !userRole.isCanCreate;
        case TASK.STATUS.NAME.SUBMITTED:
            return userRole.isCanCreate;
        default:
            return false;
    }
};

function* syncHandler({ payload }) {
    const { user, token } = yield select(selectors.getAuth);
    const questionnaires = yield select(selectors.getQuestionnaireData);
    const tasks = yield select(selectors.getTasks);
    let { images, audio } = yield select(selectors.getPostponed);
    const STATUS = { OK: 'OK' };

    console.log('postponed images delete => ', images.length);
    console.log('postponed audio delete => ', audio.length);

    for (const item of images) {
        const result = yield call(
            ContentService.deleteImage,
            item.id,
            token,
        );

        if (result.status === STATUS.OK) {
            yield put(imageWasDeleted(item));
        }
    }

    for (const item of audio) {
        const result = yield call(
            ContentService.deleteAudio,
            item.id,
            token,
        );

        if (result.status === STATUS.OK) {
            yield put(audioWasDeleted(item));
        }
    }

    const questionnairesForUpdate = questionnaires.filter(i => {
        const taskObject = tasks.find(t => t.id === i.cemeteryId && t.task);
        if (!taskObject) {
            return false;
        }
        const taskStatus = (taskObject.task && taskObject.task.status && taskObject.task.status.name) || '';

        return taskObject && checkUserCanUpdateQuestionnaire(taskStatus, user.role) && i.isUpdated;
    });

    console.log('[sync] questionnaires from store for update: -> ', questionnairesForUpdate.length);

    let notUpdated = [];
    if (questionnairesForUpdate.length) {
        const uploadResponses = yield call(
            SyncService.saveAll,
            { questionnaires: questionnairesForUpdate },
            token
        );

        const responses = uploadResponses.map(({ data, response }) => ({
            response,
            data,
        }));

        notUpdated = responses
            .filter(i => (!i.response || i.response.error))
            .map(i => ({ ...i.data, datatype: i.data.filepath ? 'audio' : 'pictures' }));
    }

    const response = yield call(
        SyncService.getAll,
        payload,
        token,
    );
    if (!response || response.error) {
        console.log('[SERVER ERROR]: sync -> ', (response && response.error) || '');
        if (questionnairesForUpdate.length) {
            yield put(setSyncStatus(statuses.sync.failed));
        }

        return null;
    } else {
        if (questionnairesForUpdate.length) {
            yield put(setSyncStatus(statuses.sync.success));
        }

        console.log('[looks like data were synchronized]')
    }

    if (response.questionnaires) {
        let questionnaires = response.questionnaires;
        notUpdated.map(i => {
            questionnaires = response.questionnaires.map(q => {
                if ((i.questionnaireId || i.cemeteryId) === q.cemeteryId) {
                    const { sectionName, rootField } = i.params;
                    if (!rootField) {
                        q[sectionName][i.datatype] = [...q[sectionName][i.datatype], i];
                    } else {
                        q[sectionName][rootField][i.datatype] = [...q[sectionName][rootField][i.datatype], i];
                    }
                }

                return q;
            });

            return i;
        });
        yield put(setQuestionnaires(questionnaires));
    }

    if (response.tasks) {
        yield put(setTasks(sortBySubmittedDate(response.tasks)));
    }
}

function* tasksWatcher() {
    yield takeLatest(SYNC_DATA, syncHandler);
}

export default tasksWatcher;
