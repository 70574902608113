export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE';
export const updateQuestionnaire = (payload) => {
    return ({
        type: UPDATE_QUESTIONNAIRE,
        payload,
    });
};

export const CLEAR_QUESTIONNAIRE_LIST = 'CLEAR_QUESTIONNAIRE_LIST';
export const clearQuestionnaireList = () => ({
    type: CLEAR_QUESTIONNAIRE_LIST,
});

export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE';
export const createQuestionnaire = (payload) => ({
    type: CREATE_QUESTIONNAIRE,
    payload,
});

export const SET_QUESTIONNAIRES = 'SET_QUESTIONNAIRES';
export const setQuestionnaires = (payload) => {
    return ({
        type: SET_QUESTIONNAIRES,
        payload,
    });
};

export const SUBMIT_QUESTIONNAIRE = 'SUBMIT_QUESTIONNAIRE';
export const submitQuestionnaire = (payload, onAfterSaga) => {
    return ({
        type: SUBMIT_QUESTIONNAIRE,
        payload,
        onAfterSaga,
    });
};

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const getQuestionnaire = (id, onAfterSaga) => {
    return ({
        type: GET_QUESTIONNAIRE,
        id,
        onAfterSaga,
    });
};

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const uploadImage = (payload, onAfterSaga) => ({
    type: UPLOAD_IMAGE,
    payload,
    onAfterSaga,
});

export const CHANGE_IMAGE_UPLOADING_STATUS = 'CHANGE_IMAGE_UPLOADING_STATUS';
export const changeImagesUploadStatus = (payload) => ({
    type: CHANGE_IMAGE_UPLOADING_STATUS,
    payload,
});

export const DELETE_IMAGE = 'DELETE_IMAGE';
export const deleteImagesAction = (payload, onAfterSaga) => {
    return ({
        type: DELETE_IMAGE,
        payload,
        onAfterSaga,
    });
}

export const DELETE_IMAGE_WHEN_POSSIBLE = 'DELETE_IMAGE_WHEN_POSSIBLE';
export const deleteImageWhenPossible = (payload) => ({
    type: DELETE_IMAGE_WHEN_POSSIBLE,
    payload,
});

export const IMAGE_DELETED = 'IMAGE_DELETED';
export const imageWasDeleted = (payload) => ({
    type: IMAGE_DELETED,
    payload,
});

export const POSTPONE_AUDIO_DELETE = 'POSTPONE_AUDIO_DELETE';
export const postponeAudioDelete = (payload) => ({
    type: POSTPONE_AUDIO_DELETE,
    payload,
});

export const DELETE_AUDIO = 'DELETE_AUDIO';
export const deleteAudio = (payload, onAfterSaga) => ({
    type: DELETE_AUDIO,
    payload,
    onAfterSaga,
});

export const UPLOAD_AUDIO = 'UPLOAD_AUDIO';
export const uploadAudio = (payload, onAfterSaga) => ({
    type: UPLOAD_AUDIO,
    payload,
    onAfterSaga,
});

export const AUDIO_DELETED = 'AUDIO_DELETED';
export const audioWasDeleted = (payload) => ({
    type: AUDIO_DELETED,
    payload,
});
