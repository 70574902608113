import { DESTROY_DATA } from '../actions/auth';
import { NEW_TASK, SET_TASKS } from '../actions/tasks';
import Task from '../types/Task';

const DEFAULT_STATE = []

export default (state = DEFAULT_STATE, action = {}) => {
    switch (action.type) {
        case `${SET_TASKS}`:
            const res = action.payload.tasks && action.payload.tasks.map
                ? action.payload.tasks.map(a => new Task(a).toJS())
                : DEFAULT_STATE;

            return res;

        case `${NEW_TASK}`:
            return [new Task(action.payload).toJS(), ...state];

        case `${DESTROY_DATA}`:
            return DEFAULT_STATE;

        default:
            return state;
    }
};
