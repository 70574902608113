import { formatDate } from './formatDate';
import { TASK } from '../constants/task';
import i18n from '../i18n';

const NAME_KEYS = ['author', 'historian', 'editor'];
const NAME_CREATED_DATE = 'createdAt';
const NAME_UPDATED_DATE = 'mobileGroupSubmitDate';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';

const isTaskStatusName = (key)=> key === 'task.status.name';
const isName = (key)=> NAME_KEYS.indexOf(key) > -1;
const isDate = (key)=> key === NAME_CREATED_DATE || key === NAME_UPDATED_DATE;

export const getFullName = (data)=> (data && data[FIRST_NAME] && data[LAST_NAME] && `${data[FIRST_NAME]} ${data[LAST_NAME]}`) || '';

export const getTaskStatusName = (name)=> name ? i18n.t(`task.status_name.${name}`) : i18n.t(`task.status_name.${TASK.STATUS.NAME.NO_TASK}`);

export default ($object, $levels)=> {
    const levels = $levels.split('.');
    const lastKey = levels[levels.length - 1];
    let result = $object[levels[0]];
    if(levels.length > 1) {
        levels.splice(0, 1);
        levels.forEach(level=> {
            result = result && result[level];
        });
    }
    if(isName(lastKey)) {
        result = getFullName(result);
    }
    if(isDate(lastKey)) {
        result = formatDate(result);
    }
    if(isTaskStatusName($levels)) {
        result = getTaskStatusName(result);
    }
    return result || '';
};