import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import { connect } from 'react-redux';
import { updateAuth } from '../../actions/auth';
import { getCountries } from '../../actions/location';
import { COLORS } from '../../constants/colors';
import { YES_OR_NO } from '../../constants/radiobuttons';
import { Text } from '../../constants/styles';
import { multiPlatformAlert } from '../../helpers/alert';
import pressButtonOnEnter from '../../helpers/pressButtonOnEnter';
import i18n from '../../i18n';
import { Form, Input } from '../common';

const isIOS = (Platform.OS === 'ios');
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            password: '',
            error: '',
            emptyLogin: false,
            emptyPassword: false,
            countries: [],
            selectedCountry: null,
            isReadonly: false,
        };

        this.onButtonPress = this.onButtonPress.bind(this);
        this.onLoginFail = this.onLoginFail.bind(this);
        this.onGetCountries = this.onGetCountries.bind(this);
    }

    async onButtonPress() {
        const { login, password, selectedCountry, isReadonly } = this.state;

        if (login && password) {
            this.props.onLoginSubmit(
                { login, password, selectedCountry, isReadonly },
                (isAuthorized) => {
                    if (!isAuthorized) this.onLoginFail();
                }
            );
        }

        this.setState({ emptyLogin: !login, emptyPassword: !password });
    }

    onLoginFail() {
        const error = i18n.t('login_page.error_authentication_failed');
        this.setState({ error: error });
        multiPlatformAlert(
            i18n.t('error'),
            error,
            [
                {
                    id: 'ok',
                    text: i18n.t('ok')
                },
            ],
            { cancelable: false }
        );
    }

    componentDidMount() {
        this.props.getCountries(this.onGetCountries);
    }

    onGetCountries(countries) {
        if (countries) {
            this.setState({
                countries: (countries || []).map(i => ({
                    key: i.id,
                    label: i.name,
                    component: <Text key={i.id}>{i.name}</Text>
                }))
            });
        }
    }

    render() {
        const Select = this.props.customElements.select;
        const RadioButton = this.props.customElements.radio;
        let selectedCountry = i18n.t('text.display_all_countries');

        if (this.state.selectedCountry) {
            const countryName = this.state.countries.find(i => i.id === this.state.selectedCountry);
            if (countryName) {
                selectedCountry = countryName.label;
            }
        }

        return (
            <Form onButtonPress={this.onButtonPress} buttonName={i18n.t('login_page.sign_in')}>
                <Input placeholder={i18n.t('login_page.login')}
                    onKeyPress={pressButtonOnEnter(this.onButtonPress)}
                    _viewStyle={{ marginBottom: 30 }}
                    _inputStyle={{ borderColor: COLORS.GRAY.DARK }}
                    value={this.state.login}
                    onChangeText={login => this.setState({ login })}
                    error={this.state.emptyLogin ? i18n.t('login_page.error_empty_login') : ''}
                />
                <Input placeholder={i18n.t('login_page.password')}
                    onKeyPress={pressButtonOnEnter(this.onButtonPress)}
                    _viewStyle={{ marginBottom: !isIOS ? 50 : 30 }}
                    _inputStyle={{ borderColor: COLORS.GRAY.DARK }}
                    secureTextEntry
                    value={this.state.password}
                    onChangeText={password => this.setState({ password })}
                    error={this.state.emptyPassword ? i18n.t('login_page.error_empty_password') : ''}
                />
                {isIOS && (
                    <View>
                        <Select
                            cancelText={i18n.t('cancel')}
                            animationType={'none'}
                            data={this.state.countries}
                            selectTextStyle={{ textAlign: 'left' }}
                            selectStyle={{ marginBottom: 30, borderColor: COLORS.GRAY.DARK }}
                            initValue={selectedCountry}
                            onChange={option => {
                                this.setState({ selectedCountry: option.label });
                            }}
                        />
                        <RadioButton
                            style={{ marginBottom: 50, paddingLeft: 6 }}
                            caption={i18n.t('text.open_in_readonly')}
                            captionWidth={205}
                            labelWidth={70}
                            initialValue={this.state.isReadonly ? 1 : 0}
                            formData={YES_OR_NO}
                            onSelect={(val) => {
                                this.setState({ isReadonly: val === 1 ? true : false })
                            }}
                        />
                    </View>
                )}

            </Form>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getCountries: (onAfterSaga) => dispatch(getCountries(onAfterSaga)),
    updateAuth: (payload, onAfterSaga) => dispatch(updateAuth(payload, onAfterSaga)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
