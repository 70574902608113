import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { COLORS } from '../colors';
import { sizes } from '../sizes';

const isWeb = (Platform.OS === 'web');

export const IconContainer = styled.View`
  top: ${props => props.top || 90};
`;

export const LogoImage = styled.Image`
  height: ${props => props.height || 156};
  width: ${props => props.width || 125};
  marginLeft: ${props => props.marginLeft || 0};
`;

export const TaskPreviewContainer = styled.View`
  position: absolute;
  z-index: 1;
  top: ${isWeb ? `80` : `20`};
  padding-top: 10;
  padding-left: 17;
  padding-right: 17;
  padding-bottom: 10;
  flex-direction: column;
`;

export const HeaderContainer = styled.View`
  padding-top: 13;
  padding-bottom: 13;
  flex-direction: row;
  justify-content: space-between;
`;

export const BaseContainer = styled.View`
  margin-left: 20;
  margin-right: 20;
`;

export const TabHeader = styled.TouchableOpacity`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${props => props.visited ? COLORS.PURPLE.LIGHT : props.color};
  margin-bottom: 1;
  padding-left: 13;
  padding-bottom: ${props => props.visited && !props.isDraft ? 7 : 0}};
  height: ${props => props.visited && !props.isDraft ? sizes.TAB_VISITED_HEADER_HEIGHT : sizes.TAB_HEADER_HEIGHT}};
`;

export const TabContainer = styled.View`
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const SeparatedView = styled.View`
  flex-direction: row;
  border-top-width: 1;
  border-bottom-width: 1;
  border-color: ${COLORS.GRAY.LIGHT};
  border-style: solid;
  padding-bottom: 17;
  padding-top: 17;
  margin-bottom: 20;
  margin-top: 10;
`;

export const selectorStyle = {
  marginBottom: 10,
  borderColor: COLORS.GRAY.LIGHT
};

export const taskContainerWithShadow = {
  borderStyle: 'solid',
  borderRadius: 5,
  borderLeftWidth: isWeb ? 7 : 5,
  borderLeftColor: COLORS.PURPLE.DARK,
  borderTopWidth: 0.1,
  backgroundColor: COLORS.WHITE.WHITE,
  shadowColor: COLORS.BLACK.BLACK,
  shadowOffset: { width: 5, height: 0, },
  shadowOpacity: 0.3,
  shadowRadius: 4,
};

export const bottomButtonsContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  height: sizes.BOTTOM_MENU_HEIGHT,
  borderWidth: 1,
  borderColor: COLORS.PURPLE.DARK,
  borderStyle: 'solid',
};

export const bottomBarStyles = {
  bottom: 0,
  left: 0,
  position: 'absolute'
};

export const headerStyles = {
  flexDirection: 'row',
  paddingBottom: 15,
  marginTop: 18,
  height: sizes.GLOBAL_HEADER_HEIGHT,
};

export const modalSelectorStyle = {
  ...selectorStyle,
  marginBottom: 5,
  marginTop: 10,
};

export const modalSelectorSelectWithErrorStyle = {
  ...selectorStyle,
  borderColor: COLORS.RED.LIGHT,
};

export const modalSelectorTextStyle = {
  textAlign: 'left',
  color: COLORS.BLACK.BLACK,
};

export const modalSelectorTextWithErrorStyle = {
    ...modalSelectorTextStyle,
  color: COLORS.RED.LIGHT,
};

export const buttonsContainerStyle = {
  flexDirection: 'row-reverse',
  marginTop: 20,
  marginLeft: 19,
  marginRight: 19,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: COLORS.WHITE.WHITE,
  paddingTop: 25,
  paddingBottom: 25,
};

export const leftMenuStyles = {
  top: 0,
  left: 0,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: sizes.WEB.LEFT_MENU_WIDTH,
  height: '100%',
  backgroundColor: COLORS.PURPLE.DARK,
};
