import styled from 'styled-components/native';
import {Platform} from 'react-native';
import {COLORS} from '../colors';
import {sizes} from "../sizes";

export const ButtonContainer = styled.TouchableOpacity`
    top: ${props => props.top || 0};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width || '100%'};
    height: 40;
    border-radius: 5;
    background-color: ${COLORS.PURPLE.DARK};

    ${props => props.disabled && `
        background-color: ${COLORS.PURPLE.LIGHT}
    `}

    ${props => props.visible === 'false' && `
        display: none
    `}
`;

export const ButtonText = styled.Text`
    font-family: Lato-Bold;
    font-style: normal;
    font-size: 14;  
    color: ${COLORS.WHITE.WHITE};
`;

export const ButtonLink = styled.Text`
    font-family: Lato;
    font-size: 12;
    color: ${COLORS.PURPLE.DARK};
    text-decoration: underline;
    align-self: flex-end;
`;

export const TaskStatus = styled.TouchableOpacity`
    ${Platform.OS === 'ios' 
        ? `margin-right: 16; padding-top: 4;` 
        : `margin: auto; padding-top: 2;`}
    width: ${props => props.width || 69}; 
    height: ${props => props.height || 22}; 
    background-color: ${props => props.backgroundColor || COLORS.WHITE.WHITE}; 
    ${props => props.borderColor ? `border-color: ${props.borderColor}; border-width: 1; border-style: solid;` : ``}; 
    border-radius: 5;
`;

export const TaskStatusText = styled.Text`
    font-family: Lato;
    text-align: center;
    font-size: 12;
    color: ${props => props.color || COLORS.PURPLE.DARK}; 
`;

export const MenuButton = styled.TouchableOpacity`
    width: 25%; 
    height: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const WebMenuButton = styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20;
    width: ${sizes.WEB.LEFT_MENU_WIDTH};
    height: 72;
    background-color: ${COLORS.PURPLE.DARK};
`;

export const webMenuButtonTextStyle = {
    marginLeft: 10,
    color: COLORS.WHITE.WHITE
};
