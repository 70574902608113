import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { ADD_TASK, EDIT_TASK, GET_ALL_TASKS, GET_TASK_BY_ID, setTasks, setNewTask } from '../actions/tasks';
import { handleResponse } from '../helpers/response';
import sortBySubmittedDate from '../helpers/sortBySubmittedDate';
import * as selectors from '../reducers';
import ContentService from '../services/ContentService';
import TasksService from '../services/TasksService';

function* getTasks({ payload }) {
    const auth = yield select(selectors.getAuth);
    const response = yield call(
        TasksService.getAllTasks,
        payload,
        auth.token,
    );

    if (response && response.error) {
        console.log('[error]: get-all-tasks -> ', response.error)
    }

    yield put(setTasks(sortBySubmittedDate(response)));
}

function* getTaskById({ payload }) {
    const auth = yield select(selectors.getAuth);
    const response = yield call(
        TasksService.getTaskById,
        payload.id,
        auth.token,
    );
    handleResponse(response, payload.onAfterSaga);
}

function* createTask({ payload }) {
    const { token } = yield select(selectors.getAuth);
    const { pictures, ...data } = payload.data;
    let imagesLoadResponse = [];

    const response = yield call(
        TasksService.createTask,
        data,
        token,
    );
    payload.onAfterSaga && payload.onAfterSaga(response);
    if (response && response.id) {
        yield put(setNewTask(response));
        imagesLoadResponse = yield call(
            ContentService.uploadCemeteryImages,
            pictures,
            response.id,
            token
        );
        payload.onAfterSaga && payload.onAfterSaga({...response, imagesLoaded: imagesLoadResponse});
    }
}

function* editTask({ payload }) {
    const { token } = yield select(selectors.getAuth);
    const { pictures, removedImages, ...data } = payload.data;
    let imagesLoadResponse = [];
    let imagesRemoveResponse = [];

    const response = yield call(
        TasksService.editTask,
        payload.id,
        data,
        token,
    );
    payload.onAfterSaga && payload.onAfterSaga(response);

    if (response && response.id) {
        if (removedImages.length) {
            imagesRemoveResponse = yield call(
                ContentService.deleteImage,
                removedImages,
                token,
            );
        }
        payload.onAfterSaga && payload.onAfterSaga({
            data: response,
            imagesRemoved: imagesRemoveResponse
        });
        if (pictures.length > 0) {
            imagesLoadResponse = yield call(
                ContentService.uploadCemeteryImages,
                pictures,
                payload.id,
                token
            );
        }
        payload.onAfterSaga && payload.onAfterSaga({
            data: {...response, pictures},
            imagesLoaded: imagesLoadResponse
        });
    }
}

function* tasksWatcher() {
    yield takeEvery(GET_ALL_TASKS, getTasks);
    yield takeLeading(ADD_TASK, createTask);
    yield takeEvery(GET_TASK_BY_ID, getTaskById);
    yield takeLeading(EDIT_TASK, editTask);
}

export default tasksWatcher;
