import _serilize from '../helpers/_serilize';
import http from './http';

const ENDPOINT = {
    GET_ALL_TASKS: (params) => `cemetery/details?${params}`,
    CREATE_TASK: `cemetery/add`,
    GET_TASK: (id) => `cemetery/${id}`,
    EDIT_TASK: (id) => `cemetery/edit/${id}`,
    REMOVE_PICTURE: (pictureId) => `cemetery/picture/remove/${pictureId}`,
};

export default class TasksService {
    static getAllTasks(params, token) {
        return http.get(ENDPOINT.GET_ALL_TASKS(_serilize(params)), token);
    }

    static createTask(data, token) {
        return http.post(ENDPOINT.CREATE_TASK, data, token);
    }

    static getTaskById(id, token) {
        return http.get(ENDPOINT.GET_TASK(id), token);
    }

    static editTask(id, data, token) {
        return http.post(ENDPOINT.EDIT_TASK(id), data, token);
    }

    //@todo check removePicture functionality
    static removePicture(pictureId, token) {
        return http.get(ENDPOINT.REMOVE_PICTURE(pictureId), {}, token);
    }
}
