const digitsToUpdate = 20;
const resetGlobals = () => {
    global.counter = 0;
    global.wasInput = false;
    global.updatedParams = null;
    global.oldValue = '';
};
resetGlobals();

const checkIsTheSameField = (fieldOptions) => (global.updatedParams &&
    (fieldOptions.rootField === global.updatedParams.rootField) &&
    (fieldOptions.valueField === global.updatedParams.valueField)
);

/**
 * It is important to bind "this" to function in constructor of component where you need to use this helper-function.
 *
 * @param {any} value
 * @param {string} rootField
 * @param {string} valueField
 * @param {boolean} isBool
 */
export default function updateQuestionnaireHelper(value, options) {
    if (!options) return null;

    const { rootField, valueField, isBool, sectionName } = options;
    if (!sectionName) {
        throw new Error('[updateQuestionnaireHelper]: options.sectionName is required');
    };

    const { questionnaire } = this;
    let item = questionnaire[sectionName][rootField];

    if (!valueField && !isBool) {
        item = value;
    } else {
        item[valueField] = isBool ? !!value : value;
    }

    questionnaire[sectionName][rootField] = item;
    this.props.updateQuestionnaire(questionnaire);

    // @todo: this call fixs issue with un-updated components
    this.forceUpdate();
}

export const updateData = (val, sectionName, opt, updateCallback, isInput=false)=> {
    const updateParams = { ...opt, sectionName };
    if(!global.updatedParams) { global.updatedParams = { ...updateParams }}
    if(!checkIsTheSameField(opt)) {
        if(global.wasInput) {
            updateCallback(global.oldValue, global.updatedParams);
        }
        resetGlobals();
    } else if(isInput && (++global.counter < digitsToUpdate)) {
        global.oldValue = val;
        global.wasInput = isInput;
        global.updatedParams = { ...updateParams };
        return;
    }
    updateCallback(val, updateParams);
    global.counter = 0;
};
