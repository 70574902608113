import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../../constants/colors';
import { Platform } from 'react-native';

const TOOLTIP_WIDTH = 480;
const DEFAULT_POSITION = 'bottom';
const TOOLTIP_SHIFT = -10;
const TAIL_HEIGHT = 10;
const tooltipStyle = {
    display: 'flex',
    position: 'relative',
};
const tooltipContainerStyle = {
    position: 'absolute',
    padding: '7px 14px',
    borderRadius: 5,
    backgroundColor: COLORS.PINK.LIGHT,
    zIndex: 9999
};
const positionStyle = {
    bottom: {
        top: '100%',
        marginTop: TOOLTIP_SHIFT + TAIL_HEIGHT,
    },
    top: {
        bottom: '100%',
        marginBottom: TOOLTIP_SHIFT + TAIL_HEIGHT,
    }
};
const tooltipTailStyle = {
    position: 'absolute',
    left: 11,
    borderWidth: '11px 9px',
    borderColor: 'transparent',
    borderStyle: 'solid',
};
const tailPositionStyle = {
    bottom: {
        top: -TAIL_HEIGHT*2,
        borderBottomColor: COLORS.PINK.LIGHT,
    },
    top: {
        bottom: -TAIL_HEIGHT*2,
        borderTopColor: COLORS.PINK.LIGHT,
    }
};
const tooltipTextStyle = {
    fontSize: 12,
    lineHeight: Platform.OS === 'ios' ? 18 : 1.2,
    margin: '0 0 5px 0'
};

export class CustomTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipVisible: false
        };
    }

    render() {
        const position = this.props.position || DEFAULT_POSITION;
        const tooltipPositionStyle = positionStyle[position];
        return (
            <div style={tooltipStyle} onMouseLeave={()=>this.setState({isTooltipVisible: false})} >
                {this.state.isTooltipVisible &&
                <div style={{
                    ...tooltipContainerStyle,
                    ...tooltipPositionStyle,
                    width: this.props.width || TOOLTIP_WIDTH
                }}>
                    {this.props.paragraphs.map((paragraph, index) =>
                        <p key={index} style={tooltipTextStyle}>{paragraph}</p>
                    )}
                    <span style={{...tooltipTailStyle, ...tailPositionStyle[position]}} />
                </div>
                }
                <div onMouseOver={()=>this.setState({isTooltipVisible: true})} style={{width: this.props.childWidth}} >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

CustomTooltip.propTypes = {
    paragraphs: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired,
    position: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
};
