import React, { Component } from 'react';
import { Image } from 'react-native';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import userImage from '../../../assets/images/menu/user.png';
import exit from '../../../assets/images/menu/exit.png';
import list from '../../../assets/images/menu/list.png';
import admin from '../../../assets/images/menu/arrow-right.png';
import { WebMenuButton, leftMenuStyles, webMenuButtonTextStyle } from '../../../constants/styles';
import { COLORS } from '../../../constants/colors';
import i18n from "../../../i18n";
import {sync} from "../../../actions/sync";

class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.onLogoutSubmit = this.onLogoutSubmit.bind(this);
        this.goToTaskList = this.goToTaskList.bind(this);
        this.toAdminPannel = this.toAdminPannel.bind(this);
    }

    goToTaskList() {
        if(this.props.history.location.pathname === this.props.paths.object) {
            //eslint-disable-next-line
            if(!confirm(i18n.t('task.confirm_cancel'))) {
                return;
            }
        }
        if(this.props.history.location.pathname === this.props.paths.edit) {
            //eslint-disable-next-line
            if(!confirm(i18n.t('task.confirm_cancel_edit'))) {
                return;
            }
        }
        this.props.history.push(this.props.paths.home);
    }

    onLogoutSubmit() {
        if(this.props.history.location.pathname === this.props.paths.questionnaire) {
            const params = { selectedCountry: this.props.auth.selectedCountry };
            this.props.sync(params);
        }
        if (window.confirm(i18n.t('login_page.confirm_logout'))) {
            this.props.logout(this.props.auth.token);
            this.goToTaskList();
        }
    }

    toAdminPannel() {
        console.log('admin pannel');
        this.props.history.push('admin');
    }

    render() {
        const { user } = this.props.auth;
        console.log(user);
        const display = user.role.name === 'ADMIN';
        return (
            <div style={leftMenuStyles}>
                <WebMenuButton
                    disabled={true}
                    onPress={()=>console.log('MenuButton pressed')/*@todo: user information page*/ }>
                    <Image source={userImage} style={{ width: 27, height: 27 }} />
                    <p style={webMenuButtonTextStyle}>{`${user.firstName} ${user.lastName}`}</p>
                </WebMenuButton>
                <WebMenuButton
                    style={{backgroundColor: this.props.history.location.pathname === this.props.paths.home ?  COLORS.PURPLE.DARK1 : COLORS.PURPLE.DARK}}
                    onPress={this.goToTaskList}>
                    <Image source={list} style={{ width: 22, height: 22 }} />
                    <p style={webMenuButtonTextStyle}>{i18n.t('buttons.object_list')}</p>
                </WebMenuButton>
                <WebMenuButton
                    onPress={this.onLogoutSubmit}>
                    <Image source={exit} style={{ width: 21, height: 21 }} />
                    <p style={webMenuButtonTextStyle}>{i18n.t('buttons.exit')}</p>
                </WebMenuButton>
                {display && 
                <WebMenuButton
                    onPress={this.toAdminPannel}>
                    <Image source={admin} style={{ width: 22, height: 22 }} />
                    <p style={webMenuButtonTextStyle}>{i18n.t('buttons.admin_pannel')}</p>
                </WebMenuButton>}
            </div>
        );
    }
}

const state2props = () => state => ({
    auth: state.auth,
});

const dispatch2props = () => dispatch => ({
    sync: (payload) => dispatch(sync(payload)),
    logout: (auth) => dispatch(logout(auth))
});

export default withRouter(connect(state2props, dispatch2props)(LeftMenu));