export const TASK = {
  STATUS: {
    NAME: {
      NO_TASK: 'no_task',
      CREATED: 'created',
      IN_PROGRESS: 'in_progress',
      SUBMITTED: 'submitted',
    },
    READY: 'ready',
    NOT_READY: 'not_ready'
  },
  ACTION: {
    ENABLE_CREATE_BUTTON_ACTION: 'enableCreateButton',
    CANCEL_BUTTON_ACTION: 'cancelLastAction'
  }
};