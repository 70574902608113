import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { COLORS } from '../colors';

const containerHeight = Platform.OS === 'ios' ? 170 : 'auto';

export const Container = styled.View`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  top: 202;
  width: 371;
  height: ${containerHeight};
`;

export const TaskListVerticalContainer = styled.View`
  marginTop: 24px;
  width: 100%;
  height: 100%;
`;

export const TaskListHorizontalContainer = styled.View`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: ${props => props.paddingTop || 0};
  padding-bottom: ${props => props.paddingBottom || 0};
  padding-left: ${props => props.paddingLeft || 0};
  padding-right: ${props => props.paddingRight || 0};
`;

export const LoginScreenContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.WHITE.WHITE};
`;

export const Content = styled.View`
  display: ${props => props.visible ? 'flex' : 'none'};
`;

export const InputContainer = styled.View`
  margin-bottom: 0;
`;

export const InputView = styled.View`
  justify-content: space-between;
  flex-direction: row;
  marginBottom: 30;
`;

export const taskDetailsStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
};
