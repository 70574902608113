import React, {Component} from 'react';
import {ButtonContainer, ButtonText} from '../../constants/styles';

export class ButtonSubmit extends Component {
    render() {
        let textStyle = {};
        if (this.props.style && this.props.style.color) {
            textStyle = {color: this.props.style.color};
        }
        if (this.props.buttonColor) {
            textStyle = {color: this.props.buttonColor};
        }
        return (
            <ButtonContainer style={this.props.style} onPress={this.props._onPress} disabled={this.props.disabled}>
                <ButtonText style={textStyle}>
                    {this.props.name}
                </ButtonText>
            </ButtonContainer>
        );
    }
}