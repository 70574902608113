import React, { Component } from 'react';
import { View } from 'react-native';
import AddImages from '../AddImages';
import { DraftItem } from './DraftItemsContainer';
import { FENCE_OR_NO, YES_OR_NO } from '../../../constants/radiobuttons';
import {BaseContainer, DraftLabelText, Text} from '../../../constants/styles';
import i18n from '../../../i18n';

const SELECTS = {
    general_conditions: i18n.t('SELECTS.general_conditions')
};

export default class DraftTabGeneralCondition extends Component {
    constructor(props) {
        super(props);
        this.openGallery = this.openGallery.bind(this);
    }

    _renderAddCommentView(rootField, isVisible) {
        const { questionnaire } = this.props;
        return (
            <DraftItem
                label={i18n.t('text.additional_comment')}
                data={isVisible ? (questionnaire.general[rootField] && questionnaire.general[rootField].comment) : '-'}
                flexDirection={'column'}
                leftShift={30}
            />
        );
    }

    openGallery(data, updateData) {
        this.props.openGallery(data, { tabId: 2});
    }

    render() {
        const questionnaire = this.props.questionnaire || {};
        const tabData = questionnaire.general;
        const conditionId = (tabData.condition || {}).conditionId;
        const conditionType = conditionId && SELECTS.general_conditions[conditionId - 1];
        const { imageManipulator, imagePicker, permissions } = this.props.elements;
        const fence = tabData.fence || {};
        const fenceType = fence.isFence ? fence.comment : FENCE_OR_NO[0].label;

        return (
            <View style={{height: 'auto', marginBottom: 20}}>
                <BaseContainer>
                    <View style={{ marginTop: 25, marginBottom: 15 }}>
                        <DraftItem
                            width={500}
                            label={i18n.t('text.has_been_demolished')}
                            data={YES_OR_NO[tabData.demolished && tabData.demolished.isDemolished ? 1 : 0].label}
                        />
                        {tabData.demolished && tabData.demolished.isDemolished && this._renderAddCommentView('demolished', true)}

                        <DraftItem
                            width={500}
                            label={i18n.t('text.type_height_of_fence')}
                            data={fenceType}
                        />

                        <DraftLabelText style={{marginTop: 20}}>
                            {i18n.t('text.photos') + ': '}
                        </DraftLabelText>
                        {tabData.pictures && tabData.pictures.length > 0 &&
                        <AddImages
                            disabled={true}
                            flileinput={this.props.elements.fileInput}
                            openGallery={this.openGallery}
                            hideButton={true}
                            elements={{imageManipulator, imagePicker, permissions}}
                            questionnaireId={questionnaire.cemeteryId}
                            images={tabData.pictures}
                        />
                        }
                        {tabData.pictures && tabData.pictures.length < 1 && <Text>{i18n.t('text.no_photos')}</Text>}
                        <DraftItem
                            width={500}
                            label={i18n.t('text.general_condition')}
                            data={conditionType}
                        />
                        {this._renderAddCommentView('condition', true)}
                    </View>
                </BaseContainer>
            </View>
        );
    }
}
