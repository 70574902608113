import { AsyncStorage, Platform } from 'react-native';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import mainSaga from './sagas';

const isWeb = Platform.OS === 'web';
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
    sagaMiddleware,
];

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    blacklist: isWeb ? ['questionnaires', 'sync', 'mediacontent'] : [],
};

const persistedReducers = persistReducer(persistConfig, reducers);
export const store = createStore(
    persistedReducers,
    applyMiddleware(...middlewares),
);
sagaMiddleware.run(mainSaga);

export const persistor = persistStore(store);
