import React, {Component} from 'react';
import { Image, TouchableOpacity, Platform } from 'react-native';
import crossMobile from '../../assets/images/cross.png';
import crossWeb from '../../assets/images/web-cross.png';

const crossImage = Platform.OS === 'ios' ? crossMobile : crossWeb;

export class CloseButton extends Component {
    render() {
        return (
            <TouchableOpacity onPress={this.props.onPress}
                              style={{ position: 'absolute', top: 5, right: 5 }}
            >
                <Image
                    source={crossImage}
                    style={{ width: 15, height: 15 }}
                />
            </TouchableOpacity>
        );
    }
}