import styled from 'styled-components/native';
import { COLORS } from '../colors';

export const MapButtonContainer = styled.View`
    flexDirection: ${props => props.flexDirection || 'row'};
    margin-vertical: 8;
    margin-horizontal: 8;
    backgroundColor: transparent;
`;

export const MapContainer = styled.View`
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
`;

export const SquareInfoText = styled.Text`
    font-family: Lato;
    color: ${COLORS.BLACK.BLACK};
`;

export const SquareInfoTextContainer = styled.View`
    width: ${props => props.width || '173'};
    margin-vertical: 5;
    margin-horizontal: 10;
    backgroundColor: ${COLORS.WHITE.WHITE};
    padding-horizontal: 12;
    padding-vertical: 12;
    border-radius: 5;
`;

export const MarkerCircle = styled.View`
    width: 24;
    height: 24;
    borderRadius: 12;
    borderWidth: 6;
    borderColor: ${COLORS.WHITE.WHITE};
    backgroundColor: ${COLORS.BLACK.BLACK};
`;