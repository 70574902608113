import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('window');
const aspectRatio = width / height;

// Kyiv as default initial location
const lat = 50.4501;
const lng = 30.5234;
const latDelta = 0.005;

export const MAP_PARAMS = {
    LATITUDE: lat,
    LONGITUDE: lng,
    LATITUDE_DELTA: latDelta,
    LONGITUDE_DELTA: latDelta * aspectRatio,
    EARTH_RADIUS: 6378137,
};
