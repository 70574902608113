import { Platform } from 'react-native';
import config from '../config';
import { METHODS } from './apiRequest';
import { multiPlatformAlert } from '../helpers/alert';
import i18n from '../i18n';

const isWeb = Platform.OS === 'web';
const BASE_URL = config.hostname;

const mapToResponse = (response) => {
  const { err } = response || {};
  return err ? ({ error: err }) : response;
};

const request = (url, method, token, options = {}) => fetch(url, Object.assign({
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'token': token,
    },
  }, options))
    .then(data => data.json())
    .then(mapToResponse)
    .catch(error => {
        if (isWeb) {
            multiPlatformAlert(
                i18n.t('network_error'),
                error + '\n' + i18n.t('task.internet_connection_issue'),
                [
                    {
                        id: 'ok',
                        text: i18n.t('ok')
                    }
                ]
            )
        }
        return { error };
    }
);

export default {
  get(url, token) {
    return request(BASE_URL + url, METHODS.GET, token);
  },

  post(url, body, token) {
    return request(BASE_URL + url, METHODS.POST, token, { body: JSON.stringify(body) });
  },

  put(url, body, token) {
    return request(BASE_URL + url, METHODS.PUT, token, { body: JSON.stringify(body) });
  },

  delete(url, token) {
    return request(BASE_URL + url, METHODS.DELETE, token);
  },
};
