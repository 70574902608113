import React, { Component } from 'react';
import { View } from 'react-native';
import { HeaderText } from '../../constants/styles';
import { COLORS } from '../../constants/colors';
import { sizes } from '../../constants/sizes';

export class TaskHeader extends Component {
    render() {
        return (
            <View style={{ width: '100%', height: sizes.SCREEN_HEADER_HEIGHT, backgroundColor: COLORS.GRAY.LIGHT3, marginBottom: 0, justifyContent: 'center' }}>
                <HeaderText size={3} style={{ marginLeft: 10 }}>
                    {this.props.text}
                </HeaderText>
            </View>
        );
    }
}
