import http from './http';

const ENDPOINT = {
    GET_ALL_COUNTRIES: `geo/countries`,
    GET_STATES: (id) => `geo/states/${id}`,
    GET_CITIES: (id) => `geo/cities/${id}`,
};

export default class LocationService {
    static getAllCountries(token) {
        return http.get(ENDPOINT.GET_ALL_COUNTRIES, token);
    }

    static getStatesByCountryId(id, token) {
        return http.get(ENDPOINT.GET_STATES(id), token);
    }

    static getCitiesByStateId(id, token) {
        return http.get(ENDPOINT.GET_CITIES(id), token);
    }
}
