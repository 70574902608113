import React from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeImagesUploadStatus, deleteImagesAction, deleteImageWhenPossible, uploadImage } from '../../actions/questionnaire';
import crossImage from '../../assets/icons/cross.png';
import noPicture from '../../assets/icons/no-picture.png';
import preloadImage from '../../assets/icons/preload.gif';
import config from '../../config';
import { COLORS } from '../../constants/colors';
import { ButtonContainer, ButtonText, SeparatedView, Text } from '../../constants/styles';
import { addImages, openCamera } from '../../helpers/addImages';
import deleteImages from '../../helpers/deleteImages';
import i18n from '../../i18n';

const isWeb = Platform.OS === 'web';
class AddImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            isImagesLoading: [],
            isUploaded: true
        }

        this._onOpenGallery = addImages.bind(this);
        this._onOpenCamera = openCamera.bind(this);
        this._onImagePress = this._onImagePress.bind(this);
        this._deleteImages = deleteImages.bind(this);
        this.props.changeImagesUploadStatus(false);
    }

    _onImagePress() {
        this.props.openGallery(this.props.images);
    }

    _onPressDeleteImage(imageObject) {
        const images = this.props.images;

        if (!this.state.isImagesLoading.length) {
            const filteredImages = images.filter(i => {
                return imageObject.id
                    ? i.id !== imageObject.id
                    : imageObject.preId !== i.preId
            });

            this._deleteImages([{
                id: imageObject.id || imageObject.preId,
                questionnaireId: this.props.questionnaireId,
                params: this.props.updateSettings,
            }]);

            this.props.update(filteredImages, this.props.updateSettings);
            this.forceUpdate();
        }
    }

    uploadImageHandler = (data) => {
        this.props.uploadImage(data, response => {
            this.setState({ isImagesLoading: this.state.isImagesLoading.filter(i => i.preId !== data.preId) });
            this.props.update([...this.props.images, response], this.props.updateSettings);
        });
    };

    render() {
        const images = this.props.images;

        const COUNT_LIMIT = 5;
        let style = { width: 70, height: 70, left: 3, marginLeft: 15 };
        const FileInput = this.props.fileInput;

        return (
            <SeparatedView style={this.props.containerStyle ? { ...this.props.containerStyle, paddingLeft: 0 } : {}}>
                {!this.props.disabled && !isWeb &&
                    <View style={{ width: 235 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <ButtonContainer
                                disabled={this.props.imagesUploadingStatus}
                                width={105} onPress={this._onOpenGallery}>
                                <ButtonText>{i18n.t('buttons.add_photo')}</ButtonText>
                            </ButtonContainer>
                            <ButtonContainer
                                disabled={this.props.imagesUploadingStatus}
                                width={120} onPress={this._onOpenCamera}>
                                <ButtonText>{i18n.t('buttons.open_camera')}</ButtonText>
                            </ButtonContainer>
                        </View>
                        {this.props && this.props.children}
                    </View>}
                {!this.props.disabled && isWeb &&
                    <View style={{ width: 150 }}>
                        <ButtonContainer>
                            <ButtonText>{i18n.t('buttons.add_photo')}</ButtonText>
                            <FileInput
                                onChangeCallback={(images) => {
                                    const updateData = images.map(i => {
                                        return ({
                                            preId: i.id,
                                            base64: i.base64,
                                            questionnaireId: this.props.questionnaireId,
                                            params: this.props.updateSettings.valueField
                                                ? this.props.updateSettings
                                                : { sectionName: this.props.updateSettings.sectionName },
                                        })
                                    });

                                    this.setState({ isImagesLoading: [...this.state.isImagesLoading, ...updateData] });

                                    for (const image of updateData) {
                                        this.uploadImageHandler(image)
                                    }
                                }}
                                width={150}
                                height={40}
                                type='file'
                                data={this.state.images}

                            />
                        </ButtonContainer>
                        {this.props && this.props.children}
                    </View>}

                <View style={{ flexDirection: 'row' }}>
                    {images.map((i, index) => {
                        if (index <= COUNT_LIMIT) {
                            const isCountLimit = index === COUNT_LIMIT && (this.props.images.length - 1) !== index;
                            const id = i.id || i.preId;
                            if (!id) return null;

                            return (
                                <View
                                    key={id}
                                    style={{ position: 'relative' }}
                                >
                                    <TouchableOpacity onPress={this._onImagePress} style={{ position: 'relative' }}>
                                        <Image
                                            source={{
                                                uri: i.small || i.base64 || `${config.hostname}picture/${i.id}?size=small`
                                            }}
                                            defaultSource={noPicture}
                                            style={style}
                                            resizeMode={'cover'}
                                        />
                                    </TouchableOpacity>
                                    {
                                        isCountLimit
                                            ? (
                                                <TouchableOpacity onPress={this._onImagePress} style={{ position: 'absolute', top: 0, left: 17 }}>
                                                    <View style={{ width: 70, height: 70, backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                                        <Text style={{ color: COLORS.WHITE.WHITE, lineHeight: 70, fontSize: 23, textAlign: 'center', fontWeight: 'bold' }}>
                                                            {`+${(this.props.images.length - 1) - index}`}
                                                        </Text>
                                                    </View>
                                                </TouchableOpacity>
                                            )
                                            : (!this.props.disabled && !this.props.imagesUploadingStatus) && (
                                                <View style={{ position: 'absolute', top: 0, right: -3, paddingTop: 5, paddingRight: 5, paddingLeft: 5, paddingBottom: 5 }}>
                                                    {!this.props.isUploadAfterAction && i.preId && (
                                                        <TouchableOpacity onPress={this._onImagePress}>
                                                            <Image
                                                                source={preloadImage}
                                                                style={{ width: 70, height: 70, backgroundColor: 'rgba(0,0,0,0.3)', margin: -5, padding: 0 }}
                                                            />
                                                        </TouchableOpacity>
                                                    )}
                                                    {!i.preId && (
                                                        <TouchableOpacity onPress={() => this._onPressDeleteImage(i)}>
                                                            <Image
                                                                source={crossImage}
                                                                style={{ width: 15, height: 15 }}
                                                            />
                                                        </TouchableOpacity>
                                                    )}
                                                </View>
                                            )
                                    }
                                </View>
                            );

                        } else return null
                    })}
                </View>
            </SeparatedView>

        );
    }
}

AddImages.defaultProps = {
    updateSettings: {},
    openGallery: () => { },
};

const dispatch2props = () => dispatch => ({
    changeImagesUploadStatus: (isUploading) => dispatch(changeImagesUploadStatus(isUploading)),
    uploadImage: (payload, onAfterSaga) => dispatch(uploadImage(payload, onAfterSaga)),
    deleteImages: (payload, onAfterSaga) => dispatch(deleteImagesAction(payload, onAfterSaga)),
    deleteImageWhenPossible: (payload) => dispatch(deleteImageWhenPossible(payload)),
});

const state2props = () => state => ({
    imagesUploadingStatus: (state.imagesUpload || {}).status,
});

export default withRouter(connect(state2props, dispatch2props)(AddImages));
