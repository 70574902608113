import { connect } from 'react-redux';
import { checkPermissions } from '../../helpers/checkPermissions';

const AccessControl = ({permissions, allowedPermission, children, renderNoAccess}) => {
    const permitted = checkPermissions(permissions, allowedPermission);
    if (permitted) {
        return children;
    }
    return renderNoAccess ? renderNoAccess() : null;
};

const mapStateToProps = () => state => ({
    permissions: state.auth.user.role
});

export default connect(mapStateToProps)(AccessControl);