import React from 'react';
import uuid from 'uuid';

export class FileInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        }

        this.loadedCounter = 0;
        this.filesCount = 0;

        this.reference = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.onLoadHandler = this.onLoadHandler.bind(this);
    }

    onLoadHandler(item) {
        return (event) => {
            this.loadedCounter++;

            const base64 = event.target.result;
            const image = {
                id: uuid.v4(),
                base64,
            }

            const data = [...this.state.data, image];
            this.setState({ data });

            if (this.loadedCounter === this.filesCount) {
                this.props.onChangeCallback(data);

                this.setState({ data: [] });
                this.filesCount = 0;
                this.loadedCounter = 0;
            }
        }
    }

    onChange() {
        if (!this.reference.current) return null;
        const files = this.reference.current.files;
        this.filesCount = files.length;

        if (!files) return null;

        for (const item of files) {
            const reader = new FileReader();

            reader.readAsDataURL(item);

            const _onLoadHandler = this.onLoadHandler(item);
            reader.onload = _onLoadHandler;
        }
    }

    render() {
        const multiple = this.props.multiple !== false ? true : false;
        const accept = this.props.accept || 'image/*';

        return (
            <input
                accept={accept}
                multiple={multiple}
                type={this.props.type}
                ref={this.reference}
                style={{ height: this.props.height || 'auto', width: this.props.width || 170, opacity: 0, position: 'absolute', left: 0, top: 0 }}
                onChange={this.onChange}
            />
        )
    }
}
