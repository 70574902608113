import { Record } from 'immutable';

export default new Record({
    cemeteryId: null,
    details: {
        gravestones: {
            isGravestones: null,
            numberOfGravestones: null,
            comment: null,
            dateOfEarliest: null,
            dateOfLast: null,
            pictures: []
        },
        erectingFence: {
            urgencyOfErectingId: null,
            comment: null

        },
        vandalism: {
            isVandalism: null,
            pictures: []
        },
        ownership: {
            ownershipId: null,
            comment: null,
            pictures: []
        },
        comment: null,
        anyMassGravesComment: null,
        anySpecificThreatsComment: null,
        description: null,
        pictures: [],
        interview: [],
        contact: null,
        droneSurvey: {
            isDroneSurvey: null,
            comment: null
        }
    },
    general: {
        demolished: {
            isDemolished: null,
            comment: null
        },
        fence: {
            isFence: null,
            comment: null
        },
        pictures: [],
        condition: {
            conditionId: null,
            comment: null
        },
        interview: []
    },
    location: {
        name: '',
        country: null,
        region: null,
        district: null,
        city: null,
        coordinates: null,
        map: {
            comment: '',
            square: 0,
            perimeter: 0,
            coordinates: [],
            snapshot: {
                picture: null,
                name: ''
            }
        },
        address: {
            cemeteryAddressId: null,
            number: null,
            street: null,
            comment: null,
            description: null
        }
    },
});
