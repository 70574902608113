import React, { Component } from 'react';
import { Platform } from 'react-native';
import { COLORS } from "../../constants/colors";
import { HeaderText, LoginScreenContainer } from '../../constants/styles';
import i18n from '../../i18n';
import { Logo } from '../common';
import LoginForm from '../containers/LoginForm';

export class LoginScreen extends Component {
    render() {
        return (
            <LoginScreenContainer>
                <Logo />
                {Platform.OS === 'ios' && <HeaderText size={1} top={140} color={COLORS.PURPLE.DARK} >{i18n.t('login_page.welcome')}</HeaderText>}
                <LoginForm onLoginSubmit={this.props.onLoginSubmit} customElements={this.props.customElements} />
            </LoginScreenContainer>
        );
    }
}

