import { ROLES } from '../constants/roles';

export const checkPermissions = (permissions, allowedPermission = ROLES.RESTRICTIONS.NO_RESTRICTIONS) => {
    if (allowedPermission === ROLES.RESTRICTIONS.NO_RESTRICTIONS) {
        return true;
    }
    if (allowedPermission === ROLES.RESTRICTIONS.NO_ACCESS) {
        return false;
    }
    return permissions[allowedPermission];
};

export const checkIsReadonly = (role, readonlyMode = false) => {
    const canCreate = checkPermissions(role, ROLES.RESTRICTIONS.CAN_CREATE);
    const canEdit = checkPermissions(role, ROLES.RESTRICTIONS.CAN_EDIT);
    return (!(canCreate || canEdit) || readonlyMode);
};

export const checkCanEdit = (role, readonlyMode = false) => {
    const canCreate = checkPermissions(role, ROLES.RESTRICTIONS.CAN_CREATE);
    const canEdit = checkPermissions(role, ROLES.RESTRICTIONS.CAN_EDIT);
    return (!readonlyMode && canCreate && canEdit);
};