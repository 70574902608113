import { call, select, takeEvery } from 'redux-saga/effects';
import { GET_ALL_COUNTRIES, GET_CITIES, GET_STATES } from '../actions/location';
import { handleResponse } from '../helpers/response';
import * as selectors from '../reducers';
import LocationService from '../services/LocationService';

function* getCountries({ payload }) {
    const auth = yield select(selectors.getAuth);
    const response = yield call(
        LocationService.getAllCountries,
        auth.token,
    );
    payload.onAfterSaga(response);
}

function* getStatesByCountryId({ payload }) {
    const auth = yield select(selectors.getAuth);
    const response = yield call(
        LocationService.getStatesByCountryId,
        payload.id,
        auth.token,
    );
    handleResponse(response, payload.onAfterSaga);
}

function* getCitiesByStateId({ payload }) {
    const auth = yield select(selectors.getAuth);
    const response = yield call(
        LocationService.getCitiesByStateId,
        payload.id,
        auth.token,
    );
    handleResponse(response, payload.onAfterSaga);
}

function* locationWatcher() {
    yield takeEvery(GET_ALL_COUNTRIES, getCountries);
    yield takeEvery(GET_STATES, getStatesByCountryId);
    yield takeEvery(GET_CITIES, getCitiesByStateId);
}

export default locationWatcher;
