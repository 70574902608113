import styled from 'styled-components/native';
import { Platform } from 'react-native';
import {COLORS} from '../colors';

const isWeb = (Platform.OS === 'web');
export const ListContainer = styled.View`
    margin-top: 12;
`;

export const ItemContainer = styled.View`
    flex-direction: row;
    padding-top: 24;
    ${isWeb ? `` : `padding-right: 30;`}
    padding-bottom: 20;
    padding-left: 30;
    background-color: ${COLORS.WHITE.WHITE};
`;

export const ItemVerticalContainer = styled.View`
    flex-direction: column;
    width: 100%;
`;

export const ItemDivider = styled.View`
    background-color: ${COLORS.GRAY.LIGHT};
    height: 1;
    width: 100%;
`;

export const ListItemText = styled.Text`
    font-family: Lato;
    font-size: 16;
    color: ${COLORS.BLACK.BLACK};
`;

export const listItemsStyle = {
    ios: {
        name: {paddingRight: 10, width: '32%'},
        country: {paddingRight: 5, width: '15%'},
        region: {paddingRight: 5, width: '18%'},
        district: {width: '19%'},
        status: {width: '16%'},
    },
    web: {
        admin: {paddingRight: 10, width: '15.8%', paddingLeft: 0 },
        name: {paddingRight: 10, width: '19%'},
        country: {paddingRight: 5, width: '7%'},
        region: {paddingRight: 5, width: '10.5%'},
        district: {width: '9.5%'},
        author: {width: '11%'},
        surveyor: {width: '11%'},
        createdAt: {width: '9.5%'},
        status: {width: '7.5%'},
        historicalInformation: {width: '7.5%'},
        editorInformation: {width: '7.5%'},
    }
};