import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from './actions/auth';
import { LoginScreen } from './components/screens';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.onLoginSubmit = this.onLoginSubmit.bind(this);
    }

    onLoginSubmit(credentials, onAfterLogin) {
        this.props.login(
            credentials,
            (isAuthorized) => {
                onAfterLogin(isAuthorized);
            },
        );
    }

    render() {
        return !this.props.auth.token ? <LoginScreen onLoginSubmit={this.onLoginSubmit} customElements={this.props.customElements} /> : this.props.router;
    }
}

const mapStateToProps = () => state => ({
    auth: state.auth,
});


const mapDispatchToProps = dispatch => ({
    login: (credentials, onAfterSaga) => dispatch(login(credentials, onAfterSaga)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
