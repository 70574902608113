import uuid from 'uuid';
import i18n from '../i18n';
import { multiPlatformAlert } from './alert';

const maxImageSize = 3506000;

const checkImagesUploadingStatus = (props) => {
    const wasAlreadyLoading = props.imagesUploadingStatus;
    if (!wasAlreadyLoading) {
        props.changeImagesUploadStatus(true);
    }

    return false;
};

const errorAlert = (errorTitle, errorText) => {
    multiPlatformAlert(
        errorTitle,
        errorText || '',
        [
            {
                id: 'ok',
                text: i18n.t('ok')
            }
        ]
    );
};

const checkGrantedPermissionsStatus = (status, errorTitle, errorText) => {
    const isGranted = status === 'granted';
    if (!isGranted) {
        errorAlert(errorTitle, errorText);
    }
    return isGranted;
};

export async function addImages(options) {
    if (checkImagesUploadingStatus(this.props)) {
        return;
    }

    const Permissions = this.props.elements.permissions;
    const ImagePicker = this.props.elements.imagePicker;
    const permissions = [Permissions.CAMERA_ROLL];
    const { status } = await Permissions.askAsync(...permissions);
    const setImage = _setImage.bind(this);

    if (!checkGrantedPermissionsStatus(status, i18n.t('text.permissions_error'), i18n.t('text.no_permission_gallery'))) {
        return;
    }

    try {
        ImagePicker.launchImageLibraryAsync({
            mediaTypes: 'Images',
            base64: true,
            quality: 1,
        })
            .then(image => {
                setImage(image, options);
            })
            .catch(message => {
                errorAlert(i18n.t('text.permissions_error'), (message || {}).Error);
            });
    } catch (message) {
        this.props.changeImagesUploadStatus(false);
        errorAlert(i18n.t('text.loading_image_error'), (message || {}).Error);
    }
}

export async function openCamera(options) {
    if (checkImagesUploadingStatus(this.props)) {
        return;
    }

    const Permissions = this.props.elements.permissions;
    const ImagePicker = this.props.elements.imagePicker;
    const permissions = [Permissions.CAMERA_ROLL, Permissions.CAMERA];
    const { status } = await Permissions.askAsync(...permissions);
    const setImage = _setImage.bind(this);

    if (!checkGrantedPermissionsStatus(status, i18n.t('text.permissions_error'), i18n.t('text.no_permission_camera'))) {
        return;
    }

    try {
        ImagePicker.launchCameraAsync()
            .then(image => {
                setImage(image, options);
            })
            .catch(message => {
                errorAlert(i18n.t('text.permissions_error'), (message || {}).Error);
            });
    } catch (message) {
        this.props.changeImagesUploadStatus(false);
        errorAlert(i18n.t('text.loading_image_error'), (message || {}).Error);
    }
}

async function _setImage(image, options) {
    const ImageManipulator = this.props.elements.imageManipulator;
    if (image && !image.cancelled) {
        let images = options.isState ? this.state.images : this.props.images;
        const i = image.uri.split('.');
        const ext = i[i.length - 1];
        const imageHeader = `data:image/${ext};base64,`;

        let smallPic = null;
        let bigPic = { base64: image.base64 };
        try {
            smallPic = await ImageManipulator.manipulateAsync(
                image.uri,
                [{ resize: { width: 300, height: 200 } }],
                { compress: 0.6, base64: true }
            );
            const imageSize = image.height * image.width;
            if (imageSize && imageSize > maxImageSize) {
                const imageCompressRate = maxImageSize/imageSize < 0.9 ? maxImageSize/imageSize : 0.9;
                const imageCompressRateSqrt = Math.sqrt(imageCompressRate);
                bigPic = await ImageManipulator.manipulateAsync(image.uri, [
                    { resize: {
                        width: image.width*imageCompressRateSqrt,
                        height: image.height*imageCompressRateSqrt,
                    } }
                ], { compress: imageCompressRate, base64: true });
            }

        } catch (error) {
            console.error('Compressing image error.');
        }

        const imageSmallBase64 = smallPic ? `${imageHeader} ${smallPic.base64}` : null;

        const tmpImage = {
            base64: bigPic ? `${imageHeader} ${bigPic.base64}`: null,
            small: imageSmallBase64,
            cemeteryId: this.props.questionnaireId,
            params: this.props.updateSettings,
            preId: uuid.v4(),
        };

        if (!this.props.isUploadAfterAction) {
            const imagesLoading = [...this.state.isImagesLoading, tmpImage];
            this.setState({ isImagesLoading: imagesLoading });
        }

        if (!image.base64) {
            try {
                await ImageManipulator.manipulateAsync(
                    image.uri,
                    [],
                    { base64: true }
                ).then(manipulated => {
                    tmpImage.base64 = `${imageHeader} ${manipulated.base64}`;
                })
            } catch (message) {
                errorAlert(i18n.t('text.loading_image_error'), (message || {}).Error);
                this.props.changeImagesUploadStatus(false);
                return;
            }
        }

        images = [...images, tmpImage];
        if (this.props.update && this.props.updateSettings) {
            this.props.update(
                images,
                this.props.updateSettings,
            );
        }

        this.setState({ images });
    }

    if (!image) {
        console.log('\n\ncamera issue\n\n');
    }

    this.props.changeImagesUploadStatus(false);
}

