import React from 'react';
import { Image, TouchableOpacity, View, Platform } from 'react-native';
import { connect } from 'react-redux';
import { deleteAudio, uploadAudio } from '../../actions/questionnaire';
import crossImage from '../../assets/icons/cross.png';
import preloader from '../../assets/icons/preload.gif';
import { ButtonContainer, ButtonText, SmallText } from '../../constants/styles';
import { multiPlatformAlert } from '../../helpers/alert';
import i18n from '../../i18n';
import { QuestionnaireConsumer } from '../contexts/QuestionnaireContext';
import config from '../../config';

const isWeb = Platform.OS === 'web';

class AddInterview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            base64: null,
            filepath: null,
            isPlaying: false,
            id: null,
        };

        this._removeRecordedVoice = this._removeRecordedVoice.bind(this);
        this._onButtonPress = this._onButtonPress.bind(this);
        this.uploadAudio = this.uploadAudio.bind(this);
    }

    componentDidMount() {
        if (!this.state.name && this.props.data && this.props.data.length && this.props.data[0].name) {
            const { name, base64, filepath, id } = this.props.data[0];
            this.setState({ name, base64, filepath, id });
        }
    }

    async componentWillUnmount() {
        if(this.audio && this.state.isPlaying) {
            await this.audio.pause();
        }
    }

    audioWasUploaded = (response) => {
        this.props.update([response], this.props.updateSettings);
    };

    async _onButtonPress(context) {
        if(!this.status) {
            const Permissions = this.props.elements.permissions;
            const permissions = [Permissions.AUDIO_RECORDING];
            const { status } = await Permissions.askAsync(...permissions);
            this.status = status;
        }
        if (this.state.name) {
            if(isWeb) {
                if(!this.audio) {
                    let audioData = this.state.base64;
                    if (!audioData) {
                        const { base64 } = await fetch(`${config.hostname}audio/${this.state.id}`, { method: 'GET' })
                            .then(response => response.json());

                        audioData = base64;
                    }
                    this.audio = new Audio(audioData);
                    this.audio.onended = ()=>this.setState({ isPlaying: false });
                }

                if (this.state.isPlaying) {
                    this.audio.pause();
                    this.setState({ isPlaying: false });
                } else {
                    if(this.audio.src) {
                        this.audio
                            .play()
                            .then(() => this.setState({ isPlaying: true }))
                            .catch(error => alert(error.message))
                    }
                }
            } else {
                this._stopOrPlay(context);
            }
        } else if (context.isRecording) {
            const { name, base64, filepath } = await context.stopRecording();
            this.uploadAudio(name, filepath, base64);
        } else {
            if (this.status === 'granted') {
                await context.recordVoice();
            }
        }
    }

    uploadAudio($name, filepath, base64) {
        const name = $name || `questionnaire_${this.props.questionnaireId}_${this.props.updateSettings.sectionName}_interview`;
        const parts = name.split('.');
        const ext = parts[parts.length - 1];

        const dataBase64 = isWeb ? base64 : `data:audio/${ext};base64, ${base64}`;
        const data = {
            name,
            filepath,
            base64: dataBase64,
            params: this.props.updateSettings,
            questionnaireId: this.props.questionnaireId,
        };
        if (name && base64 && filepath) {
            this.props.uploadAudio(data, this.audioWasUploaded);
        }

        this.props.update([data], this.props.updateSettings);
        this.setState({ name, base64: dataBase64, filepath, isPlaying: false });
    }

    _stopOrPlay(context) {
        if (context.isPlaying) {
            context.stopPlaying();
        } else {
            context.startPlaying(this.state.filepath);
        }
    }

    _removeRecordedVoice() {
        multiPlatformAlert(
            i18n.t('warning'),
            i18n.t('interview.confirm_cancel'),
            [
                {
                    id: 'ok',
                    text: i18n.t('yes'),
                    onPress: () => {
                        const data = this.props.data.length > 0 && this.props.data[0];
                        const updateState = () => {
                            if (this.state.isPlaying) {
                                this.audio.pause();
                                this.audio = null;
                            }

                            this.setState({ name: null, base64: null, filepath: null, isPlaying: false });
                            this.props.update([], this.props.updateSettings);
                        };

                        if (data && data.id && typeof data.id === 'number') {
                            this.props.deleteAudio(this.props.data[0], updateState);
                        } else {
                            updateState();
                        }
                    }
                },
                {
                    id: 'cancel',
                    text: i18n.t('cancel')
                },
            ]
        );
    }

    _renderAdditionalBlock(context) {
        if (context.isRecording) {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <Image source={preloader} style={{ width: 40, height: 40 }} />
                    <SmallText style={{ fontSize: 15, paddingTop: 10, marginLeft: 15 }}>recording</SmallText>
                </View>
            );
        } else if (!context.isRecording && this.state.name) {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => !this.props.disabled && this._stopOrPlay(context)}>
                        <SmallText
                            style={{ fontSize: 15, paddingTop: this.props.disabled ? 0 : 10, marginLeft: 15 }}
                        >
                            {this.state.name}
                        </SmallText>
                    </TouchableOpacity>
                   {!this.props.disabled && <TouchableOpacity onPress={this._removeRecordedVoice}>
                        <Image
                            style={{ width: 10, height: 10, marginLeft: 10, marginTop: 15 }}
                            source={crossImage}
                        />
                    </TouchableOpacity>}
                </View>
            )
        }
    }

    _renderButtonText(context) {
        if (context.isRecording && !isWeb && !this.props.disabled) {
            return i18n.t('buttons.press_for_stop');
        } else if (context.isPlaying || this.state.isPlaying) {
            return i18n.t('buttons.stop_playing');
        } else if (this.state.name) {
            return i18n.t('buttons.play');
        } else if(isWeb) {
            return i18n.t('buttons.add_interview')
        } else {
            return i18n.t('buttons.start_interview')
        }
    }

    render() {
        const FileInput = this.props.fileInput;
        return (
            <QuestionnaireConsumer>
                {(context) => {
                    return (
                        <View style={{ width: '100%', flexDirection: 'row', marginBottom: 20 }}>
                            <ButtonContainer width={150} onPress={() => this._onButtonPress(isWeb ? '' : context)}>
                                <ButtonText>{this._renderButtonText(context)}</ButtonText>
                                {!this.props.disabled && isWeb && !this.state.name &&
                                    <FileInput
                                        multiple={false}
                                        accept={'audio/*'}
                                        onChangeCallback={(files)=>this.uploadAudio('', '', files[0].base64)}
                                        width={150}
                                        height={40}
                                        type='file'
                                        data={this.state}
                                    />}
                            </ButtonContainer>
                            {this._renderAdditionalBlock(context)}
                        </View>
                    )
                }}
            </QuestionnaireConsumer>
        );
    }
}

const dispatch2props = dispatch => ({
    deleteAudio: (payload, onAfterSaga) => dispatch(deleteAudio(payload, onAfterSaga)),
    uploadAudio: (payload, onAfterSaga) => dispatch(uploadAudio(payload, onAfterSaga))
})

export default connect(null, dispatch2props)(AddInterview);
