import React from 'react';
import {Text} from '../constants/styles';

export const attachComponentField = (val, index) => ({
    id: index + 1,
    key: index + 1,
    label: val,
    component: <Text key={index + 1}>{val}</Text>
});

export const attachComponentFieldWithId = i => ({
    ...i,
    key: i.id,
    label: i.name,
    component: <Text key={i.id}>{i.name}</Text>
});