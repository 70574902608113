import { CHANGE_IMAGE_UPLOADING_STATUS } from '../actions/questionnaire';

const DEFAULT_STATE = { status: false };

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case CHANGE_IMAGE_UPLOADING_STATUS:
            return { status: payload };

        default:
            return state;
    }
};
