import _serilize from '../helpers/_serilize';
import ContentService from './ContentService';
import http from './http';

const ENDPOINT = {
    SAVE_QUESTIONNAIRE: (id) => `questionnaire/report/${id}`,
    SUBMIT_QUESTIONNAIRE: (id) => `questionnaire/report/${id}?isSubmit=true`,
    GET_ALL: (params) => `cemetery/sync?${params}`,
};

const requestMapper = (data, cemeteryId, params) => {
    return (data || []).map(i => ({ ...i, questionnaireId: cemeteryId, params }));
}

export default class SyncService {
    static async submitQuestionnaire(data, token) {
        return await http.post(ENDPOINT.SUBMIT_QUESTIONNAIRE(data.cemeteryId), data, token);
    }

    static async saveAll(data, token) {
        const qImages = data.questionnaires.map(q => {
            return [
                ...requestMapper(q.general.pictures, q.cemeteryId, { sectionName: 'general' }),
                ...requestMapper(q.details.pictures, q.cemeteryId, { sectionName: 'details' }),
                ...requestMapper(q.details.gravestones.pictures, q.cemeteryId, { sectionName: 'details', rootField: 'gravestones' }),
                ...requestMapper(q.details.vandalism.pictures, q.cemeteryId, { sectionName: 'details', rootField: 'vandalism' }),
                ...requestMapper(q.details.ownership.pictures, q.cemeteryId, { sectionName: 'details', rootField: 'ownership' }),
            ];
        }).reduce((acc, i) => ([...acc, ...i]));

        const qAudio = data.questionnaires.map(q => {
            return [
                ...requestMapper(q.general.interview, q.cemeteryId, { sectionName: 'general' }),
                ...requestMapper(q.details.interview, q.cemeteryId, { sectionName: 'details' }),
            ];
        }).reduce((acc, i) => ([...acc, ...i]));

        const responses = await Promise.all([
            ...qImages
                .filter(i => i.preId && typeof i.preId === 'string')
                .map(imageObject => {
                    console.log('--> upload image', imageObject.preId);
                    return ContentService.uploadImage(imageObject, token, true);
                }),
            ...qAudio
                .filter(i => !!i.base64)
                .map(audio => {
                    console.log('--> upload audio');
                    return ContentService.uploadAudio(audio, token, true);
                }),
        ]);

        await Promise.all(data.questionnaires.map(q => http.post(ENDPOINT.SAVE_QUESTIONNAIRE(q.cemeteryId), q, token)));

        return responses;
    }

    static async getAll(params, token) {
        return await http.get(ENDPOINT.GET_ALL(_serilize({ ...params, base64: 'false' })), token);
        // return await http.get(ENDPOINT.GET_ALL(_serilize({ ...params, base64: `${!isWeb}` })), token);
    }
}
